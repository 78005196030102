<template>
  <div class="create-bill">
    <div
      class="filter-area"
      v-if="
        corplist && corplist.length > 1 && $store.state.user.privileges.performance === 3
      "
    >
      <div
        class="filter-button"
        v-for="item in corplist"
        :key="item.id"
        @click="() => (corp = item)"
        :class="{
          active: corp && corp.corp_id === item.corp_id,
        }"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="flex-wrapper">
      <div class="select-customer">
        <SelectItem
          labelname="选择客户"
          :selectlist="customers.filter((v) => v.value > 0)"
          v-model="customer"
          searchable
          class="name no-padding short narrow"
        ></SelectItem>
      </div>
      <div class="effect-date">
        <DatetimeItem
          labelname="日期"
          title="日期"
          v-model="effect_date"
          format="YYYY-MM-DD"
          class="short narrow"
          fasticon
          :clearable="false"
          :disabledDate="
            (time) => time.format('yyyy-MM-dd') > new Date().format('yyyy-MM-dd')
          "
        ></DatetimeItem>
      </div>
      <div class="select-user" v-if="guwen_users.length > 0">
        <SelectItem
          :labelname="`默认${$store.state.terms[2] ? $store.state.terms[2] : '顾问'}`"
          :selectlist="guwen_users"
          v-model="default_guwen"
          class="name no-padding short narrow guwen"
          clearable
          v-if="guwen_users.length > 0"
        ></SelectItem>
      </div>
      <div class="select-user">
        <SelectItem
          :labelname="`默认${$store.state.terms[1] ? $store.state.terms[1] : '美容师'}`"
          :selectlist="mei_users"
          v-model="default_mei"
          class="name no-padding short narrow meirongshi"
          clearable
        ></SelectItem>
      </div>
    </div>
    <div v-if="customer">
      <div>
        {{ customer.name }} 剩余品项<span class="small-text"
          >(共{{ customer_products_filtered.length }}项)</span
        >
      </div>
      <div class="filter-area">
        <div
          class="filter-button"
          v-for="item in exhausted_types"
          :key="item.value"
          @click="() => clickexhaustedtype(item)"
          :class="{
            active: active_exhausted_type && active_exhausted_type.value === item.value,
          }"
        >
          {{ item.label }}
        </div>
        <div class="break"></div>
        <div
          class="filter-button"
          v-for="item in free_types"
          :key="item.value"
          @click="() => clickfreetype(item)"
          :class="{
            active: active_free_type && active_free_type.value === item.value,
          }"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="filter-area" v-if="!$store.state.corptype">
        <div
          class="filter-button"
          v-for="item in main_types"
          :key="item.value"
          @click="() => clickmaintype(item)"
          :class="{
            active: active_main_type && active_main_type.value === item.value,
          }"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="table-wrapper left-products">
        <table>
          <thead>
            <tr>
              <th>序号</th>
              <th>名称</th>
              <th>类型</th>
              <th>余数</th>
              <th>余额</th>
              <th>购买日期</th>
              <th>赠送</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, k) in customer_products_filtered"
              :key="item.id"
              :class="{ green: item.is_free }"
              class="customer-product"
            >
              <td>{{ k + 1 }}</td>
              <td>
                <div>{{ item.material_name }}</div>
              </td>
              <td>
                <div class="small-text">
                  {{ ["产品", "项目", "套卡", "金额卡"][item.main_type] }}
                </div>
              </td>
              <td>
                <div v-if="item.main_type < 3">{{ item.left_quantity }}</div>
              </td>
              <td>
                <div>
                  {{ Math.round((item.left_price + item.left_price_free) * 10) / 10
                  }}<span v-if="!item.is_free && item.left_price_free" class="tiny-text"
                    >({{ Math.round(item.left_price * 10) / 10 }}+<span class="green"
                      >{{ Math.round(item.left_price_free * 10) / 10
                      }}<span class="small-text">赠</span></span
                    >)</span
                  >
                </div>
              </td>
              <td>
                <div>{{ new Date(item.purchasing_date).format("yyyy-MM-dd") }}</div>
              </td>
              <td><div v-if="item.is_free">赠</div></td>
              <td>
                <div
                  v-if="item.left_price + item.left_price_free || item.left_quantity"
                  class="operation-buttons"
                >
                  <template v-if="!(item.main_type === 3 && item.sub_type > 0)">
                    <div
                      v-if="item.main_type === 0"
                      class="blue-button button"
                      @click="() => xiaohao(item)"
                    >
                      出货
                    </div>
                    <div class="blue-button button" @click="() => xiaohao(item)">
                      消耗
                    </div>
                    <div class="blue-button button" @click="() => tuikaxuka(item, 1)">
                      续卡
                    </div>
                    <div class="gray-button button" @click="() => tuikaxuka(item, -1)">
                      退卡
                    </div>
                  </template>
                  <div
                    class="blue-button button"
                    v-if="item.main_type === 3 && item.sub_type === 2"
                    @click="() => repay(item)"
                  >
                    还款
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="$store.state.corptype === 1" :style="{ padding: '4px' }">
        <div>寄存产品</div>
        <div :style="{ 'padding-left': '15px' }">
          <div
            v-for="item in store_material_leftcnts"
            :key="item.id"
            v-show="item.left_cnt"
          >
            {{ item.material_name }} <span class="small-text">余:</span>{{ item.left_cnt
            }}{{ item.unitname }}
          </div>
        </div>
      </div>
      <div class="break-line"></div>
      <div class="filter-area-2">
        <div
          class="filter-button left"
          :class="{ active: ['xiaofei_products'].includes(add_type) }"
          @click="() => (add_type = 'xiaofei_products')"
        >
          开卡
        </div>
        <div
          class="filter-button middle"
          :class="{
            active: ['haoka_products', 'jine_haoka_list'].includes(add_type),
          }"
        >
          消耗
        </div>
        <div
          class="filter-button middle"
          :class="{
            active: ['arrearage'].includes(add_type),
          }"
        >
          还款
        </div>
        <div
          class="filter-button right"
          :class="{ active: ['tui_customer_products'].includes(add_type) }"
        >
          续(退)卡
        </div>
      </div>
      <!-- ### haoka_products ### -->
      <div v-if="add_type === 'haoka_products'" class="haoka-products">
        <div v-for="(product, i) in haoka_products" :key="product.id + '_' + i">
          <div v-for="(item, j) in product.haoka_list" :key="i + '_' + j">
            <div class="add-item-area">
              <div class="customer">
                <div class="type">类型:消耗</div>
                <SelectItem
                  labelname="实际客户"
                  vertical
                  :selectlist="
                    $store.state.corptype === 1
                      ? customer && customer.chain_id
                        ? customers.filter((v) => v.chain_id === customer.chain_id)
                        : []
                      : customers
                  "
                  v-model="item.customer"
                  searchable
                  class="no-padding customer small-size item"
                ></SelectItem>
              </div>
              <div class="real-customer-name">
                <InputItem
                  v-model="item.customer_name"
                  labelname="姓名"
                  class="narrow short small-size customer-name"
                  vertical
                  v-if="item.customer && item.customer.value === -1"
                ></InputItem>
              </div>
              <div>
                <InputItem
                  :labelname="`${
                    ['产品', '项目', '套卡', '金额卡'][product.main_type]
                  }名称`"
                  :value="product.material_name"
                  class="name no-padding short narrow small-size"
                  vertical
                  disabled
                ></InputItem>
              </div>
              <div
                v-if="product.main_type === 2 && product.sub_type !== 2"
                class="real-material"
              >
                <!-- 普通套卡 -->
                <SelectItem
                  v-if="product.main_type === 2 && product.sub_type !== 2"
                  labelname="项目名称"
                  vertical
                  :selectlist="
                    product.content
                      ? product.content
                          .map((v) => {
                            v.value = v.material_id;
                            v.label = v.name;
                            v.custom = true;
                            return v;
                          })
                          .concat(
                            materials
                              .filter(
                                (v) =>
                                  (v.main_type === 1 || v.main_type === 10) &&
                                  (!product.sub_material_ids ||
                                    !product.sub_material_ids.includes(v.value))
                              )
                              .map((v) => {
                                v.custom = false;
                                return v;
                              })
                          )
                      : materials.filter((v) => v.main_type === 1 || v.main_type === 10)
                  "
                  v-model="item.product"
                  class="name no-padding small-size item"
                  searchable
                ></SelectItem>
              </div>
              <div v-if="product.main_type === 2 && product.sub_type === 2">
                <!-- 限定子品项的套卡 -->
                <SelectItem
                  v-if="product.main_type === 2 && product.sub_type === 2"
                  :labelname="
                    item.product &&
                    item.product.material_id in main_type_dict &&
                    main_type_dict[item.product.material_id]['main_type'] === 2
                      ? '子套卡'
                      : `项目或产品名称`
                  "
                  vertical
                  :selectlist="
                    product.content
                      .filter((v) => v.material_id in sub_product_price[product.id])
                      .map((v) => {
                        v.value = v.material_id;
                        v.label = v.name;
                        v.custom = true;
                        return v;
                      })
                  "
                  v-model="item.product"
                  class="name no-padding small-size item"
                  searchable
                ></SelectItem>
              </div>
              <div
                v-if="
                  item.product &&
                  item.product.material_id in main_type_dict &&
                  main_type_dict[item.product.material_id]['main_type'] === 2
                "
              >
                <SelectItem
                  labelname="实际品项"
                  vertical
                  :selectlist="
                    materials.filter(
                      (v) =>
                        item.product &&
                        main_type_dict[item.product.material_id]['sub_material_ids'] &&
                        main_type_dict[item.product.material_id][
                          'sub_material_ids'
                        ].includes(v.material_id)
                    )
                  "
                  v-model="item.real_product"
                  class="name no-padding small-size item real-product"
                  v-if="
                    item.product &&
                    item.product.material_id in main_type_dict &&
                    main_type_dict[item.product.material_id]['main_type'] === 2
                  "
                ></SelectItem>
              </div>
              <div
                class="custom-product-1"
                v-if="product.main_type === 2 && item.product && item.product.value === 0"
              >
                <InputItem
                  labelname="品项名称"
                  v-model="item.custom_product_name"
                  class="narrow short small-size no-padding product-name"
                  vertical
                ></InputItem>
                <InputItem
                  labelname="单位"
                  v-model="item.custom_product_unitname"
                  class="narrow short small-size no-padding product-unitname"
                  vertical
                ></InputItem>
              </div>
              <div class="add-quantity">
                <InputItem
                  labelname="消耗数量"
                  vertical
                  class="cnt no-padding small-size item"
                  v-model="item.cnt"
                  type="number"
                ></InputItem>
              </div>
              <div class="add-price">
                <InputItem
                  labelname="消耗金额(元)"
                  vertical
                  class="amount no-padding small-size item"
                  :value="
                    Math.round(
                      (product.left_price / product.left_quantity) * item.cnt * 100
                    ) / 100
                  "
                  type="number"
                  disabled
                  v-if="
                    !(
                      product.main_type === 2 &&
                      product.sub_type === 2 &&
                      product.id in sub_product_price &&
                      item.product &&
                      item.product.material_id in sub_product_price[product.id]
                    )
                  "
                ></InputItem>
                <InputItem
                  labelname="消耗金额(元)"
                  vertical
                  class="amount no-padding small-size item"
                  :value="
                    Math.round(
                      sub_product_price[product.id][item.product.material_id][0] *
                        item.cnt *
                        100
                    ) / 100
                  "
                  type="number"
                  disabled
                  v-else
                ></InputItem>
              </div>
              <div>
                <SelectItem
                  labelname="统一消耗类别"
                  :selectlist="haoka_cats"
                  v-model="item.default_cat"
                  class="name no-padding short narrow small-size"
                  searchable
                  clearable
                  vertical
                ></SelectItem>
              </div>
              <div class="performance-wrapper">
                <div class="performance-title">
                  <div>整店绩效</div>
                  <div class="gray-button" @click="() => addallperformance(i, j)">
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.all_performance.length > 1"
                    @click="() => deleteallperformance(i, j)"
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.all_performance"
                    :key="i + '_' + j + '_all_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`消耗类别${
                          item.all_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="haoka_cats"
                        v-model="per_item.haoka.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`消耗金额${
                          item.all_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.haoka.amount"
                        type="number"
                      ></InputItem>
                      <InputItem
                        :labelname="`项数${item.all_performance.length > 1 ? k + 1 : ''}`"
                        vertical
                        class="no-padding xiangshu small-size item"
                        v-model="per_item.xiangshu.amount"
                        type="number"
                        v-if="!$store.state.corptype"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="performance-wrapper"
                v-if="guwen_users.length > 0 && $store.state.enable_guwen_xiaohao"
              >
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效
                  </div>
                  <div class="gray-button" @click="() => addguwenperformance(i, j)">
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.guwen_performance.length > 1"
                    @click="() => deleteguwenperformance(i, j)"
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.guwen_performance"
                    :key="i + '_' + j + '_gu_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                        }姓名${item.guwen_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="guwen_users"
                        v-model="per_item.user"
                        class="no-padding user small-size short narrow"
                        clearable
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`消耗类别${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="haoka_cats"
                        v-model="per_item.haoka.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`消耗金额${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.haoka.amount"
                        type="number"
                      ></InputItem>
                      <InputItem
                        :labelname="`项数${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding xiangshu small-size item"
                        v-model="per_item.xiangshu.amount"
                        type="number"
                        v-if="!$store.state.corptype"
                      ></InputItem>
                      <InputItem
                        v-for="shou_item in per_item.shougong"
                        :key="shou_item.cat.value"
                        :labelname="`${shou_item.cat.label}${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding shougong small-size item"
                        v-model="per_item.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="performance-wrapper">
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                  </div>
                  <div class="gray-button" @click="() => addmeiperformance(i, j)">
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.mei_performance.length > 1"
                    @click="() => deletemeiperformance(i, j)"
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.mei_performance"
                    :key="i + '_' + j + '_mei_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                        }姓名${item.mei_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="mei_users"
                        v-model="per_item.user"
                        clearable
                        class="no-padding user small-size short narrow"
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`消耗类别${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="haoka_cats"
                        v-model="per_item.haoka.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`消耗金额${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.haoka.amount"
                        type="number"
                      ></InputItem>
                      <InputItem
                        :labelname="`项数${item.mei_performance.length > 1 ? k + 1 : ''}`"
                        vertical
                        class="no-padding xiangshu small-size item"
                        v-model="per_item.xiangshu.amount"
                        type="number"
                        v-if="!$store.state.corptype"
                      ></InputItem>
                      <InputItem
                        v-for="shou_item in per_item.shougong"
                        :key="shou_item.cat.value"
                        :labelname="`${shou_item.cat.label}${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding shougong small-size item"
                        v-model="shou_item.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="remark">
                <InputItem
                  v-model="item.remark"
                  labelname="备注"
                  class="narrow short small-size remark"
                  vertical
                ></InputItem>
              </div>
            </div>
            <div class="relate-store" v-if="corp.inv_enabled > 0">
              <div>
                <SelectItem
                  labelname="仓库"
                  :selectlist="
                    [
                      { value: 0, label: '不关联仓库' },
                      { value: 1, label: '关联现有出入记录' },
                      { value: 2, label: '直接出库' },
                    ].filter(
                      (v) =>
                        v.value < 2 ||
                        (v.value === 2 &&
                          ((product.main_type < 2 && product.need_manage) ||
                            (product.main_type === 2 &&
                              item.product &&
                              item.product.material_id in main_type_dict &&
                              main_type_dict[item.product.material_id]['need_manage'])))
                    )
                  "
                  v-model="item.relate_material_outin"
                  class="no-padding narrow short"
                  radio
                  :id_prefix="i + '_haoka_store_' + j"
                ></SelectItem>
              </div>
              <div v-if="item.relate_material_outin === 1">
                <div class="table-wrapper">
                  <table v-if="material_outins.length > 0">
                    <thead>
                      <tr>
                        <th>选择</th>
                        <th>日期</th>
                        <th>类型</th>
                        <th>仓库</th>
                        <th>数量</th>
                        <th>备注</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in material_outins" :key="item.id">
                        <td><input v-model="item.checked" type="checkbox" /></td>
                        <td>{{ new Date(item.effect_time).format("yyyy-MM-dd") }}</td>
                        <td>{{ item.log_type }}</td>
                        <td>{{ item.store_name }}</td>
                        <td>{{ item.add_quantity }}{{ item.unitname }}</td>
                        <td>{{ item.remark }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                v-if="
                  item.relate_material_outin === 2 &&
                  store_product_left_list
                    .filter(
                      (v) =>
                        v.corp_id === my_corp.corp_id &&
                        v.material_id === item.product.material_id
                    )
                    .map((v) => {
                      return { value: v.store_id, label: v.name };
                    }).length > 0
                "
              >
                <SelectItem
                  labelname="仓库"
                  :selectlist="
                    store_product_left_list
                      .filter(
                        (v) =>
                          v.corp_id === my_corp.corp_id &&
                          v.material_id === item.product.material_id
                      )
                      .map((v) => {
                        return { value: v.store_id, label: v.name };
                      })
                  "
                  v-model="item.store_id"
                  class="no-padding narrow short"
                  radio
                  :id_prefix="'haoka_store_id_' + j"
                ></SelectItem>
              </div>
              <div
                v-if="item.relate_material_outin === 2 && item.store_id"
                :style="{ 'font-size': '0.85em', 'padding-left': '4px' }"
              >
                <div>{{ item.product.label }}</div>
                <div :style="{ display: 'flex' }">
                  <div :style="{ 'padding-right': '8px' }">
                    {{ store_product_lefts[item.store_id].name }}仓库
                  </div>
                  <div>
                    <div>
                      当前剩余:
                      <span>{{
                        store_product_lefts[item.store_id][item.product.material_id]
                      }}</span
                      >{{ item.product.unitname }}; 本次出库: {{ item.cnt
                      }}{{ item.product.unitname }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="break-line"></div>
            <div :style="{ display: 'flex', 'font-size': '1.2em' }">
              <div class="blue-button" @click="addhaokaproductitem">添加消耗记录</div>
            </div>
          </div>
        </div>
      </div>
      <!-- ### jine_haoka_list ### -->
      <div v-if="add_type === 'jine_haoka_list'" class="jine-haoka-list">
        <div v-for="(item, j) in jine_haoka_list" :key="j">
          <div class="add-item-area">
            <div class="customer">
              <div class="type">类型: 消耗金额卡</div>
              <SelectItem
                labelname="实际客户"
                vertical
                :selectlist="
                  $store.state.corptype === 1
                    ? customer && customer.chain_id
                      ? customers.filter((v) => v.chain_id === customer.chain_id)
                      : []
                    : customers
                "
                v-model="item.customer"
                searchable
                class="no-padding customer small-size item"
              ></SelectItem>
            </div>
            <div class="real-customer-name">
              <InputItem
                v-model="item.customer_name"
                labelname="姓名"
                class="narrow short small-size customer-name"
                vertical
                v-if="item.customer && item.customer.value === -1"
              ></InputItem>
            </div>
            <div>
              <InputItem
                labelname="金额卡"
                :value="item.jine_methods[0].label"
                class="name no-padding short narrow small-size"
                vertical
                disabled
              ></InputItem>
            </div>
            <div class="main-type" v-if="false">
              <SelectItem
                :selectlist="[
                  { value: 0, label: '产品' },
                  { value: 1, label: '项目' },
                ]"
                v-model="item.main_type"
                class="no-padding narrow short small-size"
                radio
                :id_prefix="'jine_main_type_' + j"
                vertical
              ></SelectItem>
            </div>
            <div class="real-material">
              <SelectItem
                labelname="项目或产品名"
                vertical
                :selectlist="
                  materials.filter(
                    (v) =>
                      v.main_type <= 1 &&
                      (true || v.main_type === item.main_type || v.main_type === 10)
                  )
                "
                v-model="item.product"
                searchable
                class="name no-padding small-size item"
              ></SelectItem>
            </div>
            <div class="custom-product-1" v-if="item.product && item.product.value === 0">
              <InputItem
                labelname="品项名称"
                v-model="item.custom_product_name"
                class="narrow short small-size no-padding product-name"
                vertical
              ></InputItem>
              <InputItem
                labelname="单位"
                v-model="item.custom_product_unitname"
                class="narrow short small-size no-padding product-unitname"
                vertical
              ></InputItem>
            </div>
            <div class="add-quantity">
              <InputItem
                labelname="消耗数量"
                vertical
                class="cnt no-padding small-size item"
                v-model="item.cnt"
                type="number"
              ></InputItem>
            </div>
            <div class="add-price input">
              <InputItem
                labelname="金额(元)"
                vertical
                class="amount no-padding small-size item"
                v-model="item.amount"
                type="number"
              ></InputItem>
            </div>
            <template>
              <div>
                <SelectItem
                  labelname="统一业绩类别"
                  :selectlist="xiaofei_cats"
                  v-model="item.default_yeji_cat"
                  class="name no-padding short narrow small-size"
                  searchable
                  clearable
                  vertical
                ></SelectItem>
              </div>
              <template v-if="item.default_yeji_cat">
                <div class="performance-wrapper kakou-yeji all">
                  <div class="performance-title">
                    <div>整店业绩</div>
                    <div class="gray-button" @click="() => addjineallyejiperformance(j)">
                      增加
                    </div>
                    <div
                      class="gray-button"
                      v-if="item.all_yeji_performance.length > 1"
                      @click="() => deletejineallyejiperformance(j)"
                    >
                      删除一个
                    </div>
                  </div>
                  <div class="performance-items">
                    <div
                      v-for="(per_item, k) in item.all_yeji_performance"
                      :key="j + '_all_yeji_' + k"
                      class="performance-item"
                    >
                      <div class="performance-row">
                        <SelectItem
                          :labelname="`业绩类别${
                            item.all_yeji_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          :selectlist="xiaofei_cats"
                          v-model="per_item.yeji.cat"
                          clearable
                          class="no-padding category small-size item"
                        ></SelectItem>
                        <InputItem
                          :labelname="`业绩金额${
                            item.all_yeji_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          class="no-padding amount small-size item"
                          v-model="per_item.yeji.amount"
                          type="number"
                        ></InputItem>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="performance-wrapper kakou-yeji guwen"
                  v-if="guwen_users.length > 0"
                >
                  <div class="performance-title">
                    <div>
                      {{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效
                    </div>
                    <div
                      class="gray-button"
                      @click="() => addjineguwenyejiperformance(j)"
                    >
                      增加
                    </div>
                    <div
                      class="gray-button"
                      v-if="item.guwen_yeji_performance.length > 1"
                      @click="() => deletejineguwenyejiperformance(j)"
                    >
                      删除一个
                    </div>
                  </div>
                  <div class="performance-items">
                    <div
                      v-for="(per_item, k) in item.guwen_yeji_performance"
                      :key="j + '_gu_yeji_' + k"
                      class="performance-item"
                    >
                      <div class="performance-row">
                        <SelectItem
                          :labelname="`${
                            $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                          }姓名${item.guwen_yeji_performance.length > 1 ? k + 1 : ''}`"
                          :selectlist="guwen_users"
                          v-model="per_item.user"
                          class="no-padding user small-size short narrow"
                          clearable
                          vertical
                        ></SelectItem>
                        <SelectItem
                          :labelname="`业绩类别${
                            item.guwen_yeji_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          :selectlist="xiaofei_cats"
                          v-model="per_item.yeji.cat"
                          clearable
                          class="no-padding category small-size item"
                        ></SelectItem>
                        <InputItem
                          :labelname="`业绩金额${
                            item.guwen_yeji_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          class="no-padding amount small-size item"
                          v-model="per_item.yeji.amount"
                          type="number"
                        ></InputItem>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="performance-wrapper kakou-yeji mei">
                  <div class="performance-title">
                    <div>
                      {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                    </div>
                    <div class="gray-button" @click="() => addjinemeiyejiperformance(j)">
                      增加
                    </div>
                    <div
                      class="gray-button"
                      v-if="item.mei_yeji_performance.length > 1"
                      @click="() => deletejinemeiyejiperformance(j)"
                    >
                      删除一个
                    </div>
                  </div>
                  <div class="performance-items">
                    <div
                      v-for="(per_item, k) in item.mei_yeji_performance"
                      :key="j + '_mei_yeji_' + k"
                      class="performance-item"
                    >
                      <div class="performance-row">
                        <SelectItem
                          :labelname="`${
                            $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                          }姓名${item.mei_yeji_performance.length > 1 ? k + 1 : ''}`"
                          :selectlist="mei_users"
                          v-model="per_item.user"
                          clearable
                          class="no-padding user small-size short narrow"
                          vertical
                        ></SelectItem>
                        <SelectItem
                          :labelname="`业绩类别${
                            item.mei_yeji_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          :selectlist="xiaofei_cats"
                          v-model="per_item.yeji.cat"
                          clearable
                          class="no-padding category small-size item"
                        ></SelectItem>
                        <InputItem
                          :labelname="`业绩金额${
                            item.mei_yeji_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          class="no-padding amount small-size item"
                          v-model="per_item.yeji.amount"
                          type="number"
                        ></InputItem>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div
                :style="{
                  'margin-left': '20px',
                  'margin-right': '20px',
                  height: '50px',
                  'border-left': '1px solid #aaa',
                }"
                v-if="haoka_cats && haoka_cats.length > 0"
              ></div>
            </template>
            <template v-if="haoka_cats && haoka_cats.length > 0">
              <div>
                <SelectItem
                  labelname="统一消耗类别"
                  :selectlist="haoka_cats"
                  v-model="item.default_cat"
                  class="name no-padding short narrow small-size"
                  searchable
                  clearable
                  vertical
                ></SelectItem>
              </div>
              <div class="performance-wrapper">
                <div class="performance-title">
                  <div>整店绩效</div>
                  <div class="gray-button" @click="() => addjineallperformance(j)">
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.all_performance.length > 1"
                    @click="() => deletejineallperformance(j)"
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.all_performance"
                    :key="j + '_all_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`消耗类别${
                          item.all_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="haoka_cats"
                        v-model="per_item.haoka.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`消耗金额${
                          item.all_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.haoka.amount"
                        type="number"
                      ></InputItem>
                      <InputItem
                        :labelname="`项数${item.all_performance.length > 1 ? k + 1 : ''}`"
                        vertical
                        class="no-padding xiangshu small-size item"
                        v-model="per_item.xiangshu.amount"
                        type="number"
                        v-if="!$store.state.corptype"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="performance-wrapper"
                v-if="guwen_users.length > 0 && $store.state.enable_guwen_xiaohao"
              >
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效
                  </div>
                  <div class="gray-button" @click="() => addjineguwenperformance(j)">
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.guwen_performance.length > 1"
                    @click="() => deletejineguwenperformance(j)"
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.guwen_performance"
                    :key="j + '_gu_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                        }姓名${item.guwen_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="guwen_users"
                        v-model="per_item.user"
                        class="no-padding user small-size short narrow"
                        clearable
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`消耗类别${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="haoka_cats"
                        v-model="per_item.haoka.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`消耗金额${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.haoka.amount"
                        type="number"
                      ></InputItem>
                      <InputItem
                        :labelname="`项数${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding xiangshu small-size item"
                        v-model="per_item.xiangshu.amount"
                        type="number"
                        v-if="!$store.state.corptype"
                      ></InputItem>
                      <InputItem
                        v-for="shou_item in per_item.shougong"
                        :key="shou_item.cat.value"
                        :labelname="shou_item.cat.label"
                        vertical
                        class="no-padding shougong small-size item"
                        v-model="per_item.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="performance-wrapper">
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                  </div>
                  <div class="gray-button" @click="() => addjinemeiperformance(j)">
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.mei_performance.length > 1"
                    @click="() => deletejinemeiperformance(j)"
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.mei_performance"
                    :key="j + '_mei_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                        }姓名${item.mei_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="mei_users"
                        v-model="per_item.user"
                        clearable
                        class="no-padding user small-size short narrow"
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`消耗类别${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="haoka_cats"
                        v-model="per_item.haoka.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`消耗金额${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.haoka.amount"
                        type="number"
                      ></InputItem>
                      <InputItem
                        :labelname="`项数${item.mei_performance.length > 1 ? k + 1 : ''}`"
                        vertical
                        class="no-padding xiangshu small-size item"
                        v-model="per_item.xiangshu.amount"
                        type="number"
                        v-if="!$store.state.corptype"
                      ></InputItem>
                      <InputItem
                        v-for="shou_item in per_item.shougong"
                        :key="shou_item.cat.value"
                        :labelname="`${shou_item.cat.label}${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding shougong small-size item"
                        v-model="shou_item.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="remark">
                <InputItem
                  v-model="item.remark"
                  labelname="备注"
                  class="narrow short small-size remark"
                  vertical
                ></InputItem>
              </div>
            </template>
          </div>
          <div class="jine_amount">
            <div>
              <span>{{ item.jine_methods[0].label }}</span
              ><span v-if="item.jine_methods[0].is_free">(赠)</span>
              <span>
                <span class="small-text"> 余</span>:
                {{
                  Math.round(
                    (item.jine_methods[0].left_price +
                      item.jine_methods[0].left_price_free) *
                      100
                  ) / 100
                }}元
                <span v-if="item.jine_methods[0].left_price_free" class="small-text"
                  >({{ item.jine_methods[0].left_price }}+<span class="green"
                    >{{ item.jine_methods[0].left_price_free }}赠</span
                  >){{ item.jine_methods[0].ratio }}</span
                ></span
              >
              <span
                v-if="corp && item.jine_methods[0].corp_id !== corp.corp_id"
                class="red small-text"
                >(跨店消耗)</span
              >
            </div>
            <div :style="{ 'padding-left': '5px' }">
              <span class="small-text">扣</span>:<span>{{
                item.jine_methods[0].amount
              }}</span>
              <span class="small-text">元</span>
              <span>
                <span
                  class="red bold"
                  v-if="
                    item.jine_methods[0].left_price +
                      item.jine_methods[0].left_price_free <
                    item.jine_methods[0].amount
                  "
                  >(不足以抵扣)</span
                ><span v-else class="small-text">
                  (<span
                    v-if="!item.jine_methods[0].ratio || item.jine_methods[0].radio === 1"
                    >{{ item.jine_methods[0].real_amount }}</span
                  ><input
                    v-model.number="item.jine_methods[0].real_amount"
                    class="real-amount"
                    v-else
                  />+<span class="green"
                    >{{
                      (item.jine_methods[0].amount ? item.jine_methods[0].amount : 0) -
                      item.jine_methods[0].real_amount
                    }}赠</span
                  >)</span
                >
              </span>
            </div>
          </div>
          <div class="relate-store" v-if="item.product">
            <div>
              <SelectItem
                labelname="仓库"
                :selectlist="
                  [
                    { value: 0, label: '不关联仓库' },
                    { value: 1, label: '关联现有出入记录' },
                    { value: 2, label: '直接出库' },
                  ].filter(
                    (v) =>
                      v.value < 2 ||
                      (v.value === 2 &&
                        item.product.main_type < 2 &&
                        item.product.need_manage)
                  )
                "
                v-model="item.relate_material_outin"
                class="no-padding narrow short"
                radio
                :id_prefix="'jine_haoka_store_' + j"
              ></SelectItem>
            </div>
            <div v-if="item.relate_material_outin === 1">
              <div class="table-wrapper">
                <table v-if="material_outins.length > 0">
                  <thead>
                    <tr>
                      <th>选择</th>
                      <th>日期</th>
                      <th>类型</th>
                      <th>仓库</th>
                      <th>数量</th>
                      <th>备注</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in material_outins" :key="item.id">
                      <td><input v-model="item.checked" type="checkbox" /></td>
                      <td>{{ new Date(item.effect_time).format("yyyy-MM-dd") }}</td>
                      <td>{{ item.log_type }}</td>
                      <td>{{ item.store_name }}</td>
                      <td>{{ item.add_quantity }}{{ item.unitname }}</td>
                      <td>{{ item.remark }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-if="
                item.relate_material_outin === 2 &&
                store_product_left_list
                  .filter(
                    (v) =>
                      v.corp_id === my_corp.corp_id &&
                      v.material_id === item.product.material_id
                  )
                  .map((v) => {
                    return { value: v.store_id, label: v.name };
                  }).length > 0
              "
            >
              <SelectItem
                :selectlist="
                  store_product_left_list
                    .filter(
                      (v) =>
                        v.corp_id === my_corp.corp_id &&
                        v.material_id === item.product.material_id
                    )
                    .map((v) => {
                      return { value: v.store_id, label: v.name };
                    })
                "
                v-model="item.store_id"
                class="no-padding narrow small-size short"
                radio
                :id_prefix="'jine_haoka_store_id_' + j"
              ></SelectItem>
            </div>
            <div
              v-if="item.relate_material_outin === 2 && item.store_id"
              :style="{ 'font-size': '0.85em', 'padding-left': '4px' }"
            >
              <div>{{ item.product.label }}</div>
              <div :style="{ display: 'flex' }">
                <div :style="{ 'padding-right': '8px' }">
                  {{ store_product_lefts[item.store_id].name }}仓库中
                </div>
                <div>
                  <div>
                    当前剩余:
                    <span>{{
                      store_product_lefts[item.store_id][item.product.material_id]
                    }}</span
                    >{{ item.product.unitname }}; 本次出库: {{ item.cnt
                    }}{{ item.product.unitname }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="break-line"></div>
          <div :style="{ display: 'flex', 'font-size': '1.2em' }">
            <div class="blue-button" @click="addjinehaokaproductitem">添加消耗记录</div>
          </div>
        </div>
      </div>
      <!-- ### xiaofei_products ### -->
      <div v-if="add_type === 'xiaofei_products'" class="xiaofei_products">
        <div
          v-for="(xiaofei_product_item, i) in xiaofei_products"
          :key="'xiaofei_product_' + i"
        >
          <div>
            <SelectItem
              labelname="开卡-大类"
              :selectlist="
                [
                  { value: 0, label: '产品' },
                  { value: 1, label: '项目' },
                  { value: 2, label: '套卡' },
                  { value: 3, label: '金额卡' },
                ].filter((v) => !$store.state.corptype || v.value === 0 || v.value === 3)
              "
              v-model="main_type"
              class="no-padding narrow short"
              radio
              :disabled="xiaofei_product_item.methods.length > 0"
              :id_prefix="'main-type-selector'"
            ></SelectItem>
          </div>
          <div class="add-xiaofei-item-area">
            <div class="material">
              <SelectItem
                :labelname="`待购买或赠送的品项${
                  xiaofei_product_item.product
                    ? `(${
                        ['已淘汰', '一般', '主推'][
                          xiaofei_product_item.product.material_status + 1
                        ]
                      })`
                    : ''
                }`"
                :selectlist="
                  materials.filter(
                    (v) => (v.on_sale && v.main_type === main_type) || v.main_type === 10
                  )
                "
                v-model="xiaofei_product_item.product"
                class="no-padding narrow small-size"
                vertical
                clearable
                searchable
                :disabled="xiaofei_product_item.methods.length > 0"
              ></SelectItem>
            </div>
            <div class="cnt">
              <InputItem
                :labelname="`数量${
                  xiaofei_product_item.product &&
                  ((xiaofei_product_item.product.unitname &&
                    xiaofei_product_item.unitname === 0) ||
                    (xiaofei_product_item.product.set_unitname &&
                      xiaofei_product_item.unitname === -1) ||
                    (xiaofei_product_item.product.spec_unitname &&
                      xiaofei_product_item.unitname === 1))
                    ? `(${
                        [
                          xiaofei_product_item.product.set_unitname,
                          xiaofei_product_item.product.unitname,
                          xiaofei_product_item.product.spec_unitname,
                        ][xiaofei_product_item.unitname + 1]
                      })`
                    : ''
                }`"
                vertical
                class="small-size no-padding"
                v-model="xiaofei_product_item.cnt"
                type="number"
                :disabled="
                  (xiaofei_product_item.product &&
                    xiaofei_product_item.product.main_type === 3) ||
                  main_type === 3 ||
                  (xiaofei_product_item.product &&
                    xiaofei_product_item.product.main_type === 2 &&
                    xiaofei_product_item.product.sub_type === 2) ||
                  xiaofei_product_item.methods.length > 0
                "
              ></InputItem>
            </div>
            <div class="amount">
              <InputItem
                labelname="总金额"
                vertical
                class="small-size no-padding"
                v-model="xiaofei_product_item.amount"
                type="number"
                :disabled="
                  (xiaofei_product_item.product &&
                    xiaofei_product_item.product.main_type === 2 &&
                    xiaofei_product_item.product.sub_type === 2) ||
                  xiaofei_product_item.methods.length > 0
                "
              ></InputItem>
            </div>
            <div class="users" v-if="guwen_users.length > 0">
              <SelectItem
                :labelname="`${
                  $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                }姓名`"
                :selectlist="guwen_users"
                v-model="xiaofei_product_item.guwen_users"
                class="no-padding narrow small-size users"
                multiple
                vertical
                clearable
                searchable
                :disabled="xiaofei_product_item.methods.length > 0"
              ></SelectItem>
            </div>
            <div class="users">
              <SelectItem
                :labelname="`${
                  $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                }姓名`"
                :selectlist="mei_users.filter((v) => v.role_type === 1)"
                v-model="xiaofei_product_item.mei_users"
                class="no-padding narrow small-size users"
                multiple
                vertical
                clearable
                searchable
                :disabled="xiaofei_product_item.methods.length > 0"
              ></SelectItem>
            </div>
            <div>
              <SelectItem
                labelname="支付方式"
                :selectlist="
                  payments.filter(
                    (v) =>
                      v.payment_type >= 0 &&
                      ((xiaofei_product_item.product &&
                        xiaofei_product_item.product.value !== 0) ||
                        v.payment_type === 2 ||
                        v.payment_type === 4)
                  )
                "
                v-model="methods"
                multiple
                class="no-padding narrow small-size methods red"
                vertical
                clearable
                searchable
              ></SelectItem>
            </div>
            <div class="remark">
              <InputItem
                labelname="备注"
                vertical
                class="small-size short no-padding"
                v-model="xiaofei_product_item.remark"
              ></InputItem>
            </div>
          </div>
          <template v-for="(item, j) in xiaofei_product_item.methods">
            <div :key="'xiaofei_' + i + '_method_' + j" class="add-item-area more-margin">
              <div class="method">
                <SelectItem
                  :labelname="`支付方式${
                    xiaofei_product_item.methods.length > 1 ? j + 1 : ''
                  }`"
                  vertical
                  :selectlist="
                    payments.filter(
                      (v) =>
                        v.payment_type >= 0 &&
                        (xiaofei_product_item.product.value !== 0 ||
                          v.payment_type === 2 ||
                          v.payment_type === 4)
                    )
                  "
                  v-model="item.method"
                  class="no-padding small-size method red"
                ></SelectItem>
              </div>
              <div class="amount">
                <InputItem
                  labelname="金额"
                  v-model="item.amount"
                  class="narrow short small-size fake amount"
                  type="number"
                  :disabled="xiaofei_product_item.methods.length - 1 === j"
                  vertical
                ></InputItem>
              </div>
              <div
                class="amount"
                v-if="
                  $store.state.dao_amount_enabled &&
                  xiaofei_product_item.product.dao_ratio < 100 &&
                  item.method &&
                  item.method.payment_type === 0
                "
              >
                <InputItem
                  labelname="店内分成"
                  v-model="item.dao_amount"
                  class="narrow short small-size fake amount"
                  type="number"
                  v-if="
                    $store.state.dao_amount_enabled &&
                    xiaofei_product_item.product.dao_ratio < 100 &&
                    item.method &&
                    item.method.payment_type === 0
                  "
                  vertical
                ></InputItem>
              </div>

              <div class="default-cat">
                <SelectItem
                  labelname="统一业绩类别"
                  :selectlist="xiaofei_cats"
                  v-model="item.default_cat"
                  class="short no-padding small-size"
                  clearable
                  vertical
                ></SelectItem>
              </div>
              <div class="performance-wrapper">
                <div class="performance-title">
                  <div>整店绩效</div>
                  <div
                    class="gray-button"
                    @click="() => addperformance('xiaofei', 'all_performance', i, j)"
                  >
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.all_performance.length > 1"
                    @click="
                      () =>
                        deleteperformance(
                          'xiaofei',
                          'all_performance',
                          i,
                          j,
                          item.all_performance.length - 1
                        )
                    "
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.all_performance"
                    :key="'xiaofei_' + i + '_' + j + '_all_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`业绩类别${
                          item.all_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="xiaofei_cats"
                        v-model="per_item.yeji.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`业绩金额${
                          item.all_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.yeji.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="performance-wrapper" v-if="item.guwen_performance.length">
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效
                  </div>
                  <div
                    class="gray-button"
                    @click="() => addperformance('xiaofei', 'guwen_performance', i, j)"
                  >
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.guwen_performance.length > 1"
                    @click="
                      () =>
                        deleteperformance(
                          'xiaofei',
                          'guwen_performance',
                          i,
                          j,
                          item.guwen_performance.length - 1
                        )
                    "
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.guwen_performance"
                    :key="'xiaofei_' + i + '_' + j + '_guwen_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                        }姓名${item.guwen_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="guwen_users"
                        v-model="per_item.user"
                        class="no-padding user small-size short narrow"
                        clearable
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`业绩类别${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="xiaofei_cats"
                        v-model="per_item.yeji.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`业绩金额${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.yeji.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="performance-wrapper" v-if="item.mei_performance.length">
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                  </div>
                  <div
                    class="gray-button"
                    @click="() => addperformance('xiaofei', 'mei_performance', i, j)"
                  >
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.mei_performance.length > 1"
                    @click="
                      () =>
                        deleteperformance(
                          'xiaofei',
                          'mei_performance',
                          i,
                          j,
                          item.mei_performance.length - 1
                        )
                    "
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.mei_performance"
                    :key="'xiaofei_' + i + '_' + j + '_mei_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                        }姓名${item.mei_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="mei_users.filter((v) => v.role_type === 1)"
                        v-model="per_item.user"
                        class="no-padding user small-size short narrow"
                        clearable
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`业绩类别${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="xiaofei_cats"
                        v-model="per_item.yeji.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`业绩金额${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.yeji.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :key="'xiaofei_' + i + '_jine-cp_' + j" class="jine-customer-products">
              <SelectItem
                labelname="金额卡"
                :selectlist="
                  customer_products.filter(
                    (v) => v.main_type === 3 && v.left_price + v.left_price_free > 0
                  )
                "
                v-model="item.jine_product"
                v-if="item.method && item.method.payment_type === 1"
                class="no-padding short jine-product narrow small-size"
              ></SelectItem>
              <div v-if="item.jine_product" class="small-text jine-product-desc">
                <span v-if="!item.jine_product.is_free">
                  {{ item.jine_product.material_name }} 余{{
                    item.jine_product.left_price + item.jine_product.left_price_free
                  }}元<span v-if="item.jine_product.left_price_free" class="small-text"
                    >({{ item.jine_product.left_price }}+<span class="green"
                      >{{ item.jine_product.left_price_free
                      }}<span class="small-text">赠</span></span
                    >)</span
                  >
                </span>
                <span v-else class="green"
                  >{{ item.jine_product.material_name }}(赠) 余{{
                    item.jine_product.left_price + item.jine_product.left_price_free
                  }}<span></span>元<span class="small-text"
                    >({{ item.jine_product.left_price }}+<span class="green"
                      >{{ item.jine_product.left_price_free
                      }}<span class="small-text">赠</span></span
                    >)</span
                  ></span
                ><span
                  class="red bold"
                  v-if="
                    item.jine_product.left_price + item.jine_product.left_price_free <
                    item.amount
                  "
                  >(不足以抵扣)</span
                ><span v-else>
                  扣<input v-model.number="item.real_amount" />+<span class="green"
                    >{{
                      Math.round((item.amount - item.real_amount) * 100) / 100
                    }}(赠)</span
                  ></span
                >
              </div>
            </div>
          </template>
          <div
            :style="{ display: 'flex', margin: '5px 0 0 30px' }"
            v-if="xiaofei_product_item.methods.length > 0"
          >
            <div class="blue-button" @click="() => addmethod('xiaofei', i)">
              增加支付方式
            </div>
          </div>
          <div
            v-if="
              xiaofei_product_item.product &&
              xiaofei_product_item.product.main_type < 2 &&
              (xiaofei_product_item.cnt || xiaofei_product_item.cnt === 0)
            "
          >
            <div class="break-line"></div>
            <div class="break-line"></div>
            <input
              type="checkbox"
              :id="'xiaofei_xiaohao_' + i"
              v-model="xiaofei_product_item.consumed"
              :style="{ margin: '0 3px' }"
              :disabled="xiaofei_product_item.product.main_type >= 2"
            />
            <label :for="'xiaofei_xiaohao_' + i"
              >当天已{{
                [["拿", "发"][$store.state.corptype], "耗"][
                  xiaofei_product_item.product.main_type
                ]
              }}</label
            >
          </div>
          <div
            v-if="xiaofei_product_item.consumed && haoka_cats && haoka_cats.length > 0"
            class="add-item-area"
          >
            <div>
              <SelectItem
                labelname="统一消耗类别"
                :selectlist="haoka_cats"
                v-model="xiaofei_product_item.default_cat"
                class="name no-padding short narrow small-size"
                searchable
                clearable
                vertical
              ></SelectItem>
            </div>
            <div class="performance-wrapper">
              <div class="performance-title">
                <div>整店绩效</div>
                <div
                  class="gray-button"
                  @click="() => addperformance('xiaohao', 'all_performance', i, 0)"
                >
                  增加
                </div>
                <div
                  class="gray-button"
                  v-if="xiaofei_product_item.all_performance.length > 1"
                  @click="
                    () =>
                      deleteperformance(
                        'xiaohao',
                        'all_performance',
                        i,
                        0,
                        xiaofei_product_item.all_performance.length - 1
                      )
                  "
                >
                  删除一个
                </div>
              </div>
              <div class="performance-items">
                <div
                  v-for="(per_item, k) in xiaofei_product_item.all_performance"
                  :key="i + '_all_' + k"
                  class="performance-item"
                >
                  <div class="performance-row">
                    <SelectItem
                      :labelname="`消耗类别${
                        xiaofei_product_item.all_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      :selectlist="haoka_cats"
                      v-model="per_item.haoka.cat"
                      clearable
                      class="no-padding category small-size item"
                    ></SelectItem>
                    <InputItem
                      :labelname="`消耗金额${
                        xiaofei_product_item.all_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      class="no-padding amount small-size item"
                      v-model="per_item.haoka.amount"
                      type="number"
                    ></InputItem>
                    <InputItem
                      :labelname="`项数${
                        xiaofei_product_item.all_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      class="no-padding xiangshu small-size item"
                      v-model="per_item.xiangshu.amount"
                      type="number"
                      v-if="!$store.state.corptype"
                    ></InputItem>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="performance-wrapper"
              v-if="guwen_users.length > 0 && $store.state.enable_guwen_xiaohao"
            >
              <div class="performance-title">
                <div>
                  {{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效
                </div>
                <div
                  class="gray-button"
                  @click="() => addperformance('xiaohao', 'guwen_performance', i, 0)"
                >
                  增加
                </div>
                <div
                  class="gray-button"
                  v-if="xiaofei_product_item.guwen_performance.length > 1"
                  @click="
                    () =>
                      deleteperformance(
                        'xiaohao',
                        'guwen_performance',
                        i,
                        0,
                        xiaofei_product_item.all_performance.length - 1
                      )
                  "
                >
                  删除一个
                </div>
              </div>
              <div class="performance-items">
                <div
                  v-for="(per_item, k) in xiaofei_product_item.guwen_performance"
                  :key="i + '_gu_' + k"
                  class="performance-item"
                >
                  <div class="performance-row">
                    <SelectItem
                      :labelname="`${
                        $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                      }姓名${
                        xiaofei_product_item.guwen_performance.length > 1 ? k + 1 : ''
                      }`"
                      :selectlist="guwen_users"
                      v-model="per_item.user"
                      class="no-padding user small-size short narrow"
                      clearable
                      vertical
                    ></SelectItem>
                    <SelectItem
                      :labelname="`消耗类别${
                        xiaofei_product_item.guwen_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      :selectlist="haoka_cats"
                      v-model="per_item.haoka.cat"
                      clearable
                      class="no-padding category small-size item"
                    ></SelectItem>
                    <InputItem
                      :labelname="`消耗金额${
                        xiaofei_product_item.guwen_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      class="no-padding amount small-size item"
                      v-model="per_item.haoka.amount"
                      type="number"
                    ></InputItem>
                    <InputItem
                      :labelname="`项数${
                        xiaofei_product_item.guwen_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      class="no-padding xiangshu small-size item"
                      v-model="per_item.xiangshu.amount"
                      type="number"
                      v-if="!$store.state.corptype"
                    ></InputItem>
                    <InputItem
                      v-for="shou_item in per_item.shougong"
                      :key="shou_item.cat.value"
                      :labelname="shou_item.cat.label"
                      vertical
                      class="no-padding shougong small-size item"
                      v-model="per_item.amount"
                      type="number"
                    ></InputItem>
                  </div>
                </div>
              </div>
            </div>
            <div class="performance-wrapper">
              <div class="performance-title">
                <div>
                  {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                </div>
                <div
                  class="gray-button"
                  @click="() => addperformance('xiaohao', 'mei_performance', i, 0)"
                >
                  增加
                </div>
                <div
                  class="gray-button"
                  v-if="xiaofei_product_item.mei_performance.length > 1"
                  @click="
                    () =>
                      deleteperformance(
                        'xiaohao',
                        'mei_performance',
                        i,
                        0,
                        xiaofei_product_item.all_performance.length - 1
                      )
                  "
                >
                  删除一个
                </div>
              </div>
              <div class="performance-items">
                <div
                  v-for="(per_item, k) in xiaofei_product_item.mei_performance"
                  :key="i + '_mei_' + k"
                  class="performance-item"
                >
                  <div class="performance-row">
                    <SelectItem
                      :labelname="`${
                        $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                      }姓名${
                        xiaofei_product_item.mei_performance.length > 1 ? k + 1 : ''
                      }`"
                      :selectlist="mei_users"
                      v-model="per_item.user"
                      clearable
                      class="no-padding user small-size short narrow"
                      vertical
                    ></SelectItem>
                    <SelectItem
                      :labelname="`消耗类别${
                        xiaofei_product_item.mei_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      :selectlist="haoka_cats"
                      v-model="per_item.haoka.cat"
                      clearable
                      class="no-padding category small-size item"
                    ></SelectItem>
                    <InputItem
                      :labelname="`消耗金额${
                        xiaofei_product_item.mei_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      class="no-padding amount small-size item"
                      v-model="per_item.haoka.amount"
                      type="number"
                    ></InputItem>
                    <InputItem
                      :labelname="`项数${
                        xiaofei_product_item.mei_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      class="no-padding xiangshu small-size item"
                      v-model="per_item.xiangshu.amount"
                      type="number"
                      v-if="!$store.state.corptype"
                    ></InputItem>
                    <InputItem
                      v-for="shou_item in per_item.shougong"
                      :key="shou_item.cat.value"
                      :labelname="`${shou_item.cat.label}${
                        xiaofei_product_item.mei_performance.length > 1 ? k + 1 : ''
                      }`"
                      vertical
                      class="no-padding shougong small-size item"
                      v-model="shou_item.amount"
                      type="number"
                    ></InputItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="relate-store"
            v-if="xiaofei_product_item.consumed && xiaofei_product_item.product"
          >
            <div>
              <SelectItem
                labelname="仓库"
                :selectlist="
                  [
                    { value: 0, label: '不关联仓库' },
                    { value: 1, label: '关联现有出入记录' },
                    { value: 2, label: '直接出库' },
                  ].filter(
                    (v) =>
                      v.value < 2 ||
                      (v.value === 2 &&
                        xiaofei_product_item.product.main_type < 2 &&
                        xiaofei_product_item.product.need_manage)
                  )
                "
                v-model="xiaofei_product_item.relate_material_outin"
                class="no-padding narrow short"
                radio
                :id_prefix="'xiaofei_xiaohao_store_' + i"
              ></SelectItem>
            </div>
            <div v-if="xiaofei_product_item.relate_material_outin === 1">
              <div class="table-wrapper">
                <table v-if="material_outins.length > 0">
                  <thead>
                    <tr>
                      <th>选择</th>
                      <th>日期</th>
                      <th>类型</th>
                      <th>仓库</th>
                      <th>数量</th>
                      <th>备注</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in material_outins" :key="item.id">
                      <td><input v-model="item.checked" type="checkbox" /></td>
                      <td>{{ new Date(item.effect_time).format("yyyy-MM-dd") }}</td>
                      <td>{{ item.log_type }}</td>
                      <td>{{ item.store_name }}</td>
                      <td>{{ item.add_quantity }}{{ item.unitname }}</td>
                      <td>{{ item.remark }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-if="
                xiaofei_product_item.relate_material_outin === 2 &&
                store_product_left_list
                  .filter(
                    (v) =>
                      v.corp_id === my_corp.corp_id &&
                      v.material_id === xiaofei_product_item.product.material_id
                  )
                  .map((v) => {
                    return { value: v.store_id, label: v.name };
                  }).length > 0
              "
            >
              <SelectItem
                :selectlist="
                  store_product_left_list
                    .filter(
                      (v) =>
                        v.corp_id === my_corp.corp_id &&
                        v.material_id === xiaofei_product_item.product.material_id
                    )
                    .map((v) => {
                      return { value: v.store_id, label: v.name };
                    })
                "
                v-model="xiaofei_product_item.store_id"
                class="no-padding narrow small-size short"
                radio
                :id_prefix="'xiaofei_store_id_' + i"
              ></SelectItem>
            </div>
            <div
              v-if="
                xiaofei_product_item.relate_material_outin === 2 &&
                xiaofei_product_item.store_id
              "
              :style="{ 'font-size': '0.85em', 'padding-left': '4px' }"
            >
              <div>{{ xiaofei_product_item.product.label }}</div>
              <div :style="{ display: 'flex' }">
                <div :style="{ 'padding-right': '8px' }">
                  {{ store_product_lefts[xiaofei_product_item.store_id].name }}仓库中
                </div>
                <div>
                  <div>
                    当前剩余:
                    <span>{{
                      store_product_lefts[xiaofei_product_item.store_id][
                        xiaofei_product_item.product.material_id
                      ]
                    }}</span
                    >{{ xiaofei_product_item.product.unitname }}; 本次出库:
                    {{ xiaofei_product_item.cnt
                    }}{{ xiaofei_product_item.product.unitname }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="break-line"></div>
        <div class="break-line"></div>
        <div class="break-line"></div>
        <div :style="{ display: 'flex', 'font-size': '1.2em' }">
          <div class="blue-button" @click="addxiaofeiproductitem">添加消费记录</div>
        </div>
      </div>
      <!-- ### tui_customer_products ### -->
      <div v-if="add_type === 'tui_customer_products'" class="tui_customer_products">
        <div
          v-for="(product, i) in tui_customer_products"
          :key="'tui_customer_product_' + i"
        >
          <div :style="{ padding: '8px 0 0 2px' }" class="bold">
            类型: {{ ["退卡", "续卡"][(product.show_tuikaxuka_func + 1) / 2] }}
          </div>
          <div class="add-xiaofei-item-area">
            <div>
              <InputItem
                :labelname="
                  ['产品', '项目', '套卡', '金额卡'][product.main_type] + '名称'
                "
                vertical
                class="small-size no-padding"
                :value="product.material_name"
                disabled
              ></InputItem>
            </div>
            <div class="cnt" v-if="product.main_type < 3">
              <InputItem
                :labelname="
                  ['退', '续卡'][(product.show_tuikaxuka_func + 1) / 2] + '数量'
                "
                vertical
                class="small-size no-padding"
                v-model="product.return_cnt"
                type="number"
              ></InputItem>
            </div>
            <div class="amount">
              <InputItem
                :labelname="
                  ['退', '续卡'][(product.show_tuikaxuka_func + 1) / 2] + '金额'
                "
                vertical
                class="small-size no-padding"
                v-model="product.return_amount"
                type="number"
              ></InputItem>
            </div>
            <div
              class="amount"
              v-if="product.show_tuikaxuka_func === -1 && product.left_price_free"
            >
              <InputItem
                labelname="退赠送额"
                vertical
                class="small-size no-padding"
                v-model="product.return_amount_free"
                type="number"
              ></InputItem>
            </div>
            <div class="users">
              <SelectItem
                :labelname="`${
                  $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                }姓名`"
                :selectlist="guwen_users"
                v-model="product.guwen_users"
                class="no-padding narrow small-size users"
                multiple
                vertical
                clearable
                searchable
                :disabled="tui_methods.length > 0"
              ></SelectItem>
            </div>
            <div class="users">
              <SelectItem
                :labelname="`${
                  $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                }姓名`"
                :selectlist="mei_users.filter((v) => v.role_type === 1)"
                v-model="product.mei_users"
                class="no-padding narrow small-size users"
                multiple
                vertical
                clearable
                searchable
                :disabled="tui_methods.length > 0"
              ></SelectItem>
            </div>
            <div v-if="false">
              <SelectItem
                labelname="支付方式"
                :selectlist="
                  payments.filter(
                    (v) =>
                      (v.payment_type !== 3 ||
                        product.show_tuikaxuka_func === 1 ||
                        (product.show_tuikaxuka_func === -1 &&
                          arrearage &&
                          product.material_id in arrearage.materialiddict &&
                          arrearage.materialiddict[product.material_id]['left_price'] <
                            0)) &&
                      !(product.is_free && v.payment_type !== 4)
                  )
                "
                v-model="tui_methods"
                multiple
                class="no-padding narrow small-size methods red"
                vertical
                clearable
                searchable
              ></SelectItem>
            </div>
            <div class="remark">
              <InputItem
                labelname="备注"
                vertical
                class="small-size short no-padding"
                v-model="product.remark"
              ></InputItem>
            </div>
          </div>
          <template v-for="(item, j) in product.methods">
            <div :key="'tui_' + i + '_method_' + j" class="add-item-area more-margin">
              <div class="method">
                <SelectItem
                  :labelname="`支付方式${product.methods.length > 1 ? j + 1 : ''}`"
                  vertical
                  :selectlist="
                    payments.filter(
                      (v) =>
                        (v.payment_type !== 3 ||
                          product.show_tuikaxuka_func === 1 ||
                          (product.show_tuikaxuka_func === -1 &&
                            arrearage &&
                            product.material_id in arrearage.materialiddict &&
                            arrearage.materialiddict[product.material_id]['left_price'] <
                              0)) &&
                        !(product.is_free && v.payment_type !== 4)
                    )
                  "
                  v-model="item.method"
                  class="no-padding small-size method red"
                ></SelectItem>
              </div>
              <div class="amount">
                <InputItem
                  labelname="金额"
                  v-model="item.amount"
                  class="narrow short small-size fake amount"
                  type="number"
                  :disabled="j === product.methods.length - 1"
                  vertical
                ></InputItem>
              </div>
              <div
                class="amount"
                v-if="
                  $store.state.dao_amount_enabled &&
                  product.dao_ratio < 100 &&
                  item.method &&
                  item.method.payment_type === 0
                "
              >
                <InputItem
                  labelname="店内分成"
                  v-model="item.dao_amount"
                  class="narrow short small-size fake amount"
                  type="number"
                  v-if="
                    $store.state.dao_amount_enabled &&
                    product.dao_ratio < 100 &&
                    item.method &&
                    item.method.payment_type === 0
                  "
                  vertical
                ></InputItem>
              </div>
              <div class="amount">
                <InputItem
                  v-if="
                    product.show_tuikaxuka_func === -1 &&
                    item.method &&
                    item.method.payment_type === 1
                  "
                  labelname="退赠送额"
                  v-model="item.amount_free"
                  class="narrow short small-size fake amount"
                  type="number"
                  :disabled="j === product.methods.length - 1"
                  vertical
                ></InputItem>
              </div>
              <div class="default-cat">
                <SelectItem
                  :labelname="`${
                    ['扣除', '分配'][(product.show_tuikaxuka_func + 1) / 2]
                  }业绩`"
                  :selectlist="xiaofei_cats"
                  v-model="item.default_cat"
                  class="short no-padding small-size"
                  clearable
                  vertical
                ></SelectItem>
              </div>
              <div class="performance-wrapper">
                <div class="performance-title">
                  <div>整店绩效</div>
                  <div
                    class="gray-button"
                    @click="() => addperformance('tuikaxuka', 'all_performance', i, j)"
                  >
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.all_performance.length > 1"
                    @click="
                      () =>
                        deleteperformance(
                          'tuikaxuka',
                          'all_performance',
                          i,
                          j,
                          item.all_performance.length - 1
                        )
                    "
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.all_performance"
                    :key="'xiaofei_' + i + '_' + j + '_all_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`业绩类别${
                          item.all_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="xiaofei_cats"
                        v-model="per_item.yeji.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`${
                          ['扣除', '分配'][(product.show_tuikaxuka_func + 1) / 2]
                        }业绩金额${item.all_performance.length > 1 ? k + 1 : ''}`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.yeji.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="performance-wrapper" v-if="item.guwen_performance.length">
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效
                  </div>
                  <div
                    class="gray-button"
                    @click="() => addperformance('tuikaxuka', 'guwen_performance', i, j)"
                  >
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.guwen_performance.length > 1"
                    @click="
                      () =>
                        deleteperformance(
                          'tuikaxuka',
                          'guwen_performance',
                          i,
                          j,
                          item.guwen_performance.length - 1
                        )
                    "
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.guwen_performance"
                    :key="'xiaofei_' + i + '_' + j + '_guwen_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                        }姓名${item.guwen_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="guwen_users"
                        v-model="per_item.user"
                        class="no-padding user small-size short narrow"
                        clearable
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`业绩类别${
                          item.guwen_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="xiaofei_cats"
                        v-model="per_item.yeji.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`${
                          ['扣除', '分配'][(product.show_tuikaxuka_func + 1) / 2]
                        }业绩金额${item.guwen_performance.length > 1 ? k + 1 : ''}`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.yeji.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
              <div class="performance-wrapper" v-if="item.mei_performance.length">
                <div class="performance-title">
                  <div>
                    {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                  </div>
                  <div
                    class="gray-button"
                    @click="() => addperformance('tuikaxuka', 'mei_performance', i, j)"
                  >
                    增加
                  </div>
                  <div
                    class="gray-button"
                    v-if="item.mei_performance.length > 1"
                    @click="
                      () =>
                        deleteperformance(
                          'tuikaxuka',
                          'mei_performance',
                          i,
                          j,
                          item.mei_performance.length - 1
                        )
                    "
                  >
                    删除一个
                  </div>
                </div>
                <div class="performance-items">
                  <div
                    v-for="(per_item, k) in item.mei_performance"
                    :key="'xiaofei_' + i + '_' + j + '_mei_' + k"
                    class="performance-item"
                  >
                    <div class="performance-row">
                      <SelectItem
                        :labelname="`${
                          $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                        }姓名${item.mei_performance.length > 1 ? k + 1 : ''}`"
                        :selectlist="mei_users.filter((v) => v.role_type === 1)"
                        v-model="per_item.user"
                        class="no-padding user small-size short narrow"
                        clearable
                        vertical
                      ></SelectItem>
                      <SelectItem
                        :labelname="`业绩类别${
                          item.mei_performance.length > 1 ? k + 1 : ''
                        }`"
                        vertical
                        :selectlist="xiaofei_cats"
                        v-model="per_item.yeji.cat"
                        clearable
                        class="no-padding category small-size item"
                      ></SelectItem>
                      <InputItem
                        :labelname="`${
                          ['扣除', '分配'][(product.show_tuikaxuka_func + 1) / 2]
                        }业绩金额${item.mei_performance.length > 1 ? k + 1 : ''}`"
                        vertical
                        class="no-padding amount small-size item"
                        v-model="per_item.yeji.amount"
                        type="number"
                      ></InputItem>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :key="'xiaofei_' + i + '_jine-cp_' + j" class="jine-customer-products">
              <SelectItem
                labelname="金额卡"
                :selectlist="
                  customer_products.filter(
                    (v) => v.main_type === 3 && v.left_price + v.left_price_free > 0
                  )
                "
                v-model="item.jine_product"
                v-if="item.method && item.method.payment_type === 1"
                class="no-padding short jine-product narrow small-size"
              ></SelectItem>
              <div v-if="item.jine_product" class="small-text jine-product-desc">
                <span v-if="!item.jine_product.is_free">
                  {{ item.jine_product.material_name }} 余{{
                    item.jine_product.left_price + item.jine_product.left_price_free
                  }}元<span v-if="item.jine_product.left_price_free" class="small-text"
                    >({{ item.jine_product.left_price }}+<span class="green"
                      >{{ item.jine_product.left_price_free
                      }}<span class="small-text">赠</span></span
                    >)</span
                  >
                </span>
                <span v-else class="green"
                  >{{ item.jine_product.material_name }}(赠) 余{{
                    item.jine_product.left_price + item.jine_product.left_price_free
                  }}<span></span>元<span class="small-text"
                    >({{ item.jine_product.left_price }}+<span class="green"
                      >{{ item.jine_product.left_price_free
                      }}<span class="small-text">赠</span></span
                    >)</span
                  ></span
                ><span
                  class="red bold"
                  v-if="
                    item.jine_product.left_price + item.jine_product.left_price_free <
                    item.amount
                  "
                  >(不足以抵扣)</span
                ><span v-else>
                  扣<input v-model.number="item.real_amount" />+<span class="green"
                    >{{
                      Math.round((item.amount - item.real_amount) * 100) / 100
                    }}(赠)</span
                  ></span
                >
              </div>
            </div>
          </template>
          <div
            :style="{ display: 'flex', margin: '5px 0 0 30px' }"
            v-if="product.methods.length > 0"
          >
            <div class="blue-button" @click="() => addmethod('tuikaxuka', i)">
              增加支付方式
            </div>
          </div>
        </div>
        <div class="break-line"></div>
        <div class="break-line"></div>
        <div class="break-line"></div>
        <div :style="{ display: 'flex', 'font-size': '1.2em' }">
          <div class="blue-button" @click="addtuikaxukaitem">
            添加{{
              ["退卡", "续卡"][(tui_customer_products[0].show_tuikaxuka_func + 1) / 2]
            }}记录
          </div>
        </div>
      </div>
      <!-- ### arrearage ### -->
      <div v-if="add_type === 'arrearage'" class="arrearage">
        <div class="break-line"></div>
        <div class="add-xiaofei-item-area">
          <div class="material">
            <SelectItem
              :labelname="`还款品项`"
              :selectlist="
                arrearage.products.filter(
                  (v) =>
                    !items_to_submit
                      .filter((a) => a.add_type === 'arrearage')
                      .map((v) => v.material_id)
                      .includes(v.value)
                )
              "
              v-model="arrearage_material_id"
              class="no-padding narrow short"
              searchable
              radio
              id_prefix="arrearage_material_id"
            ></SelectItem>
          </div>
        </div>
        <template v-for="(product, i) in arrearage.products">
          <template v-if="arrearage_material_id === product.value">
            <div class="add-xiaofei-item-area" :key="i">
              <div class="material">
                <InputItem
                  :labelname="
                    ['产品', '项目', '套卡', '金额卡'][product.main_type] + '名称'
                  "
                  vertical
                  class="small-size no-padding"
                  :value="product.material_name"
                  disabled
                ></InputItem>
              </div>
              <div class="amount" v-if="false">
                <InputItem
                  labelname="还款金额"
                  vertical
                  class="small-size no-padding"
                  v-model="product.amount"
                  type="number"
                ></InputItem>
              </div>
              <div class="users">
                <SelectItem
                  :labelname="`${
                    $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                  }姓名`"
                  :selectlist="guwen_users"
                  v-model="product.guwen_users"
                  class="no-padding narrow small-size users"
                  multiple
                  vertical
                  clearable
                  searchable
                  :disabled="tui_methods.length > 0"
                ></SelectItem>
              </div>
              <div class="users">
                <SelectItem
                  :labelname="`${
                    $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                  }姓名`"
                  :selectlist="mei_users.filter((v) => v.role_type === 1)"
                  v-model="product.mei_users"
                  class="no-padding narrow small-size users"
                  multiple
                  vertical
                  clearable
                  searchable
                  :disabled="tui_methods.length > 0"
                ></SelectItem>
              </div>
              <div v-if="false">
                <SelectItem
                  labelname="支付方式"
                  :selectlist="
                    payments.filter(
                      (v) =>
                        (v.payment_type !== 3 ||
                          product.show_tuikaxuka_func === 1 ||
                          (product.show_tuikaxuka_func === -1 &&
                            arrearage &&
                            product.material_id in arrearage.materialiddict &&
                            arrearage.materialiddict[product.material_id]['left_price'] <
                              0)) &&
                        !(product.is_free && v.payment_type !== 4)
                    )
                  "
                  v-model="tui_methods"
                  multiple
                  class="no-padding narrow small-size methods red"
                  vertical
                  clearable
                  searchable
                ></SelectItem>
              </div>
              <div class="remark">
                <InputItem
                  labelname="备注"
                  vertical
                  class="small-size short no-padding"
                  v-model="product.remark"
                ></InputItem>
              </div>
            </div>
            <template v-for="(item, j) in product.methods">
              <div :key="i + '_method_' + j" class="add-item-area more-margin">
                <div class="method">
                  <SelectItem
                    :labelname="`还款方式${product.methods.length > 1 ? j + 1 : ''}`"
                    vertical
                    :selectlist="payments.filter((v) => v.payment_type < 2)"
                    v-model="item.method"
                    class="no-padding small-size method red"
                  ></SelectItem>
                </div>
                <div class="amount">
                  <InputItem
                    labelname="还款金额"
                    v-model="item.amount"
                    class="narrow short small-size amount no-padding"
                    type="number"
                    vertical
                  ></InputItem>
                </div>
                <div
                  class="amount"
                  v-if="
                    $store.state.dao_amount_enabled &&
                    product.dao_ratio < 100 &&
                    item.method &&
                    item.method.payment_type === 0
                  "
                >
                  <InputItem
                    labelname="店内分成"
                    v-model="item.dao_amount"
                    class="narrow short small-size fake amount"
                    type="number"
                    v-if="
                      $store.state.dao_amount_enabled &&
                      product.dao_ratio < 100 &&
                      item.method &&
                      item.method.payment_type === 0
                    "
                    vertical
                  ></InputItem>
                </div>
                <div class="default-cat">
                  <SelectItem
                    :labelname="`分配业绩`"
                    :selectlist="xiaofei_cats"
                    v-model="item.default_cat"
                    class="short no-padding small-size"
                    clearable
                    vertical
                  ></SelectItem>
                </div>
                <div class="performance-wrapper">
                  <div class="performance-title">
                    <div>整店绩效</div>
                    <div
                      class="gray-button"
                      @click="() => addperformance('qiankuan', 'all_performance', i, j)"
                    >
                      增加
                    </div>
                    <div
                      class="gray-button"
                      v-if="item.all_performance.length > 1"
                      @click="
                        () =>
                          deleteperformance(
                            'qiankuan',
                            'all_performance',
                            i,
                            j,
                            item.all_performance.length - 1
                          )
                      "
                    >
                      删除一个
                    </div>
                  </div>
                  <div class="performance-items">
                    <div
                      v-for="(per_item, k) in item.all_performance"
                      :key="'xiaofei_' + i + '_' + j + '_all_' + k"
                      class="performance-item"
                    >
                      <div class="performance-row">
                        <SelectItem
                          :labelname="`业绩类别${
                            item.all_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          :selectlist="xiaofei_cats"
                          v-model="per_item.yeji.cat"
                          clearable
                          class="no-padding category small-size item"
                        ></SelectItem>
                        <InputItem
                          :labelname="`业绩金额${
                            item.all_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          class="no-padding amount small-size item"
                          v-model="per_item.yeji.amount"
                          type="number"
                        ></InputItem>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="performance-wrapper" v-if="item.guwen_performance.length">
                  <div class="performance-title">
                    <div>
                      {{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效
                    </div>
                    <div
                      class="gray-button"
                      @click="() => addperformance('qiankuan', 'guwen_performance', i, j)"
                    >
                      增加
                    </div>
                    <div
                      class="gray-button"
                      v-if="item.guwen_performance.length > 1"
                      @click="
                        () =>
                          deleteperformance(
                            'qiankuan',
                            'guwen_performance',
                            i,
                            j,
                            item.guwen_performance.length - 1
                          )
                      "
                    >
                      删除一个
                    </div>
                  </div>
                  <div class="performance-items">
                    <div
                      v-for="(per_item, k) in item.guwen_performance"
                      :key="'xiaofei_' + i + '_' + j + '_guwen_' + k"
                      class="performance-item"
                    >
                      <div class="performance-row">
                        <SelectItem
                          :labelname="`${
                            $store.state.terms[2] ? $store.state.terms[2] : '顾问'
                          }姓名${item.guwen_performance.length > 1 ? k + 1 : ''}`"
                          :selectlist="guwen_users"
                          v-model="per_item.user"
                          class="no-padding user small-size short narrow"
                          clearable
                          vertical
                        ></SelectItem>
                        <SelectItem
                          :labelname="`业绩类别${
                            item.guwen_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          :selectlist="xiaofei_cats"
                          v-model="per_item.yeji.cat"
                          clearable
                          class="no-padding category small-size item"
                        ></SelectItem>
                        <InputItem
                          :labelname="`业绩金额${
                            item.guwen_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          class="no-padding amount small-size item"
                          v-model="per_item.yeji.amount"
                          type="number"
                        ></InputItem>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="performance-wrapper" v-if="item.mei_performance.length">
                  <div class="performance-title">
                    <div>
                      {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                    </div>
                    <div
                      class="gray-button"
                      @click="() => addperformance('qiankuan', 'mei_performance', i, j)"
                    >
                      增加
                    </div>
                    <div
                      class="gray-button"
                      v-if="item.mei_performance.length > 1"
                      @click="
                        () =>
                          deleteperformance(
                            'qiankuan',
                            'mei_performance',
                            i,
                            j,
                            item.mei_performance.length - 1
                          )
                      "
                    >
                      删除一个
                    </div>
                  </div>
                  <div class="performance-items">
                    <div
                      v-for="(per_item, k) in item.mei_performance"
                      :key="'xiaofei_' + i + '_' + j + '_mei_' + k"
                      class="performance-item"
                    >
                      <div class="performance-row">
                        <SelectItem
                          :labelname="`${
                            $store.state.terms[1] ? $store.state.terms[1] : '美容师'
                          }姓名${item.mei_performance.length > 1 ? k + 1 : ''}`"
                          :selectlist="mei_users.filter((v) => v.role_type === 1)"
                          v-model="per_item.user"
                          class="no-padding user small-size short narrow"
                          clearable
                          vertical
                        ></SelectItem>
                        <SelectItem
                          :labelname="`业绩类别${
                            item.mei_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          :selectlist="xiaofei_cats"
                          v-model="per_item.yeji.cat"
                          clearable
                          class="no-padding category small-size item"
                        ></SelectItem>
                        <InputItem
                          :labelname="`业绩金额${
                            item.mei_performance.length > 1 ? k + 1 : ''
                          }`"
                          vertical
                          class="no-padding amount small-size item"
                          v-model="per_item.yeji.amount"
                          type="number"
                        ></InputItem>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div :key="'jine-cp_' + j" class="jine-customer-products">
                <SelectItem
                  labelname="金额卡"
                  :selectlist="
                    customer_products.filter(
                      (v) => v.main_type === 3 && v.left_price + v.left_price_free > 0
                    )
                  "
                  v-model="item.jine_product"
                  v-if="item.method && item.method.payment_type === 1"
                  class="no-padding short jine-product narrow small-size"
                ></SelectItem>
                <div v-if="item.jine_product" class="small-text jine-product-desc">
                  <span v-if="!item.jine_product.is_free">
                    {{ item.jine_product.material_name }} 余{{
                      item.jine_product.left_price + item.jine_product.left_price_free
                    }}元<span v-if="item.jine_product.left_price_free" class="small-text"
                      >({{ item.jine_product.left_price }}+<span class="green"
                        >{{ item.jine_product.left_price_free
                        }}<span class="small-text">赠</span></span
                      >)</span
                    >
                  </span>
                  <span v-else class="green"
                    >{{ item.jine_product.material_name }}(赠) 余{{
                      item.jine_product.left_price + item.jine_product.left_price_free
                    }}<span></span>元<span class="small-text"
                      >({{ item.jine_product.left_price }}+<span class="green"
                        >{{ item.jine_product.left_price_free
                        }}<span class="small-text">赠</span></span
                      >)</span
                    ></span
                  ><span
                    class="red bold"
                    v-if="
                      item.jine_product.left_price + item.jine_product.left_price_free <
                      item.amount
                    "
                    >(不足以抵扣)</span
                  ><span v-else>
                    扣<input v-model.number="item.real_amount" />+<span class="green"
                      >{{
                        Math.round((item.amount - item.real_amount) * 100) / 100
                      }}(赠)</span
                    ></span
                  >
                </div>
              </div>
            </template>
            <div
              :style="{ display: 'flex', margin: '5px 0 0 30px' }"
              v-if="product.methods.length > 0"
              :key="'add-method-' + i"
            >
              <div class="blue-button" @click="() => addmethod('qiankuan', i)">
                增加支付方式
              </div>
            </div>
          </template>
        </template>
        <div class="break-line"></div>
        <div class="break-line"></div>
        <div class="break-line"></div>
        <div :style="{ display: 'flex', 'font-size': '1.2em' }">
          <div class="blue-button" @click="addrepayitem">添加还款记录</div>
        </div>
      </div>
      <div class="break-line"></div>
      <div class="break-line"></div>
      <div>待提交记录</div>
      <div v-if="items_to_submit.length === 0">(暂无记录)</div>
      <div v-else>
        <div class="table-wrapper items-to-submit">
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>类型</th>
                <th>客户</th>
                <th>品项名称</th>
                <th>数量变化</th>
                <th>金额变化</th>
                <th>方式</th>
                <th>整店绩效</th>
                <th>{{ $store.state.terms[2] ? $store.state.terms[2] : "顾问" }}绩效</th>
                <th>
                  {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}绩效
                </th>
                <th>备注</th>
                <th>仓库信息</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(product, i) in items_to_submit">
                <!-- ==== haoka_products ==== -->
                <template v-if="product.add_type === 'haoka_products'">
                  <tr :key="i" class="haoka_products">
                    <td>
                      <div class="index-wrapper">
                        <div class="index">{{ i + 1 }}.</div>
                        <div class="gray-button" @click="() => deletesubmititem(i)">
                          删除
                        </div>
                      </div>
                    </td>
                    <td>消耗</td>
                    <td>{{ product["haoka_list"][0]["customer"]["label"] }}</td>
                    <td>{{ product.material_name }}</td>
                    <td>
                      <div>
                        <span v-if="-product['haoka_list'][0]['cnt'] > 0">+</span
                        >{{ -product["haoka_list"][0]["cnt"]
                        }}{{ product["haoka_list"][0]["unitname"] }}
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="
                          !(
                            product.main_type === 2 &&
                            product.sub_type === 2 &&
                            product.id in sub_product_price &&
                            product['haoka_list'][0].product &&
                            product['haoka_list'][0].product.material_id in
                              sub_product_price[product.id]
                          )
                        "
                      >
                        <span
                          v-if="
                            (product.left_price / product.left_quantity) *
                              -product['haoka_list'][0].cnt >
                            0
                          "
                          >+</span
                        >{{
                          Math.round(
                            (product.left_price / product.left_quantity) *
                              -product["haoka_list"][0].cnt *
                              100
                          ) / 100
                        }}元
                      </div>
                      <div v-else>
                        <span
                          v-if="
                            sub_product_price[product.id][
                              product['haoka_list'][0].product.material_id
                            ][0] *
                              -product['haoka_list'][0].cnt >
                            0
                          "
                          >+</span
                        >{{
                          Math.round(
                            sub_product_price[product.id][
                              product["haoka_list"][0].product.material_id
                            ][0] *
                              -product["haoka_list"][0].cnt *
                              100
                          ) / 100
                        }}元
                      </div>
                    </td>
                    <td>消耗</td>
                    <td>
                      <div
                        v-for="(per_item, j) in product['haoka_list'][0][
                          'all_performance'
                        ]"
                        :key="i + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">整店:</span>
                          <span
                            v-if="per_item['haoka']['cat'] && per_item['haoka']['amount']"
                            class="per-item"
                            >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                              v-if="per_item['haoka']['amount'] > 0"
                              >+</span
                            >{{ per_item["haoka"]["amount"] }}元;</span
                          >
                          <span
                            v-if="
                              per_item['xiangshu'] &&
                              per_item['xiangshu']['cat'] &&
                              per_item['xiangshu']['amount']
                            "
                            class="per-item"
                            >{{ per_item["xiangshu"]["cat"]["label"]
                            }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                            >{{ per_item["xiangshu"]["amount"] }};</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in product['haoka_list'][0][
                          'guwen_performance'
                        ].filter((v) => v.user)"
                        :key="i + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['haoka']['cat'] && per_item['haoka']['amount']"
                            class="per-item"
                            >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                              v-if="per_item['haoka']['amount'] > 0"
                              >+</span
                            >{{ per_item["haoka"]["amount"] }}元;</span
                          >
                          <span
                            v-if="
                              per_item['xiangshu'] &&
                              per_item['xiangshu']['cat'] &&
                              per_item['xiangshu']['amount']
                            "
                            class="per-item"
                            >{{ per_item["xiangshu"]["cat"]["label"]
                            }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                            >{{ per_item["xiangshu"]["amount"] }};</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in product['haoka_list'][0][
                          'mei_performance'
                        ].filter((v) => v.user)"
                        :key="i + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['haoka']['cat'] && per_item['haoka']['amount']"
                            class="per-item"
                            >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                              v-if="per_item['haoka']['amount'] > 0"
                              >+</span
                            >{{ per_item["haoka"]["amount"] }}元;</span
                          >
                          <span
                            v-if="
                              per_item['xiangshu'] &&
                              per_item['xiangshu']['cat'] &&
                              per_item['xiangshu']['amount']
                            "
                            class="per-item"
                            >{{ per_item["xiangshu"]["cat"]["label"]
                            }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                            >{{ per_item["xiangshu"]["amount"] }};</span
                          >
                          <span
                            v-for="(shou_item, k) in per_item['shougong']"
                            class="per-item"
                            :key="i + '_' + j + '_' + k"
                            v-show="shou_item['amount']"
                            >{{ shou_item["cat"]["label"]
                            }}<span v-if="shou_item['amount'] > 0">+</span
                            >{{ shou_item["amount"] }};</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>{{ product["haoka_list"][0]["remark"] }}</td>
                    <td>
                      <div
                        v-if="product['haoka_list'][0].relate_material_outin === 2"
                        class="small-text"
                      >
                        {{ product["haoka_list"][0].product.material_name }} 出库
                        {{ product["haoka_list"][0].cnt
                        }}{{ product["haoka_list"][0].product.unitname }}
                      </div>
                    </td>
                  </tr>
                </template>
                <!-- ==== jine_haoka_list ==== -->
                <template v-if="product.add_type === 'jine_haoka_list'">
                  <template v-for="(item, m) in [product]">
                    <tr :key="i + '_' + m" class="jine_haoka_list">
                      <td>
                        <div class="index-wrapper">
                          <div class="index">{{ i + 1 }}.</div>
                          <div class="gray-button" @click="() => deletesubmititem(i)">
                            删除
                          </div>
                        </div>
                      </td>
                      <td>余额</td>
                      <td>{{ item["customer"]["label"] }}</td>
                      <td>{{ item.product.name }}</td>
                      <td>
                        <div>
                          <span v-if="item['cnt'] > 0">+</span>{{ item["cnt"]
                          }}{{ item.product.unitname }}
                        </div>
                      </td>
                      <td>
                        <div v-if="item.amount">
                          <span v-if="item.amount > 0">+</span>{{ item.amount }}元
                        </div>
                      </td>
                      <td class="method">
                        <div>
                          余额<span class="small-text"
                            >({{ item.jine_methods[0]["label"]
                            }}<span v-if="item.jine_methods[0].real_amount < 0">+</span
                            ><span v-if="item.jine_methods[0].real_amount"
                              >{{ -item.jine_methods[0].real_amount }}元<span
                                v-if="item.amount - item.jine_methods[0].real_amount < 0"
                                >+</span
                              ><span
                                v-if="item.amount - item.jine_methods[0].real_amount"
                                class="green"
                                >{{
                                  -(item.amount - item.jine_methods[0].real_amount)
                                }}元赠</span
                              ></span
                            >)</span
                          >
                        </div>
                      </td>
                      <td>
                        <div
                          v-for="(per_item, j) in item['all_yeji_performance'].filter(
                            (v) => v.yeji && v.yeji.cat
                          )"
                          :key="i + '_yeji_' + j"
                          class="performance"
                        >
                          <div class="per-items">
                            <span class="per-item">整店:</span>
                            <span
                              v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                              class="per-item"
                              >{{ per_item["yeji"]["cat"]["label"] }}业绩<span
                                v-if="per_item['yeji']['amount'] > 0"
                                >+</span
                              >{{ per_item["yeji"]["amount"] }}元;</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          v-for="(per_item, j) in item['guwen_yeji_performance'].filter(
                            (v) => v.user
                          )"
                          :key="i + '_guwen_yeji_' + j"
                          class="performance"
                        >
                          <div class="per-items">
                            <span class="per-item">{{ per_item.user.label }}:</span>
                            <span
                              v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                              class="per-item"
                              >{{ per_item["yeji"]["cat"]["label"] }}业绩<span
                                v-if="per_item['yeji']['amount'] > 0"
                                >+</span
                              >{{ per_item["yeji"]["amount"] }}元;</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          v-for="(per_item, j) in item['mei_yeji_performance'].filter(
                            (v) => v.user
                          )"
                          :key="i + '_mei_yeji_' + j"
                          class="performance"
                        >
                          <div class="per-items">
                            <span class="per-item">{{ per_item.user.label }}:</span>
                            <span
                              v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                              class="per-item"
                              >{{ per_item["yeji"]["cat"]["label"] }}业绩<span
                                v-if="per_item['yeji']['amount'] > 0"
                                >+</span
                              >{{ per_item["yeji"]["amount"] }}元;</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>{{ item["remark"] }}</td>
                      <td>
                        <div
                          v-if="item.relate_material_outin === 2 && false"
                          class="small-text"
                        >
                          {{ item.product.material_name }} 出库 {{ item.cnt
                          }}{{ item.product.unitname }}
                        </div>
                      </td>
                    </tr>
                    <tr :key="i + '_' + m + '2'" class="jine_haoka_list">
                      <td></td>
                      <td>消耗</td>
                      <td>{{ item["customer"]["label"] }}</td>
                      <td>{{ item.product.name }}</td>
                      <td>
                        <div>
                          <span v-if="item['cnt'] < 0">+</span>{{ -item["cnt"]
                          }}{{ item.product.unitname }}
                        </div>
                      </td>
                      <td>
                        <div v-if="item.amount">
                          <span v-if="item.amount < 0">+</span>{{ -item.amount }}元
                        </div>
                      </td>
                      <td>
                        <div>消耗</div>
                      </td>
                      <td>
                        <div
                          v-for="(per_item, j) in item['all_performance']"
                          :key="i + '_' + j"
                          class="performance"
                        >
                          <div class="per-items">
                            <span class="per-item">整店:</span>
                            <span
                              v-if="
                                per_item['haoka']['cat'] && per_item['haoka']['amount']
                              "
                              class="per-item"
                              >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                                v-if="per_item['haoka']['amount'] > 0"
                                >+</span
                              >{{ per_item["haoka"]["amount"] }}元;</span
                            >
                            <span
                              v-if="
                                per_item['xiangshu'] &&
                                per_item['xiangshu']['cat'] &&
                                per_item['xiangshu']['amount']
                              "
                              class="per-item"
                              >{{ per_item["xiangshu"]["cat"]["label"]
                              }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                              >{{ per_item["xiangshu"]["amount"] }};</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          v-for="(per_item, j) in item['guwen_performance'].filter(
                            (v) => v.user
                          )"
                          :key="i + '_' + j"
                          class="performance"
                        >
                          <div class="per-items">
                            <span class="per-item">{{ per_item.user.label }}:</span>
                            <span
                              v-if="
                                per_item['haoka']['cat'] && per_item['haoka']['amount']
                              "
                              class="per-item"
                              >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                                v-if="per_item['haoka']['amount'] > 0"
                                >+</span
                              >{{ per_item["haoka"]["amount"] }}元;</span
                            >
                            <span
                              v-if="
                                per_item['xiangshu'] &&
                                per_item['xiangshu']['cat'] &&
                                per_item['xiangshu']['amount']
                              "
                              class="per-item"
                              >{{ per_item["xiangshu"]["cat"]["label"]
                              }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                              >{{ per_item["xiangshu"]["amount"] }};</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          v-for="(per_item, j) in item['mei_performance'].filter(
                            (v) => v.user
                          )"
                          :key="i + '_' + j"
                          class="performance"
                        >
                          <div class="per-items">
                            <span class="per-item">{{ per_item.user.label }}:</span>
                            <span
                              v-if="
                                per_item['haoka']['cat'] && per_item['haoka']['amount']
                              "
                              class="per-item"
                              >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                                v-if="per_item['haoka']['amount'] > 0"
                                >+</span
                              >{{ per_item["haoka"]["amount"] }}元;</span
                            >
                            <span
                              v-if="
                                per_item['xiangshu'] &&
                                per_item['xiangshu']['cat'] &&
                                per_item['xiangshu']['amount']
                              "
                              class="per-item"
                              >{{ per_item["xiangshu"]["cat"]["label"]
                              }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                              >{{ per_item["xiangshu"]["amount"] }};</span
                            >
                            <span
                              v-for="(shou_item, k) in per_item['shougong']"
                              class="per-item"
                              :key="i + '_' + j + '_' + k"
                              v-show="shou_item['amount']"
                              >{{ shou_item["cat"]["label"]
                              }}<span v-if="shou_item['amount'] > 0">+</span
                              >{{ shou_item["amount"] }};</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>{{ item["remark"] }}</td>
                      <td>
                        <div v-if="item.relate_material_outin === 2" class="small-text">
                          {{ storedict[item.store_id].name }} {{ item.product.name }} 出库
                          {{ item.cnt }}{{ item.product.unitname }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <!-- ==== xiaofei_products ==== -->
                <template v-if="product.add_type === 'xiaofei_products'">
                  <tr
                    v-for="(item, m) in product.methods"
                    :key="'xiaofei_' + i + '_' + m"
                    class="xiaofei-products"
                  >
                    <template v-if="m === 0">
                      <td :rowspan="product.methods.length">
                        <div class="index-wrapper">
                          <div class="index">{{ i + 1 }}.</div>
                          <div class="gray-button" @click="() => deletesubmititem(i)">
                            删除
                          </div>
                        </div>
                      </td>
                      <td :rowspan="product.methods.length">消费</td>
                      <td :rowspan="product.methods.length">{{ customer.name }}</td>
                      <td :rowspan="product.methods.length">
                        {{ product.material_name }}
                      </td>
                      <td :rowspan="product.methods.length">
                        <span v-if="product.cnt > 0">+</span>{{ product.cnt
                        }}{{ product.unitname }}
                      </td>
                      <td :rowspan="product.methods.length">
                        <span v-if="product.amount > 0">+</span>{{ product.amount }}元
                      </td>
                    </template>
                    <td>
                      {{ item.method.label
                      }}<span class="small-text" v-if="item.method.payment_type === 1"
                        >({{ item.jine_product.label
                        }}<span v-if="item.real_amount < 0">+</span
                        ><span v-if="item.real_amount"
                          >{{ -item.real_amount }}元<span
                            v-if="item.amount - item.real_amount < 0"
                            >+</span
                          ><span v-if="item.amount - item.real_amount" class="green"
                            >{{ -(item.amount - item.real_amount) }}元赠</span
                          ></span
                        >)</span
                      >
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['all_performance']"
                        :key="'yeji_all_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">整店:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span v-if="per_item['yeji']['amount'] > 0">+</span
                            >{{ per_item["yeji"]["amount"] }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['guwen_performance']"
                        :key="'yeji_guwen_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span v-if="per_item['yeji']['amount'] > 0">+</span
                            >{{ per_item["yeji"]["amount"] }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['mei_performance']"
                        :key="'yeji_mei_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span v-if="per_item['yeji']['amount'] > 0">+</span
                            >{{ per_item["yeji"]["amount"] }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <template v-if="m === 0">
                      <td :rowspan="product.methods.length">{{ product.remark }}</td>
                      <td :rowspan="product.methods.length"></td>
                    </template>
                  </tr>
                  <tr
                    v-if="product.consumed"
                    :key="'xiaofei_xiaohao' + i"
                    class="xiaofei-products-xiaohao"
                  >
                    <td></td>
                    <td>消耗</td>
                    <td>{{ customer.name }}</td>
                    <td>{{ product.material_name }}</td>
                    <td>
                      <span v-if="product.cnt < 0">+</span>{{ -product.cnt
                      }}{{ product.unitname }}
                    </td>
                    <td>
                      <span v-if="product.amount < 0">+</span>{{ -product.amount }}元
                    </td>
                    <td>消耗</td>
                    <td>
                      <div
                        v-for="(per_item, j) in product['all_performance']"
                        :key="i + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">整店:</span>
                          <span
                            v-if="
                              per_item['haoka'] &&
                              per_item['haoka']['cat'] &&
                              per_item['haoka']['amount']
                            "
                            class="per-item"
                            >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                              v-if="per_item['haoka']['amount'] > 0"
                              >+</span
                            >{{ per_item["haoka"]["amount"] }}元;</span
                          >
                          <span
                            v-if="
                              per_item['xiangshu'] &&
                              per_item['xiangshu']['cat'] &&
                              per_item['xiangshu']['amount']
                            "
                            class="per-item"
                            >{{ per_item["xiangshu"]["cat"]["label"]
                            }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                            >{{ per_item["xiangshu"]["amount"] }};</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in product['guwen_performance'].filter(
                          (v) => v.user
                        )"
                        :key="i + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="
                              per_item['haoka'] &&
                              per_item['haoka']['cat'] &&
                              per_item['haoka']['amount']
                            "
                            class="per-item"
                            >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                              v-if="per_item['haoka']['amount'] > 0"
                              >+</span
                            >{{ per_item["haoka"]["amount"] }}元;</span
                          >
                          <span
                            v-if="
                              per_item['xiangshu'] &&
                              per_item['xiangshu']['cat'] &&
                              per_item['xiangshu']['amount']
                            "
                            class="per-item"
                            >{{ per_item["xiangshu"]["cat"]["label"]
                            }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                            >{{ per_item["xiangshu"]["amount"] }};</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in product['mei_performance'].filter(
                          (v) => v.user
                        )"
                        :key="i + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="
                              per_item['haoka'] &&
                              per_item['haoka']['cat'] &&
                              per_item['haoka']['amount']
                            "
                            class="per-item"
                            >{{ per_item["haoka"]["cat"]["label"] }}消耗<span
                              v-if="per_item['haoka']['amount'] > 0"
                              >+</span
                            >{{ per_item["haoka"]["amount"] }}元;</span
                          >
                          <span
                            v-if="
                              per_item['xiangshu'] &&
                              per_item['xiangshu']['cat'] &&
                              per_item['xiangshu']['amount']
                            "
                            class="per-item"
                            >{{ per_item["xiangshu"]["cat"]["label"]
                            }}<span v-if="per_item['xiangshu']['amount'] > 0">+</span
                            >{{ per_item["xiangshu"]["amount"] }};</span
                          >
                          <template v-if="per_item['shougong']">
                            <span
                              v-for="(shou_item, k) in per_item['shougong']"
                              class="per-item"
                              :key="i + '_' + j + '_' + k"
                              v-show="shou_item['amount']"
                              >{{ shou_item["cat"]["label"]
                              }}<span v-if="shou_item['amount'] > 0">+</span
                              >{{ shou_item["amount"] }};</span
                            >
                          </template>
                        </div>
                      </div>
                    </td>
                    <td>{{ product["remark"] }}</td>
                    <td>
                      <div v-if="product.relate_material_outin === 2" class="small-text">
                        {{ product.material_name }} 出库 {{ product.cnt
                        }}{{ product.unitname }}
                      </div>
                    </td>
                  </tr>
                </template>
                <!-- ==== tuikaxuka ==== -->
                <template v-if="product.add_type === 'tui_customer_products'"
                  ><tr
                    v-for="(item, m) in product.methods"
                    :key="'tuikaxuka_' + i + '_' + m"
                    class="tuikaxuka"
                  >
                    <template v-if="m === 0">
                      <td :rowspan="product.methods.length">
                        <div class="index-wrapper">
                          <div class="index">{{ i + 1 }}.</div>
                          <div class="gray-button" @click="() => deletesubmititem(i)">
                            删除
                          </div>
                        </div>
                      </td>
                      <td :rowspan="product.methods.length">
                        {{ ["退卡", "续卡"][(product.show_tuikaxuka_func + 1) / 2] }}
                      </td>
                      <td :rowspan="product.methods.length">{{ customer.name }}</td>
                      <td :rowspan="product.methods.length">
                        {{ product.material_name }}
                      </td>
                      <td :rowspan="product.methods.length">
                        <span v-if="product.show_tuikaxuka_func * product.return_cnt > 0"
                          >+</span
                        >{{ product.show_tuikaxuka_func * product.return_cnt
                        }}{{ product.unitname }}
                      </td>
                      <td :rowspan="product.methods.length">
                        <span
                          v-if="product.show_tuikaxuka_func * product.return_amount > 0"
                          >+</span
                        >{{ product.show_tuikaxuka_func * product.return_amount }}元
                      </td>
                    </template>
                    <td>
                      {{ item.method.label
                      }}<span class="small-text" v-if="item.method.payment_type === 1"
                        >({{ item.jine_product.label
                        }}<span v-if="item.real_amount < 0">+</span
                        ><span v-if="item.real_amount"
                          >{{ -item.real_amount }}元<span
                            v-if="item.amount - item.real_amount < 0"
                            >+</span
                          ><span v-if="item.amount - item.real_amount" class="green"
                            >{{ -(item.amount - item.real_amount) }}元赠</span
                          ></span
                        >)</span
                      >
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['all_performance']"
                        :key="'yeji_all_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">整店:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span
                              v-if="
                                product.show_tuikaxuka_func * per_item['yeji']['amount'] >
                                0
                              "
                              >+</span
                            >{{
                              product.show_tuikaxuka_func * per_item["yeji"]["amount"]
                            }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['guwen_performance']"
                        :key="'yeji_guwen_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span
                              v-if="
                                product.show_tuikaxuka_func * per_item['yeji']['amount'] >
                                0
                              "
                              >+</span
                            >{{
                              product.show_tuikaxuka_func * per_item["yeji"]["amount"]
                            }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['mei_performance']"
                        :key="'yeji_mei_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span
                              v-if="
                                product.show_tuikaxuka_func * per_item['yeji']['amount'] >
                                0
                              "
                              >+</span
                            >{{
                              product.show_tuikaxuka_func * per_item["yeji"]["amount"]
                            }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <template v-if="m === 0">
                      <td :rowspan="product.methods.length">{{ product.remark }}</td>
                      <td :rowspan="product.methods.length"></td>
                    </template></tr
                ></template>
                <!-- ==== arrearage ==== -->
                <template v-if="product.add_type === 'arrearage'"
                  ><tr
                    v-for="(item, m) in product.methods"
                    :key="'qiankuan_' + i + '_' + m"
                    class="qiankuan"
                  >
                    <template v-if="m === 0">
                      <td :rowspan="product.methods.length">
                        <div class="index-wrapper">
                          <div class="index">{{ i + 1 }}.</div>
                          <div class="gray-button" @click="() => deletesubmititem(i)">
                            删除
                          </div>
                        </div>
                      </td>
                      <td :rowspan="product.methods.length">还款</td>
                      <td :rowspan="product.methods.length">{{ customer.name }}</td>
                      <td :rowspan="product.methods.length">
                        欠款:{{ product.material_name }}
                      </td>
                      <td :rowspan="product.methods.length"></td>
                      <td :rowspan="product.methods.length">
                        <span v-if="product.amount > 0">+</span>{{ product.amount }}元
                      </td>
                    </template>
                    <td>
                      {{ item.method.label
                      }}<span class="small-text" v-if="item.method.payment_type === 1"
                        >({{ item.jine_product.label
                        }}<span v-if="item.real_amount < 0">+</span
                        ><span v-if="item.real_amount"
                          >{{ -item.real_amount }}元<span
                            v-if="item.amount - item.real_amount < 0"
                            >+</span
                          ><span v-if="item.amount - item.real_amount" class="green"
                            >{{ -(item.amount - item.real_amount) }}元赠</span
                          ></span
                        >)</span
                      >
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['all_performance']"
                        :key="'yeji_all_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">整店:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span v-if="per_item['yeji']['amount'] > 0">+</span
                            >{{ per_item["yeji"]["amount"] }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['guwen_performance']"
                        :key="'yeji_guwen_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span v-if="per_item['yeji']['amount'] > 0">+</span
                            >{{ per_item["yeji"]["amount"] }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(per_item, j) in item['mei_performance']"
                        :key="'yeji_mei_' + i + '_' + m + '_' + j"
                        class="performance"
                      >
                        <div class="per-items">
                          <span class="per-item">{{ per_item.user.label }}:</span>
                          <span
                            v-if="per_item['yeji']['cat'] && per_item['yeji']['amount']"
                            class="per-item"
                            >{{ per_item["yeji"]["cat"]["label"]
                            }}{{ per_item["yeji"]["cat"]["cat_type"] === 1 ? "业绩" : ""
                            }}<span v-if="per_item['yeji']['amount'] > 0">+</span
                            >{{ per_item["yeji"]["amount"] }}元;</span
                          >
                        </div>
                      </div>
                    </td>
                    <template v-if="m === 0">
                      <td :rowspan="product.methods.length">{{ product.remark }}</td>
                      <td :rowspan="product.methods.length"></td>
                    </template></tr
                ></template>
              </template>
            </tbody>
          </table>
        </div>
        <div class="break-line"></div>
        <div
          v-for="(item, k) in customer_user_counts"
          :key="k"
          class="customer-user-counts"
        >
          <div>
            {{ item.customer_name
            }}<span class="small-text" v-if="customer.cur_type || customer.cur_type === 0"
              >({{
                ["其它", "员工", "咨询客", "体验客", "会员"][item.customer.cur_type + 1]
              }})</span
            >
            <span
              class="bold"
              :class="{
                red:
                  new Date(effect_date).format('yyyy-MM-dd') !==
                  new Date().format('yyyy-MM-dd'),
              }"
            >
              ({{ last_str }})</span
            >
          </div>
          <div :style="{ 'padding-left': '20px' }" class="items">
            <div :style="{ 'padding-top': '2px' }">
              {{ corp.label }} 整店 计<input
                v-model.number="item.cnt"
                class="counts"
              />人次;
            </div>
            <div
              v-for="u in item[2]"
              :key="u.user.value"
              :style="{ 'padding-top': '2px' }"
            >
              顾问 {{ u.user.label }} 计<input
                v-model.number="u.cnt"
                class="counts"
              />人次，加班<input v-model.number="u.overtime" class="counts" />小时;
            </div>
            <div
              v-for="u in item[1]"
              :key="u.user.value"
              :style="{ 'padding-top': '2px' }"
            >
              {{ $store.state.terms[1] ? $store.state.terms[1] : "美容师" }}
              {{ u.user.label }} 计<input
                v-model.number="u.cnt"
                class="counts"
              />人次，加班<input v-model.number="u.overtime" class="counts" />小时
            </div>
          </div>
        </div>
        <div class="break-line"></div>
        <div v-if="store_material_items.length > 0">
          <div :style="{ display: 'flex', 'align-items': 'center' }">
            <div>
              <input
                type="checkbox"
                id="create-document-switch"
                v-model="create_document_switch"
                :style="{ margin: '3px' }"
              />
              <label for="create-document-switch">创建销售出库单据(编号)</label>
            </div>
            <div
              :style="{ width: '260px', 'padding-left': '10px' }"
              v-if="create_document_switch"
            >
              <InputItem
                v-model="document_no"
                class="narrow short customer-name"
              ></InputItem>
            </div>
          </div>
          <div class="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>序号</th>
                  <th>产品名称</th>
                  <th>仓库剩余</th>
                  <th>本次出入库</th>
                  <th>备注</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, k) in store_material_items" :key="item.material_id">
                  <td>
                    {{ k + 1 }}
                  </td>
                  <td>{{ item.material_name }}</td>
                  <td>
                    <div>
                      <span
                        v-if="
                          corp.corp_id in store_product_lefts &&
                          item.material_id in store_product_lefts[corp.corp_id]
                        "
                        ><span
                          v-show="
                            calNums(
                              Math.abs(
                                store_product_lefts[corp.corp_id][item.material_id]
                              ),
                              item.spec,
                              item.set_spec
                            )[0]
                          "
                          >{{
                            calNums(
                              Math.abs(
                                store_product_lefts[corp.corp_id][item.material_id]
                              ),
                              item.spec,
                              item.set_spec
                            )[0]
                          }}<span class="tiny-text">{{ item.set_unitname }}</span></span
                        ><span
                          v-show="
                            calNums(
                              Math.abs(
                                store_product_lefts[corp.corp_id][item.material_id]
                              ),
                              item.spec,
                              item.set_spec
                            )[1]
                          "
                          >{{
                            calNums(
                              Math.abs(
                                store_product_lefts[corp.corp_id][item.material_id]
                              ),
                              item.spec,
                              item.set_spec
                            )[1]
                          }}<span class="tiny-text">{{ item.unitname }}</span></span
                        ><span
                          v-show="
                            calNums(
                              Math.abs(
                                store_product_lefts[corp.corp_id][item.material_id]
                              ),
                              item.spec,
                              item.set_spec
                            )[2]
                          "
                          >{{
                            calNums(
                              Math.abs(
                                store_product_lefts[corp.corp_id][item.material_id]
                              ),
                              item.spec,
                              item.set_spec
                            )[2]
                          }}<span class="tiny-text">{{ item.spec_unitname }}</span></span
                        ></span
                      ><span v-else>0</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span v-if="item.add_quantity > 0">+</span
                      ><span v-if="item.add_quantity < 0">-</span>
                      <span
                        v-show="
                          calNums(
                            Math.abs(item.add_quantity),
                            item.spec,
                            item.set_spec
                          )[0]
                        "
                        >{{
                          calNums(
                            Math.abs(item.add_quantity),
                            item.spec,
                            item.set_spec
                          )[0]
                        }}<span class="tiny-text">{{ item.set_unitname }}</span></span
                      ><span
                        v-show="
                          calNums(
                            Math.abs(item.add_quantity),
                            item.spec,
                            item.set_spec
                          )[1]
                        "
                        >{{
                          calNums(
                            Math.abs(item.add_quantity),
                            item.spec,
                            item.set_spec
                          )[1]
                        }}<span class="tiny-text">{{ item.unitname }}</span></span
                      ><span
                        v-show="
                          calNums(
                            Math.abs(item.add_quantity),
                            item.spec,
                            item.set_spec
                          )[2]
                        "
                        >{{
                          calNums(
                            Math.abs(item.add_quantity),
                            item.spec,
                            item.set_spec
                          )[2]
                        }}<span class="tiny-text">{{ item.spec_unitname }}</span></span
                      ><span v-if="item.add_quantity > 0" class="small-text"
                        ><span class="small-text">(入)</span></span
                      ><span v-if="item.add_quantity < 0" class="small-text"
                        ><span class="small-text">(出)</span></span
                      >
                    </div>
                  </td>
                  <td>{{ item.remark }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="break-line"></div>
        <div class="break-line"></div>
        <div :style="{ display: 'flex' }" v-if="items_to_submit.length > 0">
          <div class="submit-button" @click="submitdata">提交数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SelectItem from "@/components/SelectItem.vue";
import DatetimeItem from "@/components/DatetimeItem.vue";
import InputItem from "@/components/InputItem.vue";
import {
  getCorpList,
  getPayments,
  submitCustomerProductData,
  getCustomerCnts,
  getDocuments,
  getMaterialOutins,
  getCustomerStoreMaterialLeftCnts,
  getStoresFs,
} from "@/api/other";
import { saveUserCorp } from "@/api/user";
import { getBasicConfig } from "@/api/auth";
import { getCategories, getMultipleMaterialLefts } from "@/api/other";
import { getAllUserList } from "@/api/user";
import { getMaterials } from "@/api/product";
import {
  //getSingleCustomer,
  getCustomerProducts,
  getSubProducts,
  getCustomersFs,
} from "@/api/customer";
import { calNums } from "@/utils/tool";
export default {
  name: "CreateBill",
  data: function () {
    return {
      corplist: [],
      corp: null,
      customers: [],
      customer: null,
      main_types: [
        { value: 3, label: "金额卡", cnt: 0 },
        { value: 2, label: "套卡", cnt: 0 },
        { value: 1, label: "项目", cnt: 0 },
        { value: 0, label: "产品", cnt: 0 },
      ],
      active_main_type: null,
      exhausted_types: [
        { value: "not_exhausted", label: "未耗完", cnt: 0 },
        { value: "exhausted", label: "已耗完", cnt: 0 },
      ],
      active_exhausted_type: {
        value: "not_exhausted",
        label: "未耗完",
        cnt: 0,
      },
      free_types: [
        { value: "not_free", label: "非赠送", cnt: 0 },
        { value: "free", label: "赠送", cnt: 0 },
      ],
      active_free_type: null,
      expired_types: [
        { value: "not_expired", label: "未过期", cnt: 0 },
        { value: "expired", label: "已过期", cnt: 0 },
      ],
      active_expired_type: { value: "not_expired", label: "未过期", cnt: 0 },
      show_tuikaxuka_func: 0, // -1: 退卡; 0: 隐藏; 1: 续卡
      arrearage: null,
      arrearage_material_id: null, // 还欠款选择的品项
      customer_products: [],
      tui_customer_products: [],
      sub_products: {},
      store_material_leftcnts: [],
      left_price: 0,
      left_price_free: 0,
      arrearages: [],
      main_type: 1,
      xiaofei_products: [],
      methods: [],
      tui_methods: [],
      effect_date: null,
      default_guwen: null,
      default_mei: null,
      userlist: [],
      haoka_cats: [],
      xiangshu_cats: [],
      shougong_cats: [],
      xiaofei_cats: [],
      main_type_dict: {},
      materials: [],
      customer_cnts: {},
      store_product_lefts: {},
      store_product_left_list: [],
      active_material_item: null, // 关联仓库时的id
      material_outins: [],
      add_type: null, // 'jine_haoka_list', 'haoka_products',
      jine_haoka_list: [],
      haoka_products: [],
      need_add_qinren: false,
      items_to_submit: [],
      create_document_switch: false,
      document_no: null,
      idx: 0,
      storedict: {},
    };
  },
  components: {
    SelectItem,
    DatetimeItem,
    InputItem,
  },
  computed: {
    relate_material_outin() {
      if (this.add_type === "haoka_products") {
        let product = this.haoka_products[0];
        let item = product.haoka_list[0];
        return this.add_type + "_" + item.relate_material_outin;
      }
      if (this.add_type === "jine_haoka_list") {
        let item = this.jine_haoka_list[0];
        if (!item) {
          return this.add_type + "_" + -1;
        }
        return this.add_type + "_" + item.relate_material_outin;
      }
      if (this.add_type === "xiaofei_products") {
        return this.add_type + "_" + this.xiaofei_products[0].relate_material_outin;
      }
      return this.add_type + "_" + -1;
    },
    customer_products_filtered() {
      let customer_products = this.arrearages.concat(this.customer_products);
      let active_exhausted_type = this.active_exhausted_type;
      if (active_exhausted_type) {
        customer_products = customer_products.filter(
          (v) =>
            (active_exhausted_type.value === "exhausted" && v.exhausted) ||
            (active_exhausted_type.value !== "exhausted" && !v.exhausted)
        );
      }
      let active_free_type = this.active_free_type;
      if (active_free_type) {
        if (active_free_type.value === "free") {
          customer_products = customer_products.filter((v) => v.is_free);
        } else {
          customer_products = customer_products.filter((v) => !v.is_free);
        }
      }
      let active_expired_type = this.active_expired_type;
      if (active_expired_type) {
        if (active_expired_type.value === "expired") {
          customer_products = customer_products.filter((v) => v.expired);
        } else {
          customer_products = customer_products.filter((v) => !v.expired);
        }
      }
      return customer_products;
    },
    last_str() {
      if (!this.effect_date) {
        return "";
      }
      let dt, now, today, yesterday, qiantian;
      now = new Date();
      today = new Date().format("yyyy-MM-dd");
      dt = new Date(this.effect_date).format("yyyy-MM-dd");
      yesterday = new Date(now);
      console.log("yesterday: ", yesterday);
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.format("yyyy-MM-dd");
      qiantian = new Date(yesterday);
      qiantian.setDate(qiantian.getDate() - 1);
      qiantian = qiantian.format("yyyy-MM-dd");
      if (dt === today) {
        return "今天 " + dt;
      } else if (dt === yesterday) {
        return "补录: 昨天" + dt;
      } else if (dt === qiantian) {
        return "补录: 前天" + dt;
      } else if (dt < qiantian) {
        return (
          `补录: ${Math.round(
            (new Date(today) - new Date(dt)) / 1000 / 60 / 60 / 24
          )}天前` + dt
        );
      } else {
        return "" + dt;
      }
    },
    store_product_ids() {
      let document_material_ids = this.document_items.map((v) => v.material_id);
      let product_ids = [];
      if (this.add_type === "tui_customer_products") {
        product_ids = this.tui_customer_products
          .filter((v) => v.main_type < 3)
          .map((v) => v.material_id);
      }
      if (this.add_type === "xiaofei_products") {
        product_ids = this.xiaofei_products
          .filter((v) => v.product && v.product.main_type < 3)
          .map((v) => v.product.material_id);
      }
      if (this.add_type === "haoka_products") {
        product_ids = this.haoka_products.reduce((acc, cur) => {
          let product_ids = cur["haoka_list"]
            .map((v) => {
              if (cur.main_type === 0 || cur.main_type === 1) {
                return v.product.material_id;
              } else if (cur.main_type === 2 && v.product) {
                return v.product.material_id;
              } else {
                return null;
              }
            })
            .filter((v) => v);
          return acc.concat(product_ids);
        }, []);
      }
      if (this.add_type === "jine_haoka_list") {
        product_ids = this.jine_haoka_list
          .filter(
            (v) =>
              v.product &&
              v.product.value &&
              v.product.main_type < 3 &&
              v.product.need_manage
          )
          .map((v) => v.product.material_id);
      }
      product_ids = document_material_ids.concat(product_ids);
      product_ids = Array.from(new Set(product_ids));
      product_ids.sort();
      return JSON.stringify(product_ids);
    },
    sub_product_price() {
      let rs = {};
      for (let k in this.sub_products) {
        let items = this.sub_products[k];
        let price = {};
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          let { material_id, left_quantity, left_price, left_price_free } = item;
          if (left_quantity) {
            price[material_id] = [
              left_price / left_quantity,
              left_price_free / left_quantity,
            ];
          }
        }
        rs[k] = price;
      }
      return rs;
    },
    my_corp() {
      //return this.$store.state.user.tmp_corp
      //  ? this.$store.state.user.tmp_corp
      //  : this.$store.state.user.corp;
      return this.$store.state.user.corp;
    },
    guwen_users() {
      let users = this.userlist.filter(
        (v) =>
          v.corp_id === this.my_corp.corp_id ||
          (v.ex_corp_ids && v.ex_corp_ids.includes(this.my_corp.corp_id)) ||
          v.tmp_corp_id === this.my_corp.corp_id
      );
      return users.filter((v) => v.role_type === 2);
    },
    mei_users() {
      let users = this.userlist.filter(
        (v) =>
          v.corp_id === this.my_corp.corp_id ||
          (v.ex_corp_ids && v.ex_corp_ids.includes(this.my_corp.corp_id)) ||
          v.tmp_corp_id === this.my_corp.corp_id
      );
      return users
        .filter((v) => v.role_type >= 1)
        .sort((a, b) => a.role_type - b.role_type);
    },
    haoka_products_str() {
      /*
      [
        {
          product_id,
          default_shoucat_id,
          shougong,
          xiang_ratio,
          cnt,
          all_performance_len,
          guwen_performance_len,
          mei_performance_len,
          default_haocat_id,
        },
        //...
      ];
      */
      let haoka_products = this.haoka_products.map((v) => {
        let haoka_list = v.haoka_list.map((a) => {
          let product_id = a.product ? a.product.value : null;
          let cnt = a.cnt;
          let all_performance_len = a.all_performance.length;
          let guwen_performance_len = a.guwen_performance.length;
          let mei_performance_len = a.mei_performance.length;
          let shougong;
          if (
            a.product &&
            (a.product.shougong || a.product.shougong === 0) &&
            a.product.custom
          ) {
            shougong = a.product.shougong;
          } else if (v.shougong || v.shougong === 0) {
            shougong = v.shougong;
          } else {
            shougong = 0;
          }
          let xiang_ratio;
          if (
            a.product &&
            (a.product.xiang_ratio || a.product.xiang_ratio === 0) &&
            a.product.custom
          ) {
            xiang_ratio = a.product.xiang_ratio;
          } else if (v.xiang_ratio || v.xiang_ratio === 0) {
            xiang_ratio = v.xiang_ratio;
          } else {
            xiang_ratio = v.main_type === 0 ? 0 : 1;
          }

          let default_shoucat_id;
          if (a.product && a.product.custom) {
            default_shoucat_id = a.product.default_shoucat_id;
          } else if (!default_shoucat_id && v.default_shoucat_id) {
            default_shoucat_id = v.default_shoucat_id;
          }
          let default_haocat_id;
          if (a.product && a.product.custom) {
            default_haocat_id = a.product.default_haocat_id;
          } else if (v.default_haocat_id) {
            default_haocat_id = v.default_haocat_id;
          } else if (a.product && a.product.default_haocat_id) {
            default_haocat_id = a.product.default_haocat_id;
          }
          return {
            product_id,
            default_shoucat_id,
            shougong,
            xiang_ratio,
            cnt,
            all_performance_len,
            guwen_performance_len,
            mei_performance_len,
            default_haocat_id,
          };
        });
        return haoka_list;
      });
      return JSON.stringify(haoka_products);
    },
    haoka_products_default_cat_str() {
      /*
      [
        {
          default_cat_id,
        },
        //...
      ];
      */
      let haoka_products = this.haoka_products.map((v) => {
        let haoka_list = v.haoka_list.map((a) => {
          let default_cat_id = a.default_cat ? a.default_cat.value : null;
          return {
            default_cat_id,
          };
        });
        return haoka_list;
      });
      return JSON.stringify(haoka_products);
    },
    jine_haoka_list_cnt_amount_str() {
      // [{ product_id, cnt, amount }, {}];
      let jine_haoka_list = this.jine_haoka_list.map((v) => {
        let product_id = v.product ? v.product.value : null;
        return { cnt: v.cnt, product_id: product_id, amount: v.amount };
      });
      return JSON.stringify(jine_haoka_list);
    },
    jine_haoka_list_default_yeji_cat_str() {
      // [{ default_cat_id }, {}];
      let jine_haoka_list = this.jine_haoka_list.map((v) => {
        let default_yeji_cat_id = v.default_yeji_cat ? v.default_yeji_cat.value : null;
        return { default_yeji_cat_id };
      });
      return JSON.stringify(jine_haoka_list);
    },
    jine_haoka_list_default_cat_str() {
      // [{ default_cat_id }, {}];
      let jine_haoka_list = this.jine_haoka_list.map((v) => {
        let default_cat_id = v.default_cat ? v.default_cat.value : null;
        return { default_cat_id };
      });
      return JSON.stringify(jine_haoka_list);
    },
    jine_haoka_list_methods_total_real_amount_str() {
      // 消耗的产品各个支付方式实付金额总和(不包括赠送) [total_method_amount]
      let jine_haoka_list = this.jine_haoka_list.map((v) => {
        return v.jine_methods.reduce(
          (acc, cur) => acc + (cur.real_amount ? cur.real_amount : 0),
          0
        );
        //return v.jine_methods[0];
      });
      return JSON.stringify(jine_haoka_list);
    },
    jine_haoka_list_method_amount_str() {
      // 消耗的产品各个支付方式总金额amount，用来更新real_amount(根据ratio)
      let jine_haoka_list = this.jine_haoka_list.map((v) => {
        return v.jine_methods.map((a) => (a.amount ? a.amount : 0));
      });
      return JSON.stringify(jine_haoka_list);
    },
    // addXiaoFeiBil start ====================
    tui_sub_products() {
      let res = this.tui_customer_products.map((v) => {
        if (v.main_type === 2 && v.sub_type === 2) {
          let sub_products = v.sub_products.map((m) => {
            return [m.cnt, m.price, m.price_free];
          });
          return sub_products;
        } else {
          return [];
        }
      });
      return JSON.stringify(res);
    },
    tui_customer_products_amounts() {
      // 为了自动填充最后一个支付方式的金额
      let res = this.tui_customer_products.map((product) => {
        let return_amount = product.return_amount ? product.return_amount : 0;
        let return_amount_free = product.return_amount_free
          ? product.return_amount_free
          : 0;
        let method_amounts = product.methods
          .slice(0, product.methods.length - 1)
          .map((v) => {
            let payment_id = v.method ? v.method.id : null;
            let amount =
              (v.amount ? v.amount : 0) +
              (v.method && v.method.payment_type === 1 && v.amount_free
                ? v.amount_free
                : 0);
            return payment_id + "_" + amount;
          });
        method_amounts.unshift(return_amount + return_amount_free);
        method_amounts.push(
          product.methods[product.methods.length - 1].method
            ? product.methods[product.methods.length - 1].method.payment_type
            : null
        );
        return method_amounts;
      });
      return JSON.stringify(res);
    },
    tui_customer_products_methods() {
      let res = this.tui_customer_products.map((product) => {
        let methods = product.methods.map((v) => {
          let payment_type = v.method ? v.method.payment_type : null;
          let amount = v.amount ? v.amount : 0;
          let jine_product_id = v.jine_product ? v.jine_product.value : null;
          let dao_amount = v.dao_amount ? v.dao_amount : 0;
          return [payment_type, amount, jine_product_id, dao_amount];
        });
        return methods;
      });
      return JSON.stringify(res);
    },
    tui_customer_products_guwen_users() {
      let res = this.tui_customer_products.map((product) => {
        let guwen_users = product.guwen_users
          ? product.guwen_users.map((v) => (v ? v.value : null))
          : [];
        return guwen_users;
      });
      return JSON.stringify(res);
    },
    tui_customer_products_mei_users() {
      let res = this.tui_customer_products.map((product) => {
        let mei_users = product.mei_users
          ? product.mei_users.map((v) => (v ? v.value : null))
          : [];
        return mei_users;
      });
      return JSON.stringify(res);
    },
    tui_customer_products_default_cats() {
      let res = this.tui_customer_products.map((product) => {
        let method_cats = product.methods.map((v) =>
          v.default_cat ? v.default_cat.value : null
        );
        return method_cats;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_amounts() {
      let res = this.xiaofei_products.map((xiaofei_product_item) => {
        let amount = xiaofei_product_item.amount ? xiaofei_product_item.amount : 0;
        let method_amounts = xiaofei_product_item.methods.map((v) =>
          v.amount ? v.amount : 0
        );
        if (method_amounts.length > 0) {
          method_amounts = method_amounts.slice(0, method_amounts.length - 1);
          method_amounts.unshift(amount);
        }
        return method_amounts;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_real_amounts() {
      // 每个 xiaofe_product 的real_amount。返回数组
      let res = this.xiaofei_products.map((xiaofei_product_item) => {
        if (!xiaofei_product_item) {
          return 0;
        }
        let real_amount = xiaofei_product_item.methods.reduce((acc, cur) => {
          if (!cur.method) {
            return acc;
          } else if (cur.method.payment_type === 1) {
            if (cur.jine_product) {
              return (
                acc +
                (cur.jine_product["real_amount"] ? cur.jine_product["real_amount"] : 0)
              );
            } else {
              return acc + (cur.amount ? cur.amount : 0);
            }
          } else if (cur.method.payment_type !== 4) {
            return acc + (cur.amount ? cur.amount : 0);
          } else if (cur.method.payment_type === 4) {
            return acc;
          }
        }, 0);
        return real_amount;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_methods() {
      // 用于监测 支付方式、金额、金额卡变动
      let res = this.xiaofei_products.map((product) => {
        if (!product) {
          return [];
        }
        let methods = product.methods.map((v) => {
          let payment_type = v.method ? v.method.payment_type : null;
          let amount = v.amount ? v.amount : 0;
          let jine_product_id = v.jine_product ? v.jine_product.value : null;
          let dao_amount = v.dao_amount ? v.dao_amount : 0;
          return [payment_type, amount, jine_product_id, dao_amount];
        });
        return methods;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_methods_free() {
      let res = this.xiaofei_products.map((product) => {
        let methods = product.methods.filter((v) => {
          let payment_type = v.method ? v.method.payment_type : null;
          if (payment_type === 4) {
            return true;
          } else if (payment_type === 2 && v.jine_product && v.jine_product.is_free) {
            return true;
          }
          return false;
        });
        return methods.length === product.methods.length;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_guwen_users() {
      let res = this.xiaofei_products.map((product) => {
        let guwen_users = product.guwen_users
          ? product.guwen_users.map((v) => (v ? v.value : null))
          : [];
        return guwen_users;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_mei_users() {
      let res = this.xiaofei_products.map((product) => {
        let mei_users = product.mei_users
          ? product.mei_users.map((v) => (v ? v.value : null))
          : [];
        return mei_users;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_default_cats() {
      let res = this.xiaofei_products.map((product) => {
        let method_cats = product.methods.map((v) =>
          v.default_cat ? v.default_cat.value : null
        );
        return method_cats;
      });
      return JSON.stringify(res);
    },
    xiaofei_products_default_haocats() {
      let res = this.xiaofei_products.map((product) =>
        product.default_cat ? product.default_cat.value : null
      );
      return JSON.stringify(res);
    },
    arrearage_products_methods() {
      let products = this.arrearage ? this.arrearage.products : [];
      let res = products.map((product) => {
        let method_amounts = product.methods.map((v) => {
          let payment_type = v.method ? v.method.payment_type : null;
          let amount = v.amount ? v.amount : 0;
          let jine_product_id = v.jine_product ? v.jine_product.value : null;
          let dao_amount = v.dao_amount ? v.dao_amount : 0;
          return [payment_type, amount, jine_product_id, dao_amount];
        });
        return method_amounts;
      });
      return JSON.stringify(res);
    },
    arrearage_products_guwen_users() {
      let products = this.arrearage ? this.arrearage.products : [];
      let res = products.map((product) => {
        let guwen_users = product.guwen_users
          ? product.guwen_users.map((v) => (v ? v.value : null))
          : [];
        return guwen_users;
      });
      return JSON.stringify(res);
    },
    arrearage_products_mei_users() {
      let products = this.arrearage ? this.arrearage.products : [];
      let res = products.map((product) => {
        let mei_users = product.mei_users
          ? product.mei_users.map((v) => (v ? v.value : null))
          : [];
        return mei_users;
      });
      return JSON.stringify(res);
    },
    arrearage_products_default_cats() {
      let products = this.arrearage ? this.arrearage.products : [];
      let res = products.map((product) => {
        let method_cats = product.methods.map((v) =>
          v.default_cat ? v.default_cat.value : null
        );
        return method_cats;
      });
      return JSON.stringify(res);
    },
    product_amount() {
      if (this.product && this.product.main_type === 2 && this.product.sub_type === 2) {
        return this.product.sub_products.reduce(
          (acc, cur) => acc + (cur.price ? cur.price : 0),
          0
        );
      } else {
        return 0;
      }
    },
    // addXiaoFeiBil end ====================
    customer_user_counts() {
      /*
      // customers
      [
        {
          customer: { value: -1, label: "xx" },
          customer_id: -1,
          customer_name: "xx",
          cur_type: 2,
          cnt: 1,
          1: [{ user: {}, user_id: 2, user_name: "mm", cnt: 1 }],
          2: [{ user: {}, user_id: 3, user_name: "nn", cnt: 1 }],
        },
      ];
      */
      if (this.$store.state.corptype) {
        return [];
      }
      let ids2idx = {};
      let customers = [];
      let customerid2dix = {};
      let haoka_products = [],
        jine_haoka_list = [],
        xiaofei_products = [];
      for (let i = 0; i < this.items_to_submit.length; i++) {
        let item = this.items_to_submit[i];
        let add_type = item["add_type"];
        if (add_type === "haoka_products") {
          haoka_products.push(item);
        } else if (add_type === "jine_haoka_list") {
          jine_haoka_list.push(item);
        } else if (add_type === "xiaofei_products") {
          xiaofei_products.push(item);
        }
      }
      //let jine_haoka_list = this.jine_haoka_list;
      let customer_idx,
        customer_id,
        customer_name,
        cur_type,
        key,
        customer,
        haoka_list_item,
        xiaofei_product_item;
      let consumed = false;
      for (let i = 0; i < haoka_products.length; i++) {
        consumed = true;
        let haoka_list = haoka_products[i].haoka_list;
        for (let j = 0; j < haoka_list.length; j++) {
          haoka_list_item = haoka_list[j];
          customer = haoka_list_item["customer"];
          cur_type = customer.cur_type;
          customer_id = customer.value;
          customer_name =
            customer_id === -1 && haoka_list_item.customer_name
              ? haoka_list_item.customer_name
              : customer.label;
          key = customer_name + "_" + customer_id;
          if (!(key in customerid2dix)) {
            customers.push({
              customer: customer,
              customer_id: customer_id,
              customer_name: customer_name,
              cur_type: cur_type,
              cnt: 1,
              1: [],
              2: [],
            });
            customer_idx = customers.length - 1;
            customerid2dix[key] = customer_idx;
          } else {
            customer_idx = customerid2dix[key];
          }
          for (let k = 0; k < haoka_list_item.guwen_performance.length; k++) {
            let user = haoka_list_item.guwen_performance[k].user;
            if (user) {
              let user_id = user.value;
              let ids = key + "_" + user_id;
              if (!(ids in ids2idx)) {
                let role_type = user.role_type ? user.role_type : 2;
                if (role_type > 2) {
                  role_type = 2;
                }
                role_type = 2;
                customers[customer_idx][role_type].push({ user: user, cnt: 1 });
                ids2idx[ids] = customers[customer_idx][role_type].length - 1;
              }
            }
          }
          for (let k = 0; k < haoka_list_item.mei_performance.length; k++) {
            let user = haoka_list_item.mei_performance[k].user;
            if (user) {
              let user_id = user.value;
              let ids = key + "_" + user_id;
              if (!(ids in ids2idx)) {
                let role_type = user.role_type ? user.role_type : 1;
                if (role_type > 2) {
                  role_type = 2;
                }
                role_type = 1;
                customers[customer_idx][role_type].push({ user: user, cnt: 1 });
                ids2idx[ids] = customers[customer_idx][role_type].length - 1;
              }
            }
          }
        }
      }
      if (jine_haoka_list.length > 0) {
        consumed = true;
        let haoka_list = jine_haoka_list;
        for (let j = 0; j < haoka_list.length; j++) {
          haoka_list_item = haoka_list[j];
          customer = haoka_list_item["customer"];
          customer_id = customer.value;
          customer_name =
            customer_id === -1 && haoka_list_item.customer_name
              ? haoka_list_item.customer_name
              : customer.label;
          key = customer_name + "_" + customer_id;
          if (!(key in customerid2dix)) {
            customers.push({
              customer: customer,
              customer_id: customer_id,
              customer_name: customer_name,
              cur_type: cur_type,
              cnt: 1,
              1: [],
              2: [],
            });
            customer_idx = customers.length - 1;
            customerid2dix[key] = customer_idx;
          } else {
            customer_idx = customerid2dix[key];
          }
          for (let k = 0; k < haoka_list_item.guwen_performance.length; k++) {
            let user = haoka_list_item.guwen_performance[k].user;
            if (user) {
              let user_id = user.value;
              let ids = key + "_" + user_id;
              if (!(ids in ids2idx)) {
                let role_type = user.role_type ? user.role_type : 2;
                if (role_type > 2) {
                  role_type = 2;
                }
                role_type = 2;
                customers[customer_idx][role_type].push({ user: user, cnt: 1 });
                ids2idx[ids] = customers[customer_idx][role_type].length - 1;
              }
            }
          }
          for (let k = 0; k < haoka_list_item.mei_performance.length; k++) {
            let user = haoka_list_item.mei_performance[k].user;
            if (user) {
              let user_id = user.value;
              let ids = key + "_" + user_id;
              if (!(ids in ids2idx)) {
                let role_type = user.role_type ? user.role_type : 1;
                if (role_type > 2) {
                  role_type = 2;
                }
                role_type = 1;
                customers[customer_idx][role_type].push({ user: user, cnt: 1 });
                ids2idx[ids] = customers[customer_idx][role_type].length - 1;
              }
            }
          }
        }
      }
      customer = this.customer;
      for (let i = 0; i < xiaofei_products.length; i++) {
        xiaofei_product_item = xiaofei_products[i];
        if (!xiaofei_product_item.consumed) {
          continue;
        }
        consumed = true;
        cur_type = customer.cur_type;
        customer_id = customer.customer_id;
        customer_name = customer.name;
        key = customer_name + "_" + customer_id;
        if (!(key in customerid2dix)) {
          customers.push({
            customer: customer,
            customer_id: customer_id,
            customer_name: customer_name,
            cur_type: cur_type,
            cnt: 1,
            1: [],
            2: [],
          });
          customer_idx = customers.length - 1;
          customerid2dix[key] = customer_idx;
        } else {
          customer_idx = customerid2dix[key];
        }
        for (let k = 0; k < xiaofei_product_item.guwen_performance.length; k++) {
          let user = xiaofei_product_item.guwen_performance[k].user;
          if (user) {
            let user_id = user.value;
            let ids = key + "_" + user_id;
            if (!(ids in ids2idx)) {
              let role_type = user.role_type ? user.role_type : 2;
              if (role_type > 2) {
                role_type = 2;
              }
              role_type = 2;
              customers[customer_idx][role_type].push({ user: user, cnt: 1 });
              ids2idx[ids] = customers[customer_idx][role_type].length - 1;
            }
          }
        }
        for (let k = 0; k < xiaofei_product_item.mei_performance.length; k++) {
          let user = xiaofei_product_item.mei_performance[k].user;
          if (user) {
            let user_id = user.value;
            let ids = key + "_" + user_id;
            if (!(ids in ids2idx)) {
              let role_type = user.role_type ? user.role_type : 1;
              if (role_type > 2) {
                role_type = 2;
              }
              role_type = 1;
              customers[customer_idx][role_type].push({ user: user, cnt: 1 });
              ids2idx[ids] = customers[customer_idx][role_type].length - 1;
            }
          }
        }
      }
      if (!consumed) {
        //return [];
        console.log("aaaaaaa");
      }
      for (let i = 0; i < customers.length; i++) {
        let item = customers[i];
        let customer_id = item.customer_id;
        let customer_name = item.customer_name;
        key = customer_name + "_" + customer_id;
        if (key in this.customer_cnts) {
          let customer_cnts = this.customer_cnts[key];
          for (let j = 0; j < customer_cnts.length; j++) {
            let c_item = customer_cnts[j];
            let role_type = c_item["role_type"];
            let user_id = c_item["user_id"];
            let name = c_item["name"];
            if (role_type !== 1 && role_type !== 2) {
              role_type = 1;
            }
            let ts = item[role_type].filter((v) => v.user.value === user_id);
            if (ts.length === 0) {
              item[role_type].push({
                user: {
                  value: user_id,
                  label: name,
                  id: user_id,
                  name: name,
                  role_type: role_type,
                },
                user_id: user_id,
                user_name: name,
                cnt: 1,
                overtime: c_item["overtime"] ? c_item["overtime"] : null,
              });
            }
          }
        }
      }
      for (let i = 0; i < customers.length; i++) {
        let item = customers[i];
        if (item[1].length > 1 && this.$store.state.intelligent) {
          for (let j = 0; j < item[1].length; j++) {
            item[1][j].cnt = 0.5;
          }
        }
        if (item[2].length > 1 && this.$store.state.intelligent) {
          for (let j = 0; j < item[2].length; j++) {
            item[2][j].cnt = 0.5;
          }
        }
      }
      return customers;
    },
    document_items() {
      let items = [];
      for (let i = 0; i < this.items_to_submit.length; i++) {
        let item = this.items_to_submit[i];
        if (item.add_type === "xiaofei_products") {
          if (item.main_type === 0 && item.consumed && item.relate_material_outin === 2) {
            items.push({
              material_id: item.material_id,
              material_name: item.material_name,
              add_quantity: -item.cnt,
              unitname: item.unitname,
              spec: item.spec,
              spec_unitname: item.spec_unitname,
              set_spec: item.set_spec,
              set_unitname: item.set_unitname,
              remark: item.remark,
            });
          }
        }
        if (
          item.add_type === "haoka_products" &&
          item["haoka_list"][0].relate_material_outin === 2
        ) {
          if (item.main_type === 0) {
            items.push({
              material_id: item.material_id,
              material_name: item.material_name,
              add_quantity: -item["haoka_list"][0].cnt,
              unitname: item.unitname,
              spec: item.spec,
              spec_unitname: item.spec_unitname,
              set_spec: item.set_spec,
              set_unitname: item.set_unitname,
              remark: item.remark,
            });
          }
        }
      }
      return items;
    },
    store_material_items() {
      let id2idx = {};
      let items = [];
      let k;
      for (let i = 0; i < this.document_items.length; i++) {
        let item = this.document_items[i];
        let {
          material_id,
          material_name,
          add_quantity,
          unitname,
          spec,
          spec_unitname,
          set_spec,
          set_unitname,
        } = item;
        if (material_id in id2idx) {
          k = id2idx[material_id];
          items[k].add_quantity = items[k].add_quantity + add_quantity;
        } else {
          items.push({
            material_id,
            material_name,
            add_quantity,
            unitname,
            spec,
            spec_unitname,
            set_spec,
            set_unitname,
          });
          k = items.length - 1;
          id2idx[material_id] = k;
        }
      }
      return items;
    },
  },
  watch: {
    main_type() {
      if (this.add_type === "xiaofei_products") {
        this.xiaofei_products[0].product = null;
        this.xiaofei_products[0].cnt = null;
        this.xiaofei_products[0].amount = null;
      }
    },
    add_type(v) {
      if (v === "xiaofei_products") {
        let mei_users = [];
        let guwen_users = [];
        if (this.default_guwen) {
          guwen_users.push(this.default_guwen);
        }
        if (this.default_mei) {
          mei_users.push(this.default_mei);
        }
        this.methods = [];

        let cat = null;
        let default_xiang_ratio = 1;
        let xiang_ratio = default_xiang_ratio;
        let xiang_amount = xiang_ratio < 0 ? 1 : xiang_ratio;
        let xiaohao_amount = 0;
        let all_performance = [
          {
            haoka: {
              cat: cat,
              amount: xiaohao_amount,
            },
            xiangshu: {
              cat: this.xiangshu_cats[0],
              amount: xiang_amount,
            },
          },
        ];
        let guwen_performance = [
          {
            user: this.default_guwen,
            haoka: {
              cat: cat,
              amount: xiaohao_amount,
            },
            xiangshu: {
              cat: this.xiangshu_cats[0],
              amount: xiang_amount,
            },
            shougong: [],
          },
        ];
        let mei_performance = [
          {
            user: this.default_mei,
            haoka: {
              cat: cat,
              amount: xiaohao_amount,
            },
            shougong: this.shougong_cats.map((v) => {
              return { cat: v, amount: null };
            }),
            xiangshu: {
              cat: this.xiangshu_cats[0],
              amount: null,
            },
          },
        ];
        //let special_product = method_free ? 2 : product.special_product;
        let relate_material_outin = 0;
        let relate_material_outins = [
          { value: 0, label: "不关联仓库" },
          { value: 1, label: "关联现有出入记录" },
        ];
        this.xiaofei_products = [
          {
            add_type: "xiaofei_products",
            product: null,
            cnt: null,
            amount: null,
            unitname: 0,
            custom_product_name: null,
            custom_product_unitname: null,
            methods: [],
            guwen_users,
            mei_users,
            remark: null,
            consumed: false,
            relate_material_outin,
            store_id: null,
            material_outins: [],
            relate_material_outins,
            default_cat: null,
            all_performance,
            guwen_performance,
            mei_performance,
          },
        ];
      } else {
        this.xiaofei_products = [];
      }
    },
    relate_material_outin() {
      this.material_outins = [];
      let customer_id, item;
      if (this.add_type === "haoka_products") {
        let product = this.haoka_products[0];
        item = product.haoka_list[0];
        customer_id = item ? item.customer.value : null;
      }
      if (this.add_type === "jine_haoka_list") {
        item = this.jine_haoka_list[0];
        customer_id = item ? item.customer.value : null;
      }
      if (this.add_type === "xiaofei_products") {
        item = this.xiaofei_products[0];
        customer_id = item ? this.customer.customer_id : null;
      }
      if (!item || item.relate_material_outin !== 1) {
        return;
      }
      let material_id = item.product.material_id;
      let effect_date = new Date(this.effect_date).format("yyyy-MM-dd");
      let query_type = 1;
      let data = { material_id, effect_date, customer_id, query_type };
      getMaterialOutins(data).then((res) => {
        this.material_outins = res.content.map((v) => {
          v.checked = false;
          return v;
        });
      });
    },
    methods(v) {
      let product = this.xiaofei_products[0].product;
      let method_free = true;
      this.xiaofei_products[0].methods = v.map((method, i) => {
        let amount = i === 0 ? this.xiaofei_products[0].amount : 0;
        let real_amount = amount;
        let dao_ratio = this.$store.state.dao_amount_enabled ? product.dao_ratio : 100;
        let dao_amount = Math.round(amount * dao_ratio) / 100;
        let yeji_amount =
          this.$store.state.dao_amount_enabled === 1 ? dao_amount : real_amount;
        let fencheng = dao_ratio < 100;
        let has_performance = this.show_add_performance;
        let default_cat = null;
        let cat = null;
        if (method.payment_type === 0 && product.default_yecat_id) {
          let t = this.xiaofei_cats.filter((v) => v.id === product.default_yecat_id);
          if (t.length === 1) {
            cat = t[0];
            default_cat = t[0];
            has_performance = true;
          }
        }
        if (method.payment_type !== 4) {
          method_free = false;
        }
        let all_performance = [
          {
            yeji: {
              cat: cat,
              amount: yeji_amount,
            },
            type: "all_performance",
          },
        ];
        let guwen_users = this.xiaofei_products[0].guwen_users;
        let guwen_performance = guwen_users.map((user) => {
          return {
            user,
            yeji: {
              cat: cat,
              amount: Math.round((yeji_amount / guwen_users.length) * 100) / 100,
            },
            type: "guwen_performance",
          };
        });
        let mei_users = this.xiaofei_products[0].mei_users;
        let mei_performance = mei_users.map((user) => {
          return {
            user,
            yeji: {
              cat: cat,
              amount: Math.round((yeji_amount / mei_users.length) * 100) / 100,
            },
            type: "mei_performance",
          };
        });
        console.log("mei_performance:", mei_performance);
        return {
          method: method,
          fencheng,
          amount: amount,
          real_amount: real_amount, // 非赠送的金额
          dao_amount,
          jine_product: null,
          default_cat: default_cat,
          all_performance: all_performance,
          guwen_performance: guwen_performance,
          mei_performance: mei_performance,
          has_performance: has_performance,
        };
      });
      console.log(method_free);
      if (!product) {
        return;
      }
      let cat = null;
      let default_haocat_id = product.default_haocat_id;
      let cats = this.haoka_cats.filter((v) => v.value === default_haocat_id);
      cat = cats.length > 0 ? cats[0] : null;
      this.xiaofei_products[0]["default_cat"] = cat;
      this.xiaofei_products[0]["default_cat"] = cat;
      this.xiaofei_products[0]["default_cat"] = cat;
    },
    effect_date() {
      this.getcustomercnts();
      this.getdocuments();
      if (this.effect_date && this.idx) {
        let idx = "" + this.idx;
        let len = idx.length;
        if (len < 3) {
          idx = Array(4 - len).join("0") + idx;
        }
        this.document_no = new Date(this.effect_date).format("yyyyMMdd") + "-" + idx;
      }
    },
    idx() {
      if (this.effect_date && this.idx) {
        let idx = "" + this.idx;
        let len = idx.length;
        if (len < 3) {
          idx = Array(4 - len).join("0") + idx;
        }
        this.document_no = new Date(this.effect_date).format("yyyyMMdd") + "-" + idx;
      }
    },
    store_product_ids() {
      let material_ids = JSON.parse(this.store_product_ids);
      let customer_id = this.customer.customer_id;
      let data = { material_ids, customer_id };
      let store_product_lefts = this.store_product_lefts;
      if (material_ids.length > 0) {
        getMultipleMaterialLefts(data).then((res) => {
          let items = res.content;
          this.store_product_left_list = items;
          for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let { store_id, material_id, left_cnt, name } = item;
            if (!(store_id in store_product_lefts)) {
              store_product_lefts[store_id] = { name };
            }
            store_product_lefts[store_id][material_id] = left_cnt;
          }
          this.store_product_lefts = JSON.parse(JSON.stringify(store_product_lefts));
        });
      }
    },
    corp(v, pv) {
      document.title =
        "开单" + "-" + v.name + (this.$store.state.env === "development" ? "(开发)" : "");
      this.getcustomersfs();
      let corp_id = v ? v.corp_id : null;
      let pre_corp_id = pv ? pv.corp_id : null;
      this.add_type = null;
      this.customer = null;
      if (
        pre_corp_id &&
        corp_id !== pre_corp_id &&
        this.$store.state.user.exchange_user &&
        v.customer_enabled
      ) {
        let user_id = this.$store.state.user_id;
        saveUserCorp(user_id, corp_id)
          .then(() => {
            getBasicConfig().then((res) => {
              let content = res.content;
              this.$store.commit("set_user", content.user);
            });
          })
          .catch((e) => {
            this.$store.commit("add_notification", { msg: "提交失败：" + e });
          });
      }
    },
    customer(v) {
      this.customer_products = [];
      if (v) {
        this.items_to_submit = [];
        this.haoka_products = [];
        this.jine_haoka_list = [];
        //getSingleCustomer(v.customer_id, this.corp.corp_id).then((res) => {
        //  this.customer2 = res.content;
        //});
        this.getcustomerproducts();
        if (this.$store.state.corptype === 1) {
          this.getcustomerstorematerialleftcnts();
        }
        // 设置默认美容师和顾问
        this.default_guwen = null;
        this.default_mei = null;
        let user = this.$store.state.user;
        if (user.role_type === 1 && user.main_role_type === 1) {
          this.default_mei = {
            value: user.id,
            label: user.name,
            id: user.id,
            name: user.name,
          };
        } else if (user.role_type === 2 && user.main_role_type >= 2) {
          if (this.$store.state.enable_guwen_xiaohao) {
            this.default_guwen = {
              value: user.id,
              label: user.name,
              id: user.id,
              name: user.name,
            };
          } else {
            this.default_mei = {
              value: user.id,
              label: user.name,
              id: user.id,
              name: user.name,
            };
          }
        } else {
          let { user1_id, user2_id } = v;
          let userlist = this.userlist.filter(
            (v) =>
              v.corp_id === this.corp.corp_id ||
              (v.ex_corp_ids && v.ex_corp_ids.includes(this.corp.corp_id)) ||
              v.tmp_corp_id === this.corp.corp_id
          );
          let user2;
          if (user2_id) {
            let users = userlist.filter((v) => v.id === user2_id);
            if (users.length) {
              user = users[0];
              if (user.role_type === 1) {
                this.default_mei = {
                  value: user.id,
                  label: user.name,
                  id: user.id,
                  name: user.name,
                };
              } else if (user.role_type === 2) {
                if (this.$store.state.enable_guwen_xiaohao) {
                  this.default_guwen = {
                    value: user.id,
                    label: user.name,
                    id: user.id,
                    name: user.name,
                  };
                } else {
                  this.default_mei = {
                    value: user.id,
                    label: user.name,
                    id: user.id,
                    name: user.name,
                  };
                }
              }
              user2 = user;
            }
          }
          if (user1_id) {
            let users = userlist.filter((v) => v.id === user1_id);
            if (users.length) {
              user = users[0];
              if (user.role_type === 1) {
                this.default_mei = {
                  value: user.id,
                  label: user.name,
                  id: user.id,
                  name: user.name,
                };
              } else if (user.role_type === 2) {
                if (this.$store.state.enable_guwen_xiaohao) {
                  this.default_guwen = {
                    value: user.id,
                    label: user.name,
                    id: user.id,
                    name: user.name,
                  };
                } else {
                  if (!user2 || (user2 && user2["role_type"] === 2)) {
                    this.default_mei = {
                      value: user.id,
                      label: user.name,
                      id: user.id,
                      name: user.name,
                    };
                  }
                }
              }
            }
          }
        }
        // 设置默认美容师顾问结束
        this.add_type = null;
        this.arrearage_material_id = null;
        setTimeout(() => {
          this.add_type = "xiaofei_products";
        }, 0);
      }
    },
    default_guwen() {
      for (let i = 0; i < this.haoka_products.length; i++) {
        let haoka_product = this.haoka_products[i];
        for (let j = 0; j < haoka_product.haoka_list.length; j++) {
          let haoka_list_item = haoka_product.haoka_list[j];
          let guwen_performance = haoka_list_item.guwen_performance;
          for (let k = 0; k < guwen_performance.length; k++) {
            guwen_performance[k].user = this.default_guwen;
          }
        }
      }
      for (let j = 0; j < this.jine_haoka_list.length; j++) {
        let haoka_list_item = this.jine_haoka_list[j];
        let guwen_performance = haoka_list_item.guwen_performance;
        for (let k = 0; k < guwen_performance.length; k++) {
          guwen_performance[k].user = this.default_guwen;
        }
        let guwen_yeji_performance = haoka_list_item.guwen_yeji_performance;
        console.log("guwen_yeji_performance: ", guwen_yeji_performance);
        for (let k = 0; k < guwen_yeji_performance.length; k++) {
          guwen_yeji_performance[k].user = this.default_guwen;
        }
      }
    },
    default_mei() {
      for (let i = 0; i < this.haoka_products.length; i++) {
        let haoka_product = this.haoka_products[i];
        for (let j = 0; j < haoka_product.haoka_list.length; j++) {
          let haoka_list_item = haoka_product.haoka_list[j];
          let mei_performance = haoka_list_item.mei_performance;
          for (let k = 0; k < mei_performance.length; k++) {
            mei_performance[k].user = this.default_mei;
          }
        }
      }
      for (let j = 0; j < this.jine_haoka_list.length; j++) {
        let haoka_list_item = this.jine_haoka_list[j];
        let mei_performance = haoka_list_item.mei_performance;
        for (let k = 0; k < mei_performance.length; k++) {
          mei_performance[k].user = this.default_mei;
        }
        let mei_yeji_performance = haoka_list_item.mei_yeji_performance;
        for (let k = 0; k < mei_yeji_performance.length; k++) {
          mei_yeji_performance[k].user = this.default_mei;
        }
      }
    },
    haoka_products_str(cur_value, pre_value) {
      /*
      [
        {
          product_id,
          default_shoucat_id,
          shougong,
          xiang_ratio,
          cnt,
          all_performance_len,
          guwen_performance_len,
          mei_performance_len,
          default_haocat_id,
        },
        //...
      ];
      */
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      console.log("haoka_products_str cv: ", cv);
      console.log("haoka_products_str pv: ", pv);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let c_haoka_len = cv[i].length;
        let p_haoka_len = pv[i].length;
        let haoka_len = c_haoka_len > p_haoka_len ? p_haoka_len : c_haoka_len;
        let default_xiang_ratio = this.haoka_products[i].main_type === 0 ? 0 : 1;
        let xiang_ratio =
          this.haoka_products[i].xiang_ratio || this.haoka_products[i].xiang_ratio === 0
            ? this.haoka_products[i].xiang_ratio
            : default_xiang_ratio;
        let shougong =
          this.haoka_products[i].shougong || this.haoka_products[i].shougong === 0
            ? this.haoka_products[i].shougong
            : null;
        let default_haocat_id = this.haoka_products[i].default_haocat_id;
        for (let j = 0; j < haoka_len; j++) {
          console.log("cv[i][j]: ", cv[i][j]);
          let product_id = cv[i][j].product_id;
          let product = this.materials.filter((v) => v.value === product_id);
          product = product.length > 0 ? product[0] : {};
          //let default_haocat_id = product.default_haocat_id;
          xiang_ratio = cv[i][j].xiang_ratio ? cv[i][j].xiang_ratio : 0;
          shougong = cv[i][j].shougong;

          let all_performance = this.haoka_products[i]["haoka_list"][j].all_performance;
          let all_performance_len = all_performance.length;
          let guwen_performance = this.haoka_products[i]["haoka_list"][j]
            .guwen_performance;
          let guwen_performance_len = guwen_performance.length;
          let mei_performance = this.haoka_products[i]["haoka_list"][j].mei_performance;
          let mei_performance_len = mei_performance.length;
          let cp_id = this.haoka_products[i]["id"];
          let { main_type, sub_type } = this.haoka_products[i];
          //let material_id = this.haoka_products[i]["haoka_list"][j]["product"][
          //  "material_id"
          //];
          let material_id = cv[i][j].product_id;
          if (cv[i][j].product_id !== pv[i][j].product_id) {
            console.log("消耗的套卡项目有变");
            // 需要变的：消耗类别，项数，手工，(如果sub_type===2)还要变金额
            default_haocat_id = cv[i][j].default_haocat_id;
            console.log("default_haocat_id: ", default_haocat_id);
            let cat = this.haoka_cats.filter((v) => v.value === default_haocat_id);
            cat = cat.length > 0 ? cat[0] : null;
            this.haoka_products[i]["haoka_list"][j].default_cat = cat;

            for (let k = 0; k < all_performance_len; k++) {
              //all_performance[k].haoka.cat = cat;
              if (xiang_ratio < 0) {
                all_performance[k].xiangshu.amount =
                  Math.round(100 / all_performance_len) / 100;
              } else {
                all_performance[k].xiangshu.amount =
                  Math.round((cv[i][j].cnt * xiang_ratio * 100) / all_performance_len) /
                  100;
              }
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                all_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      all_performance_len
                  ) / 100;
              }
            }
            for (let k = 0; k < guwen_performance_len; k++) {
              //guwen_performance[k].haoka.cat = cat;
              if (xiang_ratio < 0) {
                guwen_performance[k].xiangshu.amount = 1 / guwen_performance_len;
              } else {
                guwen_performance[k].xiangshu.amount =
                  (cv[i][j].cnt * xiang_ratio) / guwen_performance_len;
              }
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                guwen_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      guwen_performance_len
                  ) / 100;
              }
            }
            for (let k = 0; k < mei_performance_len; k++) {
              //mei_performance[k].haoka.cat = cat;
              if (xiang_ratio < 0) {
                mei_performance[k].xiangshu.amount = 1 / mei_performance_len;
              } else {
                mei_performance[k].xiangshu.amount =
                  (cv[i][j].cnt * xiang_ratio) / mei_performance_len;
              }
              mei_performance[k].shougong[0].amount =
                (shougong * cv[i][j].cnt) / mei_performance_len;
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                mei_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      mei_performance_len
                  ) / 100;
              }
            }
          }
          if (cv[i][j].cnt !== pv[i][j].cnt) {
            console.log("消耗数量有变");
            // 需要变的: 整店消耗金额、项数；顾问消耗金额、项数；美容师消耗金额、项数、手工
            for (let k = 0; k < all_performance_len; k++) {
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                all_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      all_performance_len
                  ) / 100;
              } else {
                all_performance[k].haoka.amount =
                  Math.round(
                    (((cv[i][j].cnt * this.haoka_products[i].left_price_4hao) /
                      this.haoka_products[i].left_quantity) *
                      100) /
                      all_performance_len
                  ) / 100;
              }
              if (xiang_ratio < 0) {
                all_performance[k].xiangshu.amount =
                  Math.round(100 / all_performance_len) / 100;
              } else {
                all_performance[k].xiangshu.amount =
                  Math.round((cv[i][j].cnt * 100 * xiang_ratio) / all_performance_len) /
                  100;
              }
            }
            for (let k = 0; k < guwen_performance_len; k++) {
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                guwen_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      guwen_performance_len
                  ) / 100;
              } else {
                guwen_performance[k].haoka.amount =
                  Math.round(
                    (((cv[i][j].cnt * this.haoka_products[i].left_price_4hao) /
                      this.haoka_products[i].left_quantity) *
                      100) /
                      guwen_performance_len
                  ) / 100;
              }
              if (xiang_ratio < 0) {
                guwen_performance[k].xiangshu.amount =
                  Math.round(100 / guwen_performance_len) / 100;
              } else {
                guwen_performance[k].xiangshu.amount =
                  Math.round((cv[i][j].cnt * 100 * xiang_ratio) / guwen_performance_len) /
                  100;
              }
            }
            for (let k = 0; k < mei_performance_len; k++) {
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                mei_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      mei_performance_len
                  ) / 100;
              } else {
                mei_performance[k].haoka.amount =
                  Math.round(
                    (((cv[i][j].cnt * this.haoka_products[i].left_price_4hao) /
                      this.haoka_products[i].left_quantity) *
                      100) /
                      mei_performance_len
                  ) / 100;
              }
              if (xiang_ratio < 0) {
                mei_performance[k].xiangshu.amount =
                  Math.round(100 / mei_performance_len) / 100;
              } else {
                mei_performance[k].xiangshu.amount =
                  Math.round((cv[i][j].cnt * 100 * xiang_ratio) / mei_performance_len) /
                  100;
              }
              mei_performance[k].shougong[0].amount =
                (shougong * cv[i][j].cnt) / mei_performance_len;
            }
          }
          if (cv[i][j].all_performance_len !== pv[i][j].all_performance_len) {
            console.log("整店绩效条数有变");
            // 变: 整店消耗金额、项数
            for (let k = 0; k < all_performance_len; k++) {
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                all_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      all_performance_len
                  ) / 100;
              } else {
                all_performance[k].haoka.amount =
                  Math.round(
                    (((cv[i][j].cnt * this.haoka_products[i].left_price_4hao) /
                      this.haoka_products[i].left_quantity) *
                      100) /
                      all_performance_len
                  ) / 100;
              }
              if (xiang_ratio < 0) {
                all_performance[k].xiangshu.amount =
                  Math.round(100 / all_performance_len) / 100;
              } else {
                all_performance[k].xiangshu.amount =
                  Math.round((cv[i][j].cnt * 100 * xiang_ratio) / all_performance_len) /
                  100;
              }
            }
          }
          if (cv[i][j].guwen_performance_len !== pv[i][j].guwen_performance_len) {
            console.log(
              "{{$store.state.terms[2] ? $store.state.terms[2] : '顾问'}}绩效条数有变"
            );
            // 变: 顾问消耗金额、项数
            for (let k = 0; k < guwen_performance_len; k++) {
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                guwen_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      guwen_performance_len
                  ) / 100;
              } else {
                guwen_performance[k].haoka.amount =
                  Math.round(
                    (((cv[i][j].cnt * this.haoka_products[i].left_price_4hao) /
                      this.haoka_products[i].left_quantity) *
                      100) /
                      guwen_performance_len
                  ) / 100;
              }
              if (xiang_ratio < 0) {
                guwen_performance[k].xiangshu.amount =
                  Math.round(100 / guwen_performance_len) / 100;
              } else {
                guwen_performance[k].xiangshu.amount =
                  Math.round((cv[i][j].cnt * 100 * xiang_ratio) / guwen_performance_len) /
                  100;
              }
            }
          }
          if (cv[i][j].mei_performance_len !== pv[i][j].mei_performance_len) {
            //console.log("美容师绩效条数有变");
            // 变: 美容师消耗金额、项数、手工
            let cp_id = this.haoka_products[i]["id"];
            let { main_type, sub_type } = this.haoka_products[i];
            let material_id = this.haoka_products[i]["haoka_list"][j]["product"][
              "material_id"
            ];
            for (let k = 0; k < mei_performance_len; k++) {
              if (
                main_type === 2 &&
                sub_type === 2 &&
                cp_id in this.sub_product_price &&
                material_id in this.sub_product_price[cp_id]
              ) {
                mei_performance[k].haoka.amount =
                  Math.round(
                    (this.sub_product_price[cp_id][material_id][0] * cv[i][j].cnt * 100) /
                      mei_performance_len
                  ) / 100;
              } else {
                mei_performance[k].haoka.amount =
                  Math.round(
                    (((cv[i][j].cnt * this.haoka_products[i].left_price_4hao) /
                      this.haoka_products[i].left_quantity) *
                      100) /
                      mei_performance_len
                  ) / 100;
              }
              if (xiang_ratio < 0) {
                mei_performance[k].xiangshu.amount =
                  Math.round(100 / mei_performance_len) / 100;
              } else {
                mei_performance[k].xiangshu.amount =
                  Math.round((cv[i][j].cnt * 100 * xiang_ratio) / mei_performance_len) /
                  100;
              }
              mei_performance[k].shougong[0].amount =
                (shougong * cv[i][j].cnt) / mei_performance_len;
            }
          }
        }
      }
    },
    haoka_products_default_cat_str(cur_value, pre_value) {
      /*
      [
        {
          default_cat_id,
        },
        //...
      ];
      */
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      console.log("haoka_products_default_cat_str cv: ", cv);
      console.log("haoka_products_default_cat_str pv: ", pv);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let c_haoka_len = cv[i].length;
        let p_haoka_len = pv[i].length;
        let haoka_len = c_haoka_len > p_haoka_len ? p_haoka_len : c_haoka_len;
        for (let j = 0; j < haoka_len; j++) {
          let all_performance = this.haoka_products[i]["haoka_list"][j].all_performance;
          let all_performance_len = all_performance.length;
          let guwen_performance = this.haoka_products[i]["haoka_list"][j]
            .guwen_performance;
          let guwen_performance_len = guwen_performance.length;
          let mei_performance = this.haoka_products[i]["haoka_list"][j].mei_performance;
          let mei_performance_len = mei_performance.length;
          if (cv[i][j].default_cat_id !== pv[i][j].default_cat_id) {
            // 需要变的：消耗类别，项数，手工，(如果sub_type===2)还要变金额
            let default_haocat_id = cv[i][j].default_cat_id;
            let cat = this.haoka_cats.filter((v) => v.value === default_haocat_id);
            cat = cat.length > 0 ? cat[0] : null;

            for (let k = 0; k < all_performance_len; k++) {
              all_performance[k].haoka.cat = cat;
            }
            for (let k = 0; k < guwen_performance_len; k++) {
              guwen_performance[k].haoka.cat = cat;
            }
            for (let k = 0; k < mei_performance_len; k++) {
              mei_performance[k].haoka.cat = cat;
            }
          }
        }
      }
    },
    jine_haoka_list_methods_total_real_amount_str(cur_value, pre_value) {
      // 消耗的产品各个支付方式金额总和 [total_method_amount]
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let all_performance = this.jine_haoka_list[i].all_performance;
        let all_performance_len = all_performance.length;
        let guwen_performance = this.jine_haoka_list[i].guwen_performance;
        let guwen_performance_len = guwen_performance.length;
        let mei_performance = this.jine_haoka_list[i].mei_performance;
        let mei_performance_len = mei_performance.length;
        if (cv[i] !== pv[i]) {
          for (let k = 0; k < all_performance_len; k++) {
            all_performance[k].haoka.amount =
              Math.round((cv[i] * 100) / all_performance_len) / 100;
          }
          for (let k = 0; k < guwen_performance_len; k++) {
            guwen_performance[k].haoka.amount =
              Math.round((cv[i] * 100) / guwen_performance_len) / 100;
          }
          for (let k = 0; k < mei_performance_len; k++) {
            mei_performance[k].haoka.amount =
              Math.round((cv[i] * 100) / mei_performance_len) / 100;
          }
        }

        let all_yeji_performance = this.jine_haoka_list[i].all_yeji_performance;
        let all_yeji_performance_len = all_yeji_performance.length;
        let guwen_yeji_performance = this.jine_haoka_list[i].guwen_yeji_performance;
        let guwen_yeji_performance_len = guwen_yeji_performance.length;
        let mei_yeji_performance = this.jine_haoka_list[i].mei_yeji_performance;
        let mei_yeji_performance_len = mei_yeji_performance.length;
        if (cv[i] !== pv[i]) {
          for (let k = 0; k < all_yeji_performance_len; k++) {
            all_yeji_performance[k].yeji.amount =
              Math.round((cv[i] * 100) / all_yeji_performance_len) / 100;
          }
          for (let k = 0; k < guwen_yeji_performance_len; k++) {
            guwen_yeji_performance[k].yeji.amount =
              Math.round((cv[i] * 100) / guwen_yeji_performance_len) / 100;
          }
          for (let k = 0; k < mei_yeji_performance_len; k++) {
            mei_yeji_performance[k].yeji.amount =
              Math.round((cv[i] * 100) / mei_yeji_performance_len) / 100;
          }
        }
      }
    },
    jine_haoka_list_method_amount_str(cur_value, pre_value) {
      // 消耗的产品各个支付方式总金额amount，用来更新real_amount(根据ratio)
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_methods = cv[i];
        let pre_methods = pv[i];
        let method_len =
          cur_methods.length > pre_methods.length
            ? pre_methods.length
            : cur_methods.length;
        for (let j = 0; j < method_len; j++) {
          if (cur_methods[j] !== pre_methods[j]) {
            let ratio = this.jine_haoka_list[i]["jine_methods"][j].ratio;
            this.jine_haoka_list[i]["jine_methods"][j].real_amount =
              this.jine_haoka_list[i]["jine_methods"][j].amount * ratio;
          }
        }
      }
    },
    jine_haoka_list_cnt_amount_str(cur_value, pre_value) {
      // [{ product_id, cnt, amount }, {}];
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let all_performance = this.jine_haoka_list[i].all_performance;
        let all_performance_len = all_performance.length;
        let guwen_performance = this.jine_haoka_list[i].guwen_performance;
        let guwen_performance_len = guwen_performance.length;
        let mei_performance = this.jine_haoka_list[i].mei_performance;
        let mei_performance_len = mei_performance.length;
        if (cv[i].amount !== pv[i].amount) {
          let method_len = this.jine_haoka_list[i].jine_methods.length;
          let pre_amount = 0;
          if (method_len > 1) {
            pre_amount = this.jine_haoka_list[i].jine_methods.reduce(
              (acc, cur, ci) => (ci < method_len - 1 ? acc + cur.amount : acc),
              0
            );
          }
          this.$set(
            this.jine_haoka_list[i].jine_methods[method_len - 1],
            "amount",
            cv[i].amount - pre_amount
          );
        }
        let xiang_ratio = this.jine_haoka_list[i].product
          ? this.jine_haoka_list[i].product.xiang_ratio
          : null;
        xiang_ratio = xiang_ratio ? xiang_ratio : 0;
        if (cv[i].cnt !== pv[i].cnt && !(xiang_ratio < 0)) {
          for (let k = 0; k < all_performance_len; k++) {
            all_performance[k].xiangshu.amount =
              Math.round((cv[i].cnt * 100 * xiang_ratio) / all_performance_len) / 100;
          }
          for (let k = 0; k < guwen_performance_len; k++) {
            guwen_performance[k].xiangshu.amount =
              Math.round((cv[i].cnt * 100 * xiang_ratio) / guwen_performance_len) / 100;
          }
          for (let k = 0; k < mei_performance_len; k++) {
            mei_performance[k].xiangshu.amount =
              Math.round((cv[i].cnt * 100 * xiang_ratio) / mei_performance_len) / 100;
          }
        }
        if (cv[i].product_id !== pv[i].product_id) {
          let product_id = cv[i].product_id;
          let product = this.materials.filter((v) => v.value === product_id);
          product = product.length > 0 ? product[0] : {};
          let default_haocat_id = product.default_haocat_id;
          let cat = this.haoka_cats.filter((v) => v.value === default_haocat_id);
          cat = cat.length > 0 ? cat[0] : null;
          this.jine_haoka_list[i].default_cat = cat;
          if (product.special_product === 0 || product.special_product === 2) {
            this.jine_haoka_list[i].special_product = product.special_product;
          } else {
            this.jine_haoka_list[i].special_product = null;
          }
        }
        if (cv[i].product_id !== pv[i].product_id || cv[i].cnt !== pv[i].cnt) {
          let product_id = cv[i].product_id;
          let product = this.materials.filter((v) => v.value === product_id);
          product = product.length > 0 ? product[0] : {};
          for (let k = 0; k < mei_performance_len; k++) {
            let shougong = null;
            if (product.shougong) {
              shougong = product.shougong;
            }
            if (shougong) {
              if (xiang_ratio >= 0) {
                mei_performance[k].shougong[0].amount =
                  shougong * mei_performance[k].xiangshu.amount;
              }
            }
          }
        }
      }
    },
    jine_haoka_list_default_yeji_cat_str(cur_value, pre_value) {
      // [{ product_id, cnt, amount }, {}];
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let all_yeji_performance = this.jine_haoka_list[i].all_yeji_performance;
        let all_yeji_performance_len = all_yeji_performance.length;
        let guwen_yeji_performance = this.jine_haoka_list[i].guwen_yeji_performance;
        let guwen_yeji_performance_len = guwen_yeji_performance.length;
        let mei_yeji_performance = this.jine_haoka_list[i].mei_yeji_performance;
        let mei_yeji_performance_len = mei_yeji_performance.length;
        if (cv[i].default_yeji_cat_id !== pv[i].default_yeji_cat_id) {
          let default_yeji_cat_id = cv[i].default_yeji_cat_id;
          let cat = this.xiaofei_cats.filter((v) => v.value === default_yeji_cat_id);
          cat = cat.length > 0 ? cat[0] : null;
          for (let k = 0; k < all_yeji_performance_len; k++) {
            all_yeji_performance[k].yeji.cat = cat;
          }
          for (let k = 0; k < guwen_yeji_performance_len; k++) {
            guwen_yeji_performance[k].yeji.cat = cat;
          }
          for (let k = 0; k < mei_yeji_performance_len; k++) {
            mei_yeji_performance[k].yeji.cat = cat;
          }
        }
      }
    },
    jine_haoka_list_default_cat_str(cur_value, pre_value) {
      // [{ product_id, cnt, amount }, {}];
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let all_performance = this.jine_haoka_list[i].all_performance;
        let all_performance_len = all_performance.length;
        let guwen_performance = this.jine_haoka_list[i].guwen_performance;
        let guwen_performance_len = guwen_performance.length;
        let mei_performance = this.jine_haoka_list[i].mei_performance;
        let mei_performance_len = mei_performance.length;
        if (cv[i].default_cat_id !== pv[i].default_cat_id) {
          let default_haocat_id = cv[i].default_cat_id;
          let cat = this.haoka_cats.filter((v) => v.value === default_haocat_id);
          cat = cat.length > 0 ? cat[0] : null;
          for (let k = 0; k < all_performance_len; k++) {
            all_performance[k].haoka.cat = cat;
          }
          for (let k = 0; k < guwen_performance_len; k++) {
            guwen_performance[k].haoka.cat = cat;
          }
          for (let k = 0; k < mei_performance_len; k++) {
            mei_performance[k].haoka.cat = cat;
          }
        }
      }
    },
    tui_sub_products() {
      for (let i = 0; i < this.tui_customer_products.length; i++) {
        let product = this.tui_customer_products[i];
        let sub_products = product.sub_products;
        if (sub_products) {
          let return_cnt = sub_products.reduce(
            (acc, cur) => acc + (cur.cnt ? cur.cnt : 0),
            0
          );
          let return_amount = sub_products.reduce(
            (acc, cur) => acc + (cur.price ? cur.price : 0),
            0
          );
          let return_amount_free = sub_products.reduce(
            (acc, cur) => acc + (cur.price_free ? cur.price_free : 0),
            0
          );
          this.tui_customer_products[i].return_cnt = return_cnt;
          this.tui_customer_products[i].return_amount = return_amount;
          this.tui_customer_products[i].return_amount_free = return_amount_free;
        }
      }
    },
    tui_customer_products_amounts() {
      // 自动填充最后一个支付方式的金额
      for (let i = 0; i < this.tui_customer_products.length; i++) {
        let product = this.tui_customer_products[i];
        let return_amount = product.return_amount ? product.return_amount : 0;
        let return_amount_free = product.return_amount_free
          ? product.return_amount_free
          : 0;
        let all_method_amount = product.methods
          .slice(0, product.methods.length - 1)
          .reduce((acc, cur) => {
            let amount = 0;
            if (
              product.show_tuikaxuka_func === 1 ||
              !cur.method ||
              cur.method.payment_type !== 4
            ) {
              amount = cur.amount ? cur.amount : 0;
            }
            return acc + amount;
          }, 0);
        let all_method_amount_free = product.methods
          .slice(0, product.methods.length - 1)
          .reduce((acc, cur) => {
            let amount_free = 0;
            if (product.show_tuikaxuka_func === -1) {
              if (cur.method && cur.method.payment_type === 4) {
                amount_free = cur.amount ? cur.amount : 0;
              } else if (cur.method && cur.method.payment_type === 1) {
                amount_free = cur.amount_free ? cur.amount_free : 0;
              }
            }
            return acc + amount_free;
          }, 0);
        let last_amount = return_amount - all_method_amount;
        let last_amount_free = return_amount_free - all_method_amount_free;
        let last_idx = this.tui_customer_products[i].methods.length - 1;
        let method = this.tui_customer_products[i].methods[last_idx].method;
        if (product.show_tuikaxuka_func === -1 && method && method.payment_type === 1) {
          // 余额 方式
          this.tui_customer_products[i].methods[last_idx].amount = last_amount;
          this.tui_customer_products[i].methods[last_idx].amount_free = last_amount_free;
        } else {
          this.tui_customer_products[i].methods[last_idx].amount =
            last_amount + last_amount_free;
          this.tui_customer_products[i].methods[last_idx].amount_free = null;
        }
      }
    },
    tui_customer_products_methods(cur_value, pre_value) {
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      let dao_amount_enabled = this.$store.state.dao_amount_enabled;
      for (let i = 0; i < len; i++) {
        let cur_methods = cv[i];
        let pre_methods = pv[i];
        let method_len =
          cur_methods.length > pre_methods.length
            ? pre_methods.length
            : cur_methods.length;
        let dao_ratio = this.$store.state.dao_amount_enabled
          ? this.tui_customer_products[i].dao_ratio
          : 100;
        let show_tuikaxuka_func = this.tui_customer_products[i].show_tuikaxuka_func;
        for (let j = 0; j < method_len; j++) {
          if (cur_methods[j][0] !== pre_methods[j][0]) {
            // 支付方式变动
            this.tui_customer_products[i].methods[j].jine_product = null;
            this.tui_customer_products[i].methods[
              j
            ].real_amount = this.tui_customer_products[i].methods[j].amount;
            this.tui_customer_products[i].methods[j].default_cat = null;
            if (cur_methods[j][0] === 0) {
              this.tui_customer_products[i].methods[j].dao_amount =
                Math.round(this.tui_customer_products[i].methods[j].amount * dao_ratio) /
                100;
              let t = this.xiaofei_cats.filter(
                (v) => v.id === this.tui_customer_products[i].default_yecat_id
              );
              if (t.length === 1) {
                this.tui_customer_products[i].methods[j].default_cat = t[0];
              }
            } else {
              this.tui_customer_products[i].methods[j].default_cat = null;
              this.tui_customer_products[i].methods[
                j
              ].dao_amount = this.tui_customer_products[i].methods[j].amount;
            }
          }
          if (cur_methods[j][1] !== pre_methods[j][1]) {
            // 金额变动
            let jine_product = this.tui_customer_products[i].methods[j].jine_product;
            if (cur_methods[j][0] === 1 && jine_product) {
              // 支付方式为余额，且选了金额卡
              if (
                !jine_product.is_free &&
                jine_product.left_price_free &&
                show_tuikaxuka_func === 1
              ) {
                // 支付方式为余额；且为续卡
                let ratio =
                  jine_product.left_price /
                  (jine_product.left_price + jine_product.left_price_free);
                let real_amount = this.tui_customer_products[i].methods[j].amount * ratio;
                this.tui_customer_products[i].methods[j].real_amount = Math.round(
                  real_amount
                );
              } else {
                this.tui_customer_products[i].methods[
                  j
                ].real_amount = this.tui_customer_products[i].methods[j].amount;
              }
            } else {
              this.tui_customer_products[i].methods[
                j
              ].real_amount = this.tui_customer_products[i].methods[j].amount;
            }
            this.tui_customer_products[i].methods[j].dao_amount =
              Math.round(
                this.tui_customer_products[i].methods[j].real_amount * dao_ratio
              ) / 100;
            if (dao_amount_enabled !== 1) {
              let real_amount;
              if (cur_methods[j][0] === 0) {
                real_amount =
                  Math.round(
                    this.tui_customer_products[i].methods[j].real_amount * dao_ratio
                  ) / 100;
              } else {
                real_amount = this.tui_customer_products[i].methods[j].real_amount;
              }
              //let real_amount = this.tui_customer_products[i].methods[j].real_amount;
              let per_len;
              per_len = this.tui_customer_products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].all_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
              per_len = this.tui_customer_products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].guwen_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
              per_len = this.tui_customer_products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].mei_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
            }
          }
          if (cur_methods[j][2] !== pre_methods[j][2] && show_tuikaxuka_func === 1) {
            // 金额卡变动，且为续卡
            let jine_product = this.tui_customer_products[i].methods[j].jine_product;
            if (jine_product) {
              //if (!jine_product.is_free && jine_product.left_price_free) {
              let ratio =
                jine_product.left_price /
                (jine_product.left_price + jine_product.left_price_free);
              let real_amount = this.tui_customer_products[i].methods[j].amount * ratio;
              this.tui_customer_products[i].methods[j].real_amount = Math.round(
                real_amount
              );
            } else {
              this.tui_customer_products[i].methods[
                j
              ].real_amount = this.tui_customer_products[i].methods[j].amount;
            }
            this.tui_customer_products[i].methods[j].dao_amount =
              Math.round(
                this.tui_customer_products[i].methods[j].real_amount * dao_ratio
              ) / 100;
            if (dao_amount_enabled !== 1) {
              let real_amount = this.tui_customer_products[i].methods[j].real_amount;
              let per_len;
              per_len = this.tui_customer_products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].all_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
              per_len = this.tui_customer_products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].guwen_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
              per_len = this.tui_customer_products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].mei_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
            }
          }
          if (cur_methods[j][3] !== pre_methods[j][3]) {
            // dao_amount 变动
            if (dao_amount_enabled === 1) {
              let real_amount = this.tui_customer_products[i].methods[j].dao_amount;
              let per_len;
              per_len = this.tui_customer_products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].all_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
              per_len = this.tui_customer_products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].guwen_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
              per_len = this.tui_customer_products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.tui_customer_products[i].methods[j].mei_performance[
                    k
                  ].yeji.amount = real_amount / per_len;
                }
              }
            }
          }
        }
      }
    },
    tui_customer_products_guwen_users(cur_value, pre_value) {
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_user_ids = cv[i];
        let pre_user_ids = pv[i];
        if (JSON.stringify(cur_user_ids) !== JSON.stringify(pre_user_ids)) {
          let method_len = this.tui_customer_products[i].methods.length;
          for (let j = 0; j < method_len; j++) {
            if (cur_user_ids.length === 0) {
              cur_user_ids = [null];
            }
            let cur_len = cur_user_ids.length;
            let method_amount = this.tui_customer_products[i].methods[j].amount;
            if (
              this.$store.state.dao_amount_enabled === 1 &&
              this.tui_customer_products[i].methods[j].method &&
              this.tui_customer_products[i].methods[j].method.payment_type === 0
            ) {
              method_amount = this.tui_customer_products[i].methods[j].dao_amount;
            }
            let default_cat = this.tui_customer_products[i].methods[j].default_cat;
            this.tui_customer_products[i].methods[j].guwen_performance = cur_user_ids.map(
              (user_id) => {
                return {
                  user:
                    user_id || user_id === 0
                      ? this.userlist.filter((u) => u.value === user_id)[0]
                      : null,
                  yeji: {
                    cat: default_cat,
                    amount: method_amount / cur_len,
                  },
                  type: "guwen_performance",
                };
              }
            );
          }
        }
      }
    },
    tui_customer_products_mei_users(cur_value, pre_value) {
      console.log(pre_value, cur_value);
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_user_ids = cv[i];
        let pre_user_ids = pv[i];
        if (JSON.stringify(cur_user_ids) !== JSON.stringify(pre_user_ids)) {
          let method_len = this.tui_customer_products[i].methods.length;
          for (let j = 0; j < method_len; j++) {
            if (cur_user_ids.length === 0) {
              cur_user_ids = [null];
            }
            let cur_len = cur_user_ids.length;
            let method_amount = this.tui_customer_products[i].methods[j].amount;
            if (
              this.$store.state.dao_amount_enabled === 1 &&
              this.tui_customer_products[i].methods[j].method &&
              this.tui_customer_products[i].methods[j].method.payment_type === 0
            ) {
              method_amount = this.tui_customer_products[i].methods[j].dao_amount;
            }
            let default_cat = this.tui_customer_products[i].methods[j].default_cat;
            this.tui_customer_products[i].methods[j].mei_performance = cur_user_ids.map(
              (user_id) => {
                return {
                  user:
                    user_id || user_id === 0
                      ? this.userlist.filter((u) => u.value === user_id)[0]
                      : null,
                  yeji: {
                    cat: default_cat,
                    amount: method_amount / cur_len,
                  },
                  type: "mei_performance",
                };
              }
            );
          }
        }
      }
    },
    tui_customer_products_default_cats(cur_value, pre_value) {
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      console.log("default cat changed");
      for (let i = 0; i < len; i++) {
        let cur_cats = cv[i];
        let pre_cats = pv[i];
        let cat_len =
          cur_cats.length > pre_cats.length ? pre_cats.length : cur_cats.length;
        for (let j = 0; j < cat_len; j++) {
          if (cur_cats[j] !== pre_cats[j]) {
            if (this.tui_customer_products[i].methods[j].default_cat) {
              this.tui_customer_products[i].methods[j].has_performance = true;
            } else {
              this.tui_customer_products[i].methods[j].has_performance = false;
            }
            this.tui_customer_products[i].methods[j];
            let per_len;
            per_len = this.tui_customer_products[i].methods[j].all_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.tui_customer_products[i].methods[j].all_performance[
                k
              ].yeji.cat = this.tui_customer_products[i].methods[j].default_cat;
            }
            per_len = this.tui_customer_products[i].methods[j].guwen_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.tui_customer_products[i].methods[j].guwen_performance[
                k
              ].yeji.cat = this.tui_customer_products[i].methods[j].default_cat;
            }
            per_len = this.tui_customer_products[i].methods[j].mei_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.tui_customer_products[i].methods[j].mei_performance[
                k
              ].yeji.cat = this.tui_customer_products[i].methods[j].default_cat;
            }
          }
        }
      }
    },
    xiaofei_products_amounts() {
      for (let i = 0; i < this.xiaofei_products.length; i++) {
        let xiaofei_product_item = this.xiaofei_products[i];
        let amount = xiaofei_product_item.amount ? xiaofei_product_item.amount : 0;
        let method_amounts = xiaofei_product_item.methods.map((v) =>
          v.amount ? v.amount : 0
        );
        if (method_amounts.length === 0) {
          continue;
        }
        let all_method_amount = method_amounts
          .slice(0, method_amounts.length - 1)
          .reduce((acc, cur) => acc + cur, 0);
        let last_amount = amount - all_method_amount;
        let last_idx = this.xiaofei_products[i].methods.length - 1;
        this.xiaofei_products[i].methods[last_idx].amount = last_amount;
      }
    },
    xiaofei_products_real_amounts(cur_value, pre_value) {
      // 产品的 real_amount 总和发生变化
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        if (cv[i] === pv[i]) {
          continue;
        }
        let xiaofei_product_item = this.xiaofei_products[i];
        let product = xiaofei_product_item["product"];
        if (!product) {
          continue;
        }
        //let cat = null;
        //let default_haocat_id = product.default_haocat_id;
        //let cats = this.haoka_cats.filter((v) => v.value === default_haocat_id);
        //cat = cats.length > 0 ? cats[0] : null;
        let default_xiang_ratio = product.main_type === 0 ? 0 : 1;
        let xiang_ratio =
          product.xiang_ratio || product.xiang_ratio === 0
            ? product.xiang_ratio
            : default_xiang_ratio;
        let xiang_amount = xiang_ratio < 0 ? 1 : xiang_ratio * xiaofei_product_item.cnt;
        let xiaohao_amount = JSON.parse(this.xiaofei_products_real_amounts)[i];
        let shougong =
          (product.shougong ? product.shougong : 0) * (xiang_ratio ? xiang_ratio : 1);
        for (let ii = 0; ii < 3; ii++) {
          let perf = ["all_performance", "guwen_performance", "mei_performance"][ii];
          let performance = xiaofei_product_item[perf];
          let len = performance.length;
          for (let k = 0; k < len; k++) {
            performance[k]["haoka"]["amount"] = xiaohao_amount / len;
            performance[k]["xiangshu"]["amount"] = xiang_amount / len;
            if (perf === "mei_performance") {
              for (let m = 0; m < performance[k]["shougong"].length; m++) {
                performance[k]["shougong"][m]["amount"] = shougong / len;
              }
            }
          }
        }
      }
    },
    xiaofei_products_methods(cur_value, pre_value) {
      // 支付方式，金额，金额卡
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      let dao_amount_enabled = this.$store.state.dao_amount_enabled;
      for (let i = 0; i < len; i++) {
        if (!this.xiaofei_products[i].product) {
          continue;
        }
        let cur_methods = cv[i];
        let pre_methods = pv[i];
        let method_len =
          cur_methods.length > pre_methods.length
            ? pre_methods.length
            : cur_methods.length;
        let dao_ratio = this.$store.state.dao_amount_enabled
          ? this.xiaofei_products[i].product.dao_ratio
          : 100;
        for (let j = 0; j < method_len; j++) {
          if (cur_methods[j][0] !== pre_methods[j][0]) {
            // 支付方式变动，金额卡变为空，real_amount 设为 amount
            this.xiaofei_products[i].methods[j].jine_product = null;
            this.xiaofei_products[i].methods[j].real_amount = this.xiaofei_products[
              i
            ].methods[j].amount;

            if (cur_methods[j][0] === 0) {
              this.xiaofei_products[i].methods[j].dao_amount =
                Math.round(this.xiaofei_products[i].methods[j].amount * dao_ratio) / 100;
              let t = this.xiaofei_cats.filter(
                (v) => v.id === this.xiaofei_products[i].product.default_yecat_id
              );
              if (t.length === 1) {
                this.xiaofei_products[i].methods[j].default_cat = t[0];
              }
            } else {
              this.xiaofei_products[i].methods[j].default_cat = null;
              this.xiaofei_products[i].methods[j].dao_amount = this.xiaofei_products[
                i
              ].methods[j].amount;
            }
          }
          if (cur_methods[j][1] !== pre_methods[j][1]) {
            // 金额变动
            let jine_product = this.xiaofei_products[i].methods[j].jine_product;
            if (cur_methods[j][0] === 1 && jine_product) {
              // 支付方式为余额 且 选了金额卡
              let ratio =
                jine_product.left_price /
                (jine_product.left_price + jine_product.left_price_free);
              let real_amount = this.xiaofei_products[i].methods[j].amount * ratio;
              let free_amount = this.xiaofei_products[i].methods[j].amount - real_amount;
              free_amount = Math.round(free_amount * 100) / 100;
              if (free_amount === jine_product.left_price_free) {
                this.xiaofei_products[i].methods[j].real_amount =
                  Math.round(real_amount * 100) / 100;
              } else {
                this.xiaofei_products[i].methods[j].real_amount = Math.round(real_amount);
              }
            } else {
              this.xiaofei_products[i].methods[j].real_amount = this.xiaofei_products[
                i
              ].methods[j].amount;
            }
            this.xiaofei_products[i].methods[j].dao_amount =
              Math.round(this.xiaofei_products[i].methods[j].real_amount * dao_ratio) /
              100;
            if (dao_amount_enabled !== 1) {
              let real_amount;
              if (cur_methods[j][0] === 0) {
                real_amount =
                  Math.round(
                    this.xiaofei_products[i].methods[j].real_amount * dao_ratio
                  ) / 100;
              } else {
                real_amount = this.xiaofei_products[i].methods[j].real_amount;
              }
              let per_len;
              per_len = this.xiaofei_products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].all_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.xiaofei_products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].guwen_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.xiaofei_products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].mei_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
            }
          }
          if (cur_methods[j][2] !== pre_methods[j][2]) {
            // 金额卡变动
            let jine_product = this.xiaofei_products[i].methods[j].jine_product;
            if (jine_product) {
              let ratio =
                jine_product.left_price /
                (jine_product.left_price + jine_product.left_price_free);
              let real_amount = this.xiaofei_products[i].methods[j].amount * ratio;
              let free_amount =
                Math.round(
                  (this.xiaofei_products[i].methods[j].amount - real_amount) * 100
                ) / 100;
              if (free_amount === jine_product.left_price_free) {
                this.xiaofei_products[i].methods[j].real_amount =
                  Math.round(real_amount * 100) / 100;
              } else {
                this.xiaofei_products[i].methods[j].real_amount = Math.round(real_amount);
              }
            } else {
              this.xiaofei_products[i].methods[j].real_amount = this.xiaofei_products[
                i
              ].methods[j].amount;
            }
            this.xiaofei_products[i].methods[j].dao_amount =
              Math.round(this.xiaofei_products[i].methods[j].real_amount * dao_ratio) /
              100;
            if (dao_amount_enabled !== 1) {
              let real_amount = this.xiaofei_products[i].methods[j].real_amount;
              let per_len;
              per_len = this.xiaofei_products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].all_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.xiaofei_products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].guwen_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.xiaofei_products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].mei_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
            }
          }
          if (cur_methods[j][3] !== pre_methods[j][3]) {
            // dao_amount 变动
            if (dao_amount_enabled === 1) {
              let real_amount = this.xiaofei_products[i].methods[j].dao_amount;
              let per_len;
              per_len = this.xiaofei_products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].all_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.xiaofei_products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].guwen_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.xiaofei_products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.xiaofei_products[i].methods[j].mei_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
            }
          }
        }
      }
    },
    xiaofei_products_methods_free(cur_value, pre_value) {
      if (this.$store.state.special_func !== 1) {
        return;
      }
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_is_free = cv[i];
        let pre_is_free = pv[i];
        if (cur_is_free !== pre_is_free) {
          if (cur_is_free) {
            this.xiaofei_products[i]["special_product"] = 2;
          } else {
            this.xiaofei_products[i]["special_product"] = this.xiaofei_products[
              i
            ].product["special_product"];
          }
        }
      }
    },
    xiaofei_products_guwen_users(cur_value, pre_value) {
      // 上面的顾问
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_user_ids = cv[i];
        let pre_user_ids = pv[i];
        if (cur_user_ids.length === 0) {
          cur_user_ids = [null];
        }
        let cur_len = cur_user_ids.length;
        if (JSON.stringify(cur_user_ids) !== JSON.stringify(pre_user_ids)) {
          let method_len = this.xiaofei_products[i].methods.length;
          let xiaohao_amount = 0;

          for (let j = 0; j < method_len; j++) {
            let method_amount = this.xiaofei_products[i].methods[j].amount;
            if (
              this.$store.state.dao_amount_enabled === 1 &&
              this.xiaofei_products[i].methods[j].method.payment_type === 0
            ) {
              method_amount = this.xiaofei_products[i].methods[j].dao_amount;
            }
            let default_cat = this.xiaofei_products[i].methods[j].default_cat;
            if (this.xiaofei_products[i].methods[j].method.payment_type === 1) {
              // 余额
              if (this.xiaofei_products[i].methods[j].jine_product) {
                xiaohao_amount =
                  xiaohao_amount +
                  this.xiaofei_products[i].methods[j].jine_product.real_amount;
              } else {
                xiaohao_amount =
                  xiaohao_amount + this.xiaofei_products[i].methods[j].amount;
              }
            } else if (this.xiaofei_products[i].methods[j].method.payment_type !== 4) {
              xiaohao_amount =
                xiaohao_amount + this.xiaofei_products[i].methods[j].amount;
            }
            this.xiaofei_products[i].methods[j].guwen_performance = cur_user_ids.map(
              (user_id) => {
                return {
                  user:
                    user_id || user_id === 0
                      ? this.userlist.filter((u) => u.value === user_id)[0]
                      : null,
                  yeji: {
                    cat: default_cat,
                    amount: method_amount / cur_len,
                  },
                  type: "guwen_performance",
                };
              }
            );
          }
          if (this.$store.state.enable_guwen_xiaohao) {
            let product = this.xiaofei_products[i].product;
            let cat = null;
            let default_haocat_id = product.default_haocat_id;
            let cats = this.haoka_cats.filter((v) => v.value === default_haocat_id);
            cat = cats.length > 0 ? cats[0] : null;
            let default_xiang_ratio = product.main_type === 0 ? 0 : 1;
            let xiang_ratio =
              product.xiang_ratio || product.xiang_ratio === 0
                ? product.xiang_ratio
                : default_xiang_ratio;
            let xiang_amount = xiang_ratio < 0 ? 1 : xiang_ratio;
            this.xiaofei_products[i].guwen_performance = cur_user_ids.map((user_id) => {
              let user =
                user_id || user_id === 0
                  ? this.userlist.filter((u) => u.value === user_id)[0]
                  : null;
              return {
                user,
                haoka: {
                  cat: cat,
                  amount: xiaohao_amount / cur_len,
                },
                xiangshu: {
                  cat: this.xiangshu_cats[0],
                  amount: xiang_amount / cur_len,
                },
                shougong: [],
              };
            });
          }
        }
      }
    },
    xiaofei_products_mei_users(cur_value, pre_value) {
      // 上面的美容师
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_user_ids = cv[i];
        let pre_user_ids = pv[i];
        if (cur_user_ids.length === 0) {
          cur_user_ids = [null];
        }
        let cur_len = cur_user_ids.length;
        if (JSON.stringify(cur_user_ids) !== JSON.stringify(pre_user_ids)) {
          let method_len = this.xiaofei_products[i].methods.length;
          let xiaohao_amount = 0;
          for (let j = 0; j < method_len; j++) {
            let method_amount = this.xiaofei_products[i].methods[j].amount;
            if (
              this.$store.state.dao_amount_enabled === 1 &&
              this.xiaofei_products[i].methods[j].method.payment_type === 0
            ) {
              method_amount = this.xiaofei_products[i].methods[j].dao_amount;
            }
            let default_cat = this.xiaofei_products[i].methods[j].default_cat;
            if (this.xiaofei_products[i].methods[j].method.payment_type === 1) {
              // 余额
              if (this.xiaofei_products[i].methods[j].jine_product) {
                xiaohao_amount =
                  xiaohao_amount +
                  this.xiaofei_products[i].methods[j].jine_product.real_amount;
              } else {
                xiaohao_amount =
                  xiaohao_amount + this.xiaofei_products[i].methods[j].amount;
              }
            } else if (this.xiaofei_products[i].methods[j].method.payment_type !== 4) {
              xiaohao_amount =
                xiaohao_amount + this.xiaofei_products[i].methods[j].amount;
            }
            this.xiaofei_products[i].methods[j].mei_performance = cur_user_ids.map(
              (user_id) => {
                return {
                  user:
                    user_id || user_id === 0
                      ? this.userlist.filter((u) => u.value === user_id)[0]
                      : null,
                  yeji: {
                    cat: default_cat,
                    amount: method_amount / cur_len,
                  },
                  type: "mei_performance",
                };
              }
            );
          }
          let product = this.xiaofei_products[i].product;
          let cat = null;
          let default_haocat_id = product.default_haocat_id;
          let cats = this.haoka_cats.filter((v) => v.value === default_haocat_id);
          cat = cats.length > 0 ? cats[0] : null;
          console.log("product: ", product);
          console.log("cat: ", cat);
          let default_xiang_ratio = product.main_type === 0 ? 0 : 1;
          let xiang_ratio =
            product.xiang_ratio || product.xiang_ratio === 0
              ? product.xiang_ratio
              : default_xiang_ratio;
          let xiang_amount = xiang_ratio < 0 ? 1 : xiang_ratio;
          this.xiaofei_products[i].mei_performance = cur_user_ids.map((user_id) => {
            let user =
              user_id || user_id === 0
                ? this.userlist.filter((u) => u.value === user_id)[0]
                : null;
            return {
              user,
              haoka: {
                cat: cat,
                amount: xiaohao_amount / cur_len,
              },
              xiangshu: {
                cat: this.xiangshu_cats[0],
                amount: xiang_amount / cur_len,
              },
              shougong: this.shougong_cats.map((v) => {
                return { cat: v, amount: product.shougong / cur_len };
              }),
            };
          });
        }
      }
    },
    xiaofei_products_default_cats(cur_value, pre_value) {
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_cats = cv[i];
        let pre_cats = pv[i];
        let cat_len =
          cur_cats.length > pre_cats.length ? pre_cats.length : cur_cats.length;
        for (let j = 0; j < cat_len; j++) {
          if (cur_cats[j] !== pre_cats[j]) {
            if (this.xiaofei_products[i].methods[j].default_cat) {
              this.xiaofei_products[i].methods[j].has_performance = true;
            } else {
              this.xiaofei_products[i].methods[j].has_performance = false;
            }
            this.xiaofei_products[i].methods[j];
            let per_len;
            per_len = this.xiaofei_products[i].methods[j].all_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.xiaofei_products[i].methods[j].all_performance[
                k
              ].yeji.cat = this.xiaofei_products[i].methods[j].default_cat;
            }
            per_len = this.xiaofei_products[i].methods[j].guwen_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.xiaofei_products[i].methods[j].guwen_performance[
                k
              ].yeji.cat = this.xiaofei_products[i].methods[j].default_cat;
            }
            per_len = this.xiaofei_products[i].methods[j].mei_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.xiaofei_products[i].methods[j].mei_performance[
                k
              ].yeji.cat = this.xiaofei_products[i].methods[j].default_cat;
            }
          }
        }
      }
    },
    xiaofei_products_default_haocats(cur_value, pre_value) {
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < this.xiaofei_products[i]["all_performance"].length; j++) {
          this.xiaofei_products[i]["all_performance"][
            j
          ].haoka.cat = this.xiaofei_products[i].default_cat;
        }
        for (let j = 0; j < this.xiaofei_products[i]["guwen_performance"].length; j++) {
          this.xiaofei_products[i]["guwen_performance"][
            j
          ].haoka.cat = this.xiaofei_products[i].default_cat;
        }
        for (let j = 0; j < this.xiaofei_products[i]["mei_performance"].length; j++) {
          this.xiaofei_products[i]["mei_performance"][
            j
          ].haoka.cat = this.xiaofei_products[i].default_cat;
        }
      }
    },
    arrearage_products_methods(cur_value, pre_value) {
      ////let cv = JSON.parse(cur_value);
      ////let pv = JSON.parse(pre_value);
      ////let cv_len = cv.length;
      ////let pv_len = pv.length;
      ////let len = cv_len > pv_len ? pv_len : cv_len;
      ////for (let i = 0; i < len; i++) {
      ////  let cur_method_amounts = cv[i];
      ////  let pre_method_amounts = pv[i];
      ////  let method_len =
      ////    cur_method_amounts.length > pre_method_amounts.length
      ////      ? pre_method_amounts.length
      ////      : cur_method_amounts.length;
      ////  for (let j = 0; j < method_len; j++) {
      ////    if (cur_method_amounts[j] !== pre_method_amounts[j]) {
      ////      let per_len;
      ////      per_len = this.arrearage.products[i].methods[j].all_performance.length;
      ////      if (per_len > 0) {
      ////        for (let k = 0; k < per_len; k++) {
      ////          this.arrearage.products[i].methods[j].all_performance[k].yeji.amount =
      ////            cur_method_amounts[j] / per_len;
      ////        }
      ////      }
      ////      per_len = this.arrearage.products[i].methods[j].guwen_performance.length;
      ////      if (per_len > 0) {
      ////        for (let k = 0; k < per_len; k++) {
      ////          this.arrearage.products[i].methods[j].guwen_performance[k].yeji.amount =
      ////            cur_method_amounts[j] / per_len;
      ////        }
      ////      }
      ////      per_len = this.arrearage.products[i].methods[j].mei_performance.length;
      ////      if (per_len > 0) {
      ////        for (let k = 0; k < per_len; k++) {
      ////          this.arrearage.products[i].methods[j].mei_performance[k].yeji.amount =
      ////            cur_method_amounts[j] / per_len;
      ////        }
      ////      }
      ////    }
      ////  }
      ////}
      // 支付方式，金额，金额卡
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      let dao_amount_enabled = this.$store.state.dao_amount_enabled;
      for (let i = 0; i < len; i++) {
        let cur_methods = cv[i];
        let pre_methods = pv[i];
        let method_len =
          cur_methods.length > pre_methods.length
            ? pre_methods.length
            : cur_methods.length;
        let dao_ratio = this.$store.state.dao_amount_enabled
          ? this.arrearage.products[i].dao_ratio
          : 100;
        for (let j = 0; j < method_len; j++) {
          if (cur_methods[j][0] !== pre_methods[j][0]) {
            // 支付方式变动，金额卡变为空，real_amount 设为 amount
            this.arrearage.products[i].methods[j].jine_product = null;
            this.arrearage.products[i].methods[j].real_amount = this.arrearage.products[
              i
            ].methods[j].amount;

            if (cur_methods[j][0] === 0) {
              this.arrearage.products[i].methods[j].dao_amount =
                Math.round(this.arrearage.products[i].methods[j].amount * dao_ratio) /
                100;
              let t = this.xiaofei_cats.filter(
                (v) => v.id === this.arrearage.products[i].default_yecat_id
              );
              if (t.length === 1) {
                this.arrearage.products[i].methods[j].default_cat = t[0];
              }
            } else {
              this.arrearage.products[i].methods[j].default_cat = null;
              this.arrearage.products[i].methods[j].dao_amount = this.arrearage.products[
                i
              ].methods[j].amount;
            }
          }
          if (cur_methods[j][1] !== pre_methods[j][1]) {
            // 金额变动
            let jine_product = this.arrearage.products[i].methods[j].jine_product;
            if (cur_methods[j][0] === 1 && jine_product) {
              // 支付方式为余额 且 选了金额卡
              let ratio =
                jine_product.left_price /
                (jine_product.left_price + jine_product.left_price_free);
              let real_amount = this.arrearage.products[i].methods[j].amount * ratio;
              let free_amount =
                this.arrearage.products[i].methods[j].amount - real_amount;
              free_amount = Math.round(free_amount * 100) / 100;
              if (free_amount === jine_product.left_price_free) {
                this.arrearage.products[i].methods[j].real_amount =
                  Math.round(real_amount * 100) / 100;
              } else {
                this.arrearage.products[i].methods[j].real_amount = Math.round(
                  real_amount
                );
              }
            } else {
              this.arrearage.products[i].methods[j].real_amount = this.arrearage.products[
                i
              ].methods[j].amount;
            }
            this.arrearage.products[i].methods[j].dao_amount =
              Math.round(this.arrearage.products[i].methods[j].real_amount * dao_ratio) /
              100;
            if (dao_amount_enabled !== 1) {
              let real_amount;
              if (cur_methods[j][0] === 0) {
                real_amount =
                  Math.round(
                    this.arrearage.products[i].methods[j].real_amount * dao_ratio
                  ) / 100;
              } else {
                real_amount = this.arrearage.products[i].methods[j].real_amount;
              }
              let per_len;
              per_len = this.arrearage.products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].all_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.arrearage.products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].guwen_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.arrearage.products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].mei_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
            }
          }
          if (cur_methods[j][2] !== pre_methods[j][2]) {
            // 金额卡变动
            let jine_product = this.arrearage.products[i].methods[j].jine_product;
            if (jine_product) {
              let ratio =
                jine_product.left_price /
                (jine_product.left_price + jine_product.left_price_free);
              let real_amount = this.arrearage.products[i].methods[j].amount * ratio;
              let free_amount =
                Math.round(
                  (this.arrearage.products[i].methods[j].amount - real_amount) * 100
                ) / 100;
              if (free_amount === jine_product.left_price_free) {
                this.arrearage.products[i].methods[j].real_amount =
                  Math.round(real_amount * 100) / 100;
              } else {
                this.arrearage.products[i].methods[j].real_amount = Math.round(
                  real_amount
                );
              }
            } else {
              this.arrearage.products[i].methods[j].real_amount = this.arrearage.products[
                i
              ].methods[j].amount;
            }
            this.arrearage.products[i].methods[j].dao_amount =
              Math.round(this.arrearage.products[i].methods[j].real_amount * dao_ratio) /
              100;
            if (dao_amount_enabled !== 1) {
              let real_amount = this.arrearage.products[i].methods[j].real_amount;
              let per_len;
              per_len = this.arrearage.products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].all_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.arrearage.products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].guwen_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.arrearage.products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].mei_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
            }
          }
          if (cur_methods[j][3] !== pre_methods[j][3]) {
            // dao_amount 变动
            if (dao_amount_enabled === 1) {
              let real_amount = this.arrearage.products[i].methods[j].dao_amount;
              let per_len;
              per_len = this.arrearage.products[i].methods[j].all_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].all_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.arrearage.products[i].methods[j].guwen_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].guwen_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
              per_len = this.arrearage.products[i].methods[j].mei_performance.length;
              if (per_len > 0) {
                for (let k = 0; k < per_len; k++) {
                  this.arrearage.products[i].methods[j].mei_performance[k].yeji.amount =
                    real_amount / per_len;
                }
              }
            }
          }
        }
      }
    },
    arrearage_products_guwen_users(cur_value, pre_value) {
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_user_ids = cv[i];
        let pre_user_ids = pv[i];
        if (JSON.stringify(cur_user_ids) !== JSON.stringify(pre_user_ids)) {
          let method_len = this.arrearage.products[i].methods.length;
          for (let j = 0; j < method_len; j++) {
            if (cur_user_ids.length === 0) {
              cur_user_ids = [null];
            }
            let cur_len = cur_user_ids.length;
            let method_amount = this.arrearage.products[i].methods[j].amount;
            if (
              this.$store.state.dao_amount_enabled === 1 &&
              this.arrearage.products[i].methods[j].method &&
              this.arrearage.products[i].methods[j].method.payment_type === 0
            ) {
              method_amount = this.arrearage.products[i].methods[j].dao_amount;
            }
            let default_cat = this.arrearage.products[i].methods[j].default_cat;
            this.arrearage.products[i].methods[j].guwen_performance = cur_user_ids.map(
              (user_id) => {
                return {
                  user:
                    user_id || user_id === 0
                      ? this.userlist.filter((u) => u.value === user_id)[0]
                      : null,
                  yeji: {
                    cat: default_cat,
                    amount: method_amount / cur_len,
                  },
                  type: "guwen_performance",
                };
              }
            );
          }
        }
      }
    },
    arrearage_products_mei_users(cur_value, pre_value) {
      console.log(pre_value, cur_value);
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_user_ids = cv[i];
        let pre_user_ids = pv[i];
        if (JSON.stringify(cur_user_ids) !== JSON.stringify(pre_user_ids)) {
          let method_len = this.arrearage.products[i].methods.length;
          for (let j = 0; j < method_len; j++) {
            if (cur_user_ids.length === 0) {
              cur_user_ids = [null];
            }
            let cur_len = cur_user_ids.length;
            let method_amount = this.arrearage.products[i].methods[j].amount;
            if (
              this.$store.state.dao_amount_enabled === 1 &&
              this.arrearage.products[i].methods[j].method &&
              this.arrearage.products[i].methods[j].method.payment_type === 0
            ) {
              method_amount = this.arrearage.products[i].methods[j].dao_amount;
            }
            let default_cat = this.arrearage.products[i].methods[j].default_cat;
            this.arrearage.products[i].methods[j].mei_performance = cur_user_ids.map(
              (user_id) => {
                return {
                  user:
                    user_id || user_id === 0
                      ? this.userlist.filter((u) => u.value === user_id)[0]
                      : null,
                  yeji: {
                    cat: default_cat,
                    amount: method_amount / cur_len,
                  },
                  type: "mei_performance",
                };
              }
            );
          }
        }
      }
    },
    arrearage_products_default_cats(cur_value, pre_value) {
      let cv = JSON.parse(cur_value);
      let pv = JSON.parse(pre_value);
      let cv_len = cv.length;
      let pv_len = pv.length;
      let len = cv_len > pv_len ? pv_len : cv_len;
      for (let i = 0; i < len; i++) {
        let cur_cats = cv[i];
        let pre_cats = pv[i];
        let cat_len =
          cur_cats.length > pre_cats.length ? pre_cats.length : cur_cats.length;
        for (let j = 0; j < cat_len; j++) {
          if (cur_cats[j] !== pre_cats[j]) {
            if (this.arrearage.products[i].methods[j].default_cat) {
              this.arrearage.products[i].methods[j].has_performance = true;
            } else {
              this.arrearage.products[i].methods[j].has_performance = false;
            }
            this.arrearage.products[i].methods[j];
            let per_len;
            per_len = this.arrearage.products[i].methods[j].all_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.arrearage.products[i].methods[j].all_performance[
                k
              ].yeji.cat = this.arrearage.products[i].methods[j].default_cat;
            }
            per_len = this.arrearage.products[i].methods[j].guwen_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.arrearage.products[i].methods[j].guwen_performance[
                k
              ].yeji.cat = this.arrearage.products[i].methods[j].default_cat;
            }
            per_len = this.arrearage.products[i].methods[j].mei_performance.length;
            for (let k = 0; k < per_len; k++) {
              this.arrearage.products[i].methods[j].mei_performance[
                k
              ].yeji.cat = this.arrearage.products[i].methods[j].default_cat;
            }
          }
        }
      }
    },
  },
  methods: {
    calNums(cnt, spec, set_spec = null) {
      return calNums(cnt, spec, set_spec);
    },
    getdocuments() {
      if (!this.corp) {
        return [];
      }
      let corp_id = this.corp.corp_id;
      if (!this.effect_date) {
        return [];
      }
      let effect_date = new Date(this.effect_date).format("yyyy-MM-dd");
      let data = { corp_id, effect_date };
      this.documents;
      let idx = 0;
      getDocuments(data).then((res) => {
        this.documents = res.content.map((v) => {
          if (v.idx > idx) {
            idx = v.idx;
          }
          return v;
        });
        this.idx = idx + 1;
      });
    },
    getcustomercnts() {
      getCustomerCnts({
        effect_date: new Date(this.effect_date).format("yyyy-MM-dd"),
        corp_id: this.corp.corp_id,
      }).then((res) => {
        let corp_id = this.corp.corp_id;
        let user_id_dict = {};
        for (let i = 0; i < this.userlist.length; i++) {
          let user = this.userlist[i];
          let user_id = user["id"];
          user_id_dict[user_id] = user;
        }
        let content = res.content
          .filter(
            (v) =>
              v.corp_id === corp_id &&
              (v.user_id || v.user_id === 0) &&
              v.user_id in user_id_dict
          )
          .map((v) => {
            v.name = user_id_dict[v.user_id]["name"];
            return v;
          });
        let customer_cnts = {};
        for (let i = 0; i < content.length; i++) {
          let item = content[i];
          let customer_id = item.customer_id;
          let customer_name = item.customer_name;
          let key = customer_name + "_" + customer_id;
          if (key in customer_cnts) {
            customer_cnts[key].push(item);
          } else {
            customer_cnts[key] = [item];
          }
        }
        this.customer_cnts = customer_cnts;
      });
    },
    clickmaintype(item) {
      if (this.active_main_type && this.active_main_type.value === item.value) {
        this.active_main_type = null;
      } else {
        this.active_main_type = item;
      }
    },
    clickexhaustedtype(item) {
      if (this.active_exhausted_type && this.active_exhausted_type.value === item.value) {
        this.active_exhausted_type = null;
      } else {
        this.active_exhausted_type = item;
      }
    },
    clickfreetype(item) {
      if (this.active_free_type && this.active_free_type.value === item.value) {
        this.active_free_type = null;
      } else {
        this.active_free_type = item;
      }
    },
    clickexpiredtype(item) {
      if (this.active_expired_type && this.active_expired_type.value === item.value) {
        this.active_expired_type = null;
      } else {
        this.active_expired_type = item;
      }
    },
    clickintertype(item) {
      this.active_inter_type = item;
    },
    getcustomersfs() {
      if (!this.corp) {
        return;
      }
      let corp_id = this.corp.corp_id;
      getCustomersFs({ corp_id }).then((res) => {
        let customers = res.content.sort(
          (a, b) =>
            (a.customer_id ? a.customer_id / Math.abs(a.customer_id) : 0) -
            (b.customer_id ? b.customer_id / Math.abs(b.customer_id) : 0)
        );
        if (customers.length > 0 && customers[0].id !== -1) {
          customers.unshift({
            value: -1,
            label: "亲人朋友",
            id: -1,
            customer_id: -1,
            cur_type: -1,
            name: "亲人朋友",
          });
          this.need_add_qinren = true;
        }
        this.customers = customers;
      });
    },
    getcustomerproducts() {
      getCustomerProducts(this.customer.customer_id, this.corp.corp_id).then((res) => {
        let has_sub_product_ids = [];
        let customer_products = res.content.customer_products.map((v) => {
          v.checked = false;
          if (v.main_type === 2 && v.sub_type === 2) {
            has_sub_product_ids.push(v.id);
          }
          v.value = v.id;
          v.label = v.material_name;
          return v;
        });
        if (has_sub_product_ids.length > 0) {
          getSubProducts({ customer_product_ids: has_sub_product_ids }).then((res) => {
            let sub_products = {};
            for (let i = 0; i < res.content.length; i++) {
              let item = res.content[i];
              let customer_product_id = item["customer_product_id"];
              if (!(customer_product_id in sub_products)) {
                sub_products[customer_product_id] = [];
              }
              sub_products[customer_product_id].push(item);
            }
            this.sub_products = sub_products;
          });
        } else {
          this.sub_products = {};
        }
        let today = new Date(new Date().format("yyyy-MM-dd"));
        let arrearages = res.content.arrearages;
        this.arrearages = arrearages;
        let left_price = 0;
        let left_price_free = 0;
        for (let i = 0; i < customer_products.length; i++) {
          let v = customer_products[i];
          if (
            v.expire_date &&
            new Date(v.expire_date).format("yyyy-MM-dd") < today.format("yyyy-MM-dd")
          ) {
            v.expired = true;
          }
          if (v.left_price && !v.expired) {
            left_price = left_price + v.left_price;
          }
          if (v.left_price_free && !v.expired) {
            left_price_free = left_price_free + v.left_price_free;
          }
          if (
            (v.main_type < 3 &&
              Math.abs(v.left_quantity) < 0.01 &&
              (Math.abs(v.left_price) < 0.01 ||
                (Math.abs(v.left_price_free) < 0.01 && v.is_free))) ||
            (v.main_type >= 3 &&
              Math.abs(v.left_price) < 0.01 &&
              Math.abs(v.left_price_free) < 0.01)
          ) {
            v.exhausted = true;
          } else {
            v.exhausted = false;
          }
          if (v.last_effect_date) {
            v.last_effect_date = new Date(v.last_effect_date);
            let days = (today - v.last_effect_date) / (24 * 60 * 60 * 1000);
            v.days = days;
          }
        }
        this.customer_products = customer_products;
        this.left_price = left_price;
        this.left_price_free = left_price_free;
      });
    },
    getcustomerstorematerialleftcnts() {
      let customer_id = this.customer.value;
      let data = { customer_id };
      getCustomerStoreMaterialLeftCnts(data).then((res) => {
        this.store_material_leftcnts = res.content;
      });
    },
    xiaohao(item) {
      this.add_type = null;
      let v = JSON.parse(JSON.stringify(item));
      if (v.main_type < 3) {
        let product = v;
        product.remark = null;
        let cat = null;
        let default_haocat_id = product.default_haocat_id;
        let cats = this.haoka_cats.filter((v) => v.value === default_haocat_id);
        cat = cats.length > 0 ? cats[0] : null;
        let default_xiang_ratio = product.main_type === 0 ? 0 : 1;
        let xiang_ratio =
          product.xiang_ratio || product.xiang_ratio === 0
            ? product.xiang_ratio
            : default_xiang_ratio;
        let xiang_amount = xiang_ratio < 0 ? 1 : xiang_ratio;
        let all_performance = [
          {
            haoka: {
              cat: cat,
              amount:
                Math.round((product.left_price_4hao / product.left_quantity) * 100) / 100,
            },
            xiangshu: {
              cat: this.xiangshu_cats[0],
              amount: xiang_amount,
            },
          },
        ];
        let guwen_performance = [
          {
            user: this.default_guwen,
            haoka: {
              cat: cat,
              amount:
                Math.round((product.left_price_4hao / product.left_quantity) * 100) / 100,
            },
            xiangshu: {
              cat: this.xiangshu_cats[0],
              amount: xiang_amount,
            },
            shougong: [],
          },
        ];
        let mei_performance = [
          {
            user: this.default_mei,
            haoka: {
              cat: cat,
              amount:
                Math.round((product.left_price_4hao / product.left_quantity) * 100) / 100,
            },
            shougong: this.shougong_cats.map((v) => {
              return { cat: v, amount: product.shougong };
            }),
            xiangshu: {
              cat: this.xiangshu_cats[0],
              amount: xiang_amount,
            },
          },
        ];
        if (v.main_type === 0 || v.main_type === 1) {
          // 产品或项目
          let relate_material_outin = 0;
          if (product.main_type === 0 && product.need_manage) {
            relate_material_outin = 2;
          }
          product["haoka_list"] = [
            {
              customer: {
                value: this.customer.customer_id,
                label: this.customer.name,
                id: this.customer.customer_id,
                name: this.customer.name,
                cur_type: this.customer.cur_type,
              },
              customer_name: null,
              product: {
                value: product.material_id,
                label: product.material_name,
                unitname: product.unitname,
                material_id: product.material_id,
                material_name: product.material_name,
                default_haocat_id: product.default_haocat_id,
                default_shoucat_id: product.default_shoucat_id,
                shougong: product.shougong,
              },
              real_product: null,
              special_product:
                product.special_product === 1 ? null : product.special_product,
              cnt: 1,
              relate_material_outin,
              store_id: null,
              material_outins: [],
              default_cat: cat,
              all_performance: all_performance,
              guwen_performance: guwen_performance,
              mei_performance: mei_performance,
            },
          ];
        } else if (v.main_type === 2) {
          // 套卡
          let relate_material_outin = 0;
          product["haoka_list"] = [
            {
              customer: {
                value: this.customer.customer_id,
                label: this.customer.name,
                id: this.customer.customer_id,
                name: this.customer.name,
                cur_type: this.customer.cur_type,
              },
              customer_name: null,
              product: null,
              real_product: null,
              special_product:
                product.special_product === 1 ? null : product.special_product,
              cnt: 1,
              relate_material_outin,
              store_id: null,
              material_outins: [],
              default_cat: cat,
              all_performance: all_performance,
              guwen_performance: guwen_performance,
              mei_performance: mei_performance,
            },
          ];
        } else {
          product["haoka_list"] = [];
        }
        product["add_type"] = "haoka_products";
        this.haoka_products = [product];
        this.add_type = "haoka_products";
      } else if (v.main_type === 3) {
        let jine_customer_products = [v];
        let all_performance = [
          {
            haoka: {
              cat: null,
              amount: 0,
            },
            xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
          },
        ];
        let guwen_performance = [
          {
            user: this.default_guwen,
            haoka: {
              cat: null,
              amount: 0,
            },
            xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
            shougong: [],
          },
        ];
        let mei_performance = [
          {
            user: this.$store.state.enable_guwen_xiaohao ? this.default_mei : null,
            haoka: { cat: null, amount: 0 },
            shougong: this.shougong_cats.map((v) => {
              return { cat: v, amount: 0 };
            }),
            xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
          },
        ];
        let all_yeji_performance = [
          {
            yeji: {
              cat: null,
              amount: 0,
            },
          },
        ];
        let guwen_yeji_performance = [
          {
            user: this.default_guwen,
            yeji: {
              cat: null,
              amount: 0,
            },
          },
        ];
        let mei_yeji_performance = [
          {
            user: this.default_mei,
            yeji: { cat: null, amount: 0 },
          },
        ];
        let jine_methods = jine_customer_products.map((v) => {
          v.checked = true;
          v.value = v.id;
          v.label = v.material_name;
          v.customer_product_id = v.id;
          v.real_amount = 0;
          v.ratio = 0;
          if (v.left_price + v.left_price_free) {
            v.ratio = v.left_price / (v.left_price + v.left_price_free);
          }
          return v;
        });
        let relate_material_outin = 0;
        this.jine_haoka_list = [
          {
            add_type: "jine_haoka_list",
            customer: {
              value: this.customer.customer_id,
              label: this.customer.name,
              id: this.customer.customer_id,
              name: this.customer.name,
              cur_type: this.customer.cur_type,
            },
            product: null,
            special_product: null,
            cnt: 1,
            amount: null,
            main_type: 1,
            jine_methods: jine_methods,
            relate_material_outin,
            store_id: null,
            material_outins: [],
            default_cat: null,
            all_performance: all_performance,
            guwen_performance: guwen_performance,
            mei_performance: mei_performance,
            all_yeji_performance: all_yeji_performance,
            guwen_yeji_performance: guwen_yeji_performance,
            mei_yeji_performance: mei_yeji_performance,
          },
        ];
        this.add_type = "jine_haoka_list";
      }
    },
    tuikaxuka(item, show_tuikaxuka_func) {
      this.add_type = null;
      let v = JSON.parse(JSON.stringify(item));
      let tui_customer_products = [v];
      let sub_products = this.sub_products;
      this.tui_customer_products = tui_customer_products.map((v) => {
        let amount = 0;
        let real_amount = 0;
        let dao_amount = 0;
        let yeji_amount = 0;
        if (show_tuikaxuka_func < 0) {
          v.return_cnt = v.left_quantity;
          v.return_amount = v.left_price;
          v.return_amount_free = v.left_price_free;
          amount = v.left_price;
          dao_amount = amount;
          yeji_amount = this.$store.state.dao_amount_enabled === 1 ? dao_amount : amount;
        }
        let cat = null;
        if (v.main_type === 2 && v.sub_type === 2) {
          v.sub_products = JSON.parse(JSON.stringify(sub_products[v.id])).map((m) => {
            m.cnt = m.left_quantity;
            m.price = m.left_price;
            m.price_free = m.left_price_free;
            return m;
          });
        }
        let all_performance = [
          {
            yeji: {
              cat: cat,
              amount: yeji_amount,
            },
            type: "all_performance",
          },
        ];
        let guwen_performance = [
          {
            user: this.default_guwen,
            yeji: {
              cat: cat,
              amount: yeji_amount,
            },
            type: "guwen_performance",
          },
        ];
        let mei_performance = [
          {
            user: this.default_mei,
            yeji: {
              cat: cat,
              amount: yeji_amount,
            },
            type: "mei_performance",
          },
        ];
        v.methods = [
          {
            method: null,
            amount: amount,
            real_amount: real_amount,
            dao_amount: dao_amount,
            jine_product: null,
            all_performance: all_performance,
            guwen_performance: guwen_performance,
            default_cat: null,
            mei_performance: mei_performance,
            has_performance: show_tuikaxuka_func > 0 && this.show_add_performance,
          },
        ];
        v.mei_users = [];
        v.guwen_users = [];
        if (this.default_mei) {
          v.mei_users = [this.default_mei];
        }
        if (this.default_guwen) {
          v.guwen_users = [this.default_guwen];
        }
        v.show_tuikaxuka_func = show_tuikaxuka_func;
        v.remark = null;
        return v;
      });
      this.add_type = "tui_customer_products";
    },
    repay(item) {
      // 还款
      this.add_type = null;
      let arrearage = JSON.parse(JSON.stringify(item));
      let materialiddict = {};
      let products = arrearage.products.map((v) => {
        let cat = null;
        materialiddict[v.material_id] = v;
        let amount = -v.left_price;
        let dao_ratio = this.$store.state.dao_amount_enabled ? v.dao_ratio : 100;
        let dao_amount = Math.round(amount * dao_ratio) / 100;
        let yeji_amount =
          this.$store.state.dao_amount_enabled === 1 ? dao_amount : amount;
        let all_performance = [
          {
            yeji: {
              cat: cat,
              amount: yeji_amount,
            },
            type: "all_performance",
          },
        ];
        let guwen_performance = [
          {
            user: this.default_guwen,
            yeji: {
              cat: cat,
              amount: yeji_amount,
            },
            type: "guwen_performance",
          },
        ];
        let mei_performance = [
          {
            user: this.default_mei,
            yeji: {
              cat: cat,
              amount: yeji_amount,
            },
            type: "mei_performance",
          },
        ];
        v.methods = [
          {
            method: null,
            amount: -v.left_price,
            dao_amount,
            default_cat: null,
            jine_product: null,
            all_performance: all_performance,
            guwen_performance: guwen_performance,
            mei_performance: mei_performance,
            has_performance: this.show_add_performance,
          },
        ];
        v.value = v.material_id;
        v.label = `${v.material_name}(欠${v.left_price}元)`;
        return v;
      });
      arrearage.products = products;
      arrearage.materialiddict = materialiddict;
      this.add_type = "arrearage";
      this.arrearage = arrearage;
    },
    addhaokaproductitem() {
      for (let i = 0; i < this.haoka_products.length; i++) {
        let v = this.haoka_products[i];
        for (let j = 0; j < v.haoka_list.length; j++) {
          let a = v.haoka_list[j];
          if (!a.product) {
            this.$store.commit("add_notification", {
              msg: `请选择${v.material_name}中消耗的项目或产品名称`,
            });
            return;
          } else if (
            v.main_type === 2 &&
            a.product.main_type === 10 &&
            !a.custom_product_name
          ) {
            this.$store.commit("add_notification", {
              msg: `请输入自定义项目或产品名称`,
            });
            return;
          } else if (
            v.main_type === 2 &&
            this.main_type_dict &&
            this.main_type_dict[a.product.material_id] &&
            this.main_type_dict[a.product.material_id]["main_type"] === 2 &&
            !a.real_product
          ) {
            this.$store.commit("add_notification", {
              msg: `请选择实际品项`,
            });
            return;
          }
          if (a.customer.value === -1 && !a.customer_name) {
            this.$store.commit("add_notification", {
              msg: `请输入客户朋友或亲人姓名`,
            });
            return;
          }
          if (!a.cnt) {
            this.$store.commit("add_notification", {
              msg: `请输入数量`,
            });
            return;
          }
          if (this.$store.state.special_func === 1) {
            if (a.special_product === null) {
              this.$store.commit("add_notification", {
                msg: `请选择手工类型`,
              });
              return;
            } else if (a.special_product === 0) {
              let shougong = a.mei_performance
                .filter((v) => v.user)
                .reduce((acc, cur) => {
                  if (acc) {
                    return true;
                  }
                  return cur.shougong.reduce((a, c) => {
                    if (a) {
                      return true;
                    }
                    if (c.amount) {
                      return true;
                    }
                    return false;
                  }, false);
                }, false);
              if (shougong) {
                this.$store.commit("add_notification", {
                  msg: `手工类型为阶梯项时，不可以填手工`,
                });
                return;
              }
            }
          }
        }
      }
      if (this.haoka_products.length > 0) {
        this.items_to_submit.push(JSON.parse(JSON.stringify(this.haoka_products[0])));
        this.haoka_products = [];
      }
    },
    addjinehaokaproductitem() {
      for (let j = 0; j < this.jine_haoka_list.length; j++) {
        let a = this.jine_haoka_list[j];
        if (!a.product) {
          this.$store.commit("add_notification", {
            msg: `请选择从金额卡中扣卡的项目或产品名称`,
          });
          return;
        } else if (a.product.main_type === 10 && !a.custom_product_name) {
          this.$store.commit("add_notification", {
            msg: `请输入自定义项目或产品名称`,
          });
          return;
        }
        let amount = a.jine_methods.reduce((acc, cur) => acc + cur.amount, 0);
        if (amount !== a.amount) {
          this.$store.commit("add_notification", {
            msg: `购买${a.product.label}${a.amount}元与扣除的金额卡总额${amount}不相等`,
          });
          return;
        }
        for (let k = 0; k < a.jine_methods.length; k++) {
          let { left_price, left_price_free, amount, real_amount } = a.jine_methods[k];
          amount = amount ? amount : 0;
          real_amount = real_amount ? real_amount : 0;
          let free_amount = amount - real_amount;
          if (left_price < real_amount) {
            this.$store.commit("add_notification", {
              msg: `金额卡 ${a.jine_methods[k].material_name} (非赠送)余额不足(只有${left_price}元)`,
            });
            return;
          }
          if (left_price_free < free_amount) {
            this.$store.commit("add_notification", {
              msg: `金额卡 ${a.jine_methods[k].material_name} (赠送)余额元不足(只有${left_price_free}元)`,
            });
            return;
          }
        }
        if (this.$store.state.special_func === 1) {
          if (a.special_product === null) {
            this.$store.commit("add_notification", {
              msg: `请选择手工类型`,
            });
            return;
          } else if (a.special_product === 0) {
            let shougong = a.mei_performance
              .filter((v) => v.user)
              .reduce((acc, cur) => {
                if (acc) {
                  return true;
                }
                return cur.shougong.reduce((a, c) => {
                  if (a) {
                    return true;
                  }
                  if (c.amount) {
                    return true;
                  }
                  return false;
                }, false);
              }, false);
            if (shougong) {
              this.$store.commit("add_notification", {
                msg: `手工类型为阶梯项时，不可以填手工`,
              });
              return;
            }
          }
        }
      }
      if (this.jine_haoka_list.length > 0) {
        this.items_to_submit.push(JSON.parse(JSON.stringify(this.jine_haoka_list[0])));
        this.jine_haoka_list = [];
        console.log("items_to_submit:", this.items_to_submit);
      }
    },
    addxiaofeiproductitem() {
      if (this.xiaofei_products[0]["methods"].length === 0) {
        this.$store.commit("add_notification", {
          msg: `没有选择任何支付方式`,
        });
        return;
      }
      for (let i = 0; i < this.xiaofei_products.length; i++) {
        // 正常开卡，正常消费
        let v = this.xiaofei_products[i];
        let product = JSON.parse(JSON.stringify(v.product));
        product.add_type = "xiaofei_products";
        product.material_name = v.product.name;
        product.cnt = v.cnt;
        product.amount = v.amount;
        product.remark = v.remark;
        product.consumed = v.consumed;
        product.special_product = v.special_product;
        product.relate_material_outin = v.relate_material_outin;
        product.store_id = v.store_id;
        product.material_outins = v.material_outins;
        product.material_outin_ids = v.material_outins.map((v) => v.id);
        let methods = [];
        for (let j = 0; j < v.methods.length; j++) {
          let a = v.methods[j];
          let all_performance = [],
            guwen_performance = [],
            mei_performance = [];
          if (a.has_performance) {
            all_performance = a.all_performance.filter(
              (b) => b.yeji.cat && b.yeji.amount
            );
            guwen_performance = a.guwen_performance
              .filter((b) => b.user && b.yeji.cat && b.yeji.amount)
              .map((v) => {
                v.role_type = 2;
                return v;
              });
            mei_performance = a.mei_performance
              .filter((b) => b.user && b.yeji.cat && b.yeji.amount)
              .map((v) => {
                v.role_type = 1;
                return v;
              });
          }
          if (a.method.payment_type === 1) {
            let amount_free = a.amount - a.real_amount;
            if (!a.jine_product) {
              this.$store.commit("add_notification", {
                msg: `支付方式为余额时需要选择金额卡`,
                seconds: 4,
              });
              return;
            }
            if (a.jine_product.left_price < a.real_amount) {
              this.$store.commit("add_notification", {
                msg: `${a.jine_product.label} 余额${a.jine_product.left_price}元，不足以扣${a.real_amount}元`,
                seconds: 4,
              });
              return;
            }
            if (a.jine_product.left_price_free < amount_free) {
              this.$store.commit("add_notification", {
                msg: `${a.jine_product.label} 赠送余额${a.jine_product.left_price_free}元，不足以扣${amount_free}元`,
                seconds: 4,
              });
              return;
            }
          }
          methods.push({
            method: a.method,
            amount: a.amount,
            real_amount: a.real_amount,
            dao_amount: a.dao_amount,
            jine_product: a.jine_product,
            all_performance: all_performance,
            guwen_performance: guwen_performance,
            mei_performance: mei_performance,
          });
        }
        product.methods = methods;
        let method_amount = product.methods.reduce((acc, cur) => acc + cur.amount, 0);
        if (method_amount !== v.amount && !(!method_amount && !v.amount)) {
          this.$store.commit("add_notification", {
            msg: `${product.material_name}总金额为${v.amount}元，有效支付方式总金额为${method_amount}，两者不相等，请确认`,
            seconds: 6,
          });
          return;
        }
        let guwen_ids = [];
        let mei_ids = [];
        if (v.guwen_users) {
          guwen_ids = v.guwen_users.map((v) => v.id);
        }
        if (v.mei_users) {
          mei_ids = v.mei_users.map((v) => v.id);
        }
        product.user_ids = guwen_ids.concat(mei_ids);
        if (v.consumed) {
          product.all_performance = v.all_performance.map((b) => {
            let bb = {};
            if (b.xiangshu.cat && b.xiangshu.amount) {
              bb.xiangshu = {
                cat_id: b.xiangshu.cat.id,
                amount: b.xiangshu.amount,
                cat: b.xiangshu.cat,
              };
            }
            if (b.haoka.cat && b.haoka.amount) {
              bb.haoka = {
                cat_id: b.haoka.cat.id,
                amount: b.haoka.amount,
                cat: b.haoka.cat,
              };
            }
            return bb;
          });
          product.guwen_performance = v.guwen_performance
            .filter((v) => v.user)
            .map((b) => {
              let bb = { user_id: b.user.value, role_type: 2, user: b.user };
              if (b.xiangshu.amount) {
                bb["xiangshu"] = {
                  cat_id: b.xiangshu.cat.id,
                  amount: b.xiangshu.amount,
                  cat: b.xiangshu.cat,
                };
              }
              if (b.haoka.cat && b.haoka.amount) {
                bb.haoka = {
                  cat_id: b.haoka.cat.id,
                  amount: b.haoka.amount,
                  cat: b.haoka.cat,
                };
              }
              return bb;
            });
          product.mei_performance = v.mei_performance
            .filter((v) => v.user)
            .map((b) => {
              let bb = { user_id: b.user.value, role_type: 1, user: b.user };
              if (b.xiangshu.amount) {
                bb["xiangshu"] = {
                  cat_id: b.xiangshu.cat.id,
                  amount: b.xiangshu.amount,
                  cat: b.xiangshu.cat,
                };
              }
              if (b.haoka.cat && b.haoka.amount) {
                bb.haoka = {
                  cat_id: b.haoka.cat.id,
                  amount: b.haoka.amount,
                  cat: b.haoka.cat,
                };
              }
              bb.shougong = b.shougong.map((c) => {
                let cc = {};
                // if (c.cat && c.amount) {
                cc.cat_id = c.cat.id;
                cc.amount = c.amount;
                cc.cat = c.cat;
                //}
                return cc;
              });
              return bb;
            });
        } else {
          product.all_performance = [];
          product.guwen_performance = [];
          product.mei_performance = [];
        }
        this.items_to_submit.push(product);
      }
      this.methods = [];
      this.xiaofei_products[0].cnt = null;
      this.xiaofei_products[0].amount = null;
      this.xiaofei_products[0].remark = null;
      this.xiaofei_products[0].consumed = false;
      this.xiaofei_products[0].product = null;
      this.xiaofei_products[0].relate_material_outin = 0;
      this.xiaofei_products[0].store_id = null;
      this.xiaofei_products[0].material_outins = [];
    },
    addtuikaxukaitem() {
      let customer_products = this.tui_customer_products;
      for (let i = 0; i < customer_products.length; i++) {
        // 退卡或续卡
        let v = customer_products[i];
        let product = {};
        product.add_type = "tui_customer_products";
        product.show_tuikaxuka_func = v.show_tuikaxuka_func;
        product.customer_product_id = v.id;
        product.material_id = v.material_id;
        product.material_name = v.material_name;
        product.return_cnt = v.return_cnt;
        product.return_amount = v.return_amount;
        product.return_amount_free = v.return_amount_free ? v.return_amount_free : 0;
        product.main_type = v.main_type;
        product.sub_type = v.sub_type;
        if (v.show_tuikaxuka_func === -1 && v.main_type === 2 && v.sub_type === 2) {
          let sub_products = v.sub_products.map((m) => {
            m.cnt = m.cnt ? m.cnt : 0;
            m.price = m.price ? m.price : 0;
            m.price_free = m.price_free ? m.price_free : 0;
            return m;
          });
          for (let j = 0; j < sub_products.length; j++) {
            let sub_item = sub_products[j];
            if (
              sub_item.cnt === sub_item.left_quantity &&
              (sub_item.price !== sub_item.left_price ||
                sub_item.price_free !== sub_item.left_price_free)
            ) {
              this.$store.commit("add_notification", {
                msg: `数量全部退完了，金额也必须退完`,
              });
              return;
            }
          }
          product["sub_products"] = sub_products;
        }
        let methods = [];
        for (let j = 0; j < v.methods.length; j++) {
          let a = v.methods[j];
          if (!a.method) {
            this.$store.commit("add_notification", {
              msg: `请选择${["退卡", "续卡"][(v.show_tuikaxuka_func + 1) / 2]}方式`,
            });
            return;
          }
          let all_performance = [],
            guwen_performance = [],
            mei_performance = [];
          if (a.has_performance) {
            all_performance = a.all_performance.filter(
              (b) => b.yeji.cat && b.yeji.amount
            );
            guwen_performance = a.guwen_performance
              .filter((b) => b.user && b.yeji.cat && b.yeji.amount)
              .map((v) => {
                v.role_type = 2;
                return v;
              });
            mei_performance = a.mei_performance
              .filter((b) => b.user && b.yeji.cat && b.yeji.amount)
              .map((v) => {
                v.role_type = 1;
                return v;
              });
          }
          if (
            v.show_tuikaxuka_func === -1 &&
            a.method.payment_type === 3 &&
            product.material_id in this.arrearage.materialiddict &&
            a.amount + this.arrearage.materialiddict[product.material_id]["left_price"] >
              0
          ) {
            this.$store.commit("add_notification", {
              msg: `退款金额${a.amount}超过欠款金额${
                this.arrearage.materialiddict[product.material_id]["left_price"]
              }`,
              seconds: 4,
            });
            return;
          }
          if (a.method.payment_type === 1) {
            if (!a.jine_product) {
              this.$store.commit("add_notification", {
                msg: `支付方式为余额时需要选择金额卡`,
                seconds: 4,
              });
              return;
            }
            if (v.show_tuikaxuka_func === 1) {
              if (a.jine_product.left_price < a.real_amount) {
                this.$store.commit("add_notification", {
                  msg: `${a.jine_product.label} 余额${a.jine_product.left_price}元，不足以扣${a.real_amount}元`,
                  seconds: 4,
                });
                return;
              }
              if (a.jine_product.left_price_free < a.amount_free) {
                this.$store.commit("add_notification", {
                  msg: `${a.jine_product.label} 赠送余额${a.jine_product.left_price_free}元(赠)，不足以扣${a.amount_free}元`,
                  seconds: 4,
                });
                return;
              }
            }
          }
          methods.push({
            method: a.method,
            amount: a.amount ? a.amount : 0,
            real_amount: a.real_amount ? a.real_amount : 0,
            amount_free: a.amount_free ? a.amount_free : 0,
            dao_amount: a.dao_amount,
            jine_product: a.jine_product,
            all_performance: all_performance,
            guwen_performance: guwen_performance,
            mei_performance: mei_performance,
          });
        }
        product.methods = methods;
        if (methods.filter((v) => v.method.payment_type === 3).length > 1) {
          this.$store.commit("add_notification", {
            msg: `不可以同时有两个'欠款'方式`,
            seconds: 4,
          });
          return;
        }
        product.remark = v.remark;
        let method_total_amount = 0,
          method_total_amount_free = 0;
        if (v.show_tuikaxuka_func === -1) {
          for (let j = 0; j < product.methods.length; j++) {
            let a = product.methods[j];
            if (a.method.payment_type === 1) {
              // 余额
              method_total_amount = method_total_amount + a.amount;
              method_total_amount_free = method_total_amount_free + a.amount_free;
            } else if (a.method.payment_type === 4) {
              // 赠送
              method_total_amount_free = method_total_amount_free + a.amount;
            } else {
              // 非余额、非赠送
              method_total_amount = method_total_amount + a.amount;
            }
          }
          if (method_total_amount !== product.return_amount) {
            this.$store.commit("add_notification", {
              msg: `品项退金额${product.return_amount}，各方式累计退金额${method_total_amount}，两者不相等，请确认`,
              seconds: 4,
            });
            return;
          }
          if (method_total_amount_free !== product.return_amount_free) {
            this.$store.commit("add_notification", {
              msg: `品项退赠送额${product.return_amount_free}，各方式累计退赠送额${method_total_amount_free}，两者不相等，请确认`,
              seconds: 4,
            });
            return;
          }
        }
        let guwen_ids = [];
        let mei_ids = [];
        if (v.guwen_users) {
          guwen_ids = v.guwen_users.map((v) => v.id);
        }
        if (v.mei_users) {
          mei_ids = v.mei_users.map((v) => v.id);
        }
        product.user_ids = guwen_ids.concat(mei_ids);
        this.items_to_submit.push(product);
      }
      this.tui_customer_products = [];
      this.add_type = null;
    },
    addrepayitem() {
      let v = JSON.parse(
        JSON.stringify(
          this.arrearage.products.filter((v) => v.value === this.arrearage_material_id)
        )
      );
      v = v[0];
      let product = {};
      product.add_type = "arrearage";
      product.material_id = v.material_id;
      product.material_name = v.material_name;
      product.left_price = v.left_price;
      product.customer_product_id = this.arrearage.id;
      for (let i = 0; i < v.methods.length; i++) {
        if (!v.methods[i].method) {
          this.$store.commit("add_notification", { msg: "请选择还款方式!" });
          return;
        }
        if (!v.methods[i].amount) {
          this.$store.commit("add_notification", { msg: "请填写还款金额!" });
          return;
        }
      }
      product.methods = v.methods
        .filter((v) => v.method && v.amount)
        .map((a) => {
          let all_performance = [],
            guwen_performance = [],
            mei_performance = [];
          if (a.has_performance) {
            all_performance = a.all_performance.filter(
              (b) => b.yeji.cat && b.yeji.amount
            );
            guwen_performance = a.guwen_performance
              .filter((b) => b.user && b.yeji.cat && b.yeji.amount)
              .map((v) => {
                v.role_type = 2;
                return v;
              });
            mei_performance = a.mei_performance
              .filter((b) => b.user && b.yeji.cat && b.yeji.amount)
              .map((v) => {
                v.role_type = 1;
                return v;
              });
          }
          return {
            method: a.method,
            amount: a.amount,
            real_amount: a.real_amount ? a.real_amount : 0,
            dao_amount: a.dao_amount,
            jine_product: a.jine_product,
            all_performance: all_performance,
            guwen_performance: guwen_performance,
            mei_performance: mei_performance,
          };
        });
      let guwen_ids = [];
      let mei_ids = [];
      if (v.guwen_users) {
        guwen_ids = v.guwen_users.map((v) => v.id);
      }
      if (v.mei_users) {
        mei_ids = v.mei_users.map((v) => v.id);
      }
      product.user_ids = guwen_ids.concat(mei_ids);
      product.remark = v.remark;
      let method_amount = v.methods.reduce((acc, cur) => acc + cur.amount, 0);
      if (product.left_price + method_amount > 0) {
        this.$store.commit("add_notification", {
          msg: `${product.material_name}欠款${product.left_price}元，还款金额${method_amount}超过了欠款金额，请修改`,
          seconds: 6,
        });
        return;
      }
      product.amount = method_amount;
      this.arrearage_material_id = null;
      this.items_to_submit.push(product);
    },
    deletesubmititem(i) {
      this.items_to_submit.splice(i, 1);
    },
    addallperformance(i, j) {
      let product = this.haoka_products[i];
      let item = product.haoka_list[j];
      item.all_performance.push({
        haoka: {
          cat: item.default_cat,
          amount: 0,
        },
        xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
      });
    },
    deleteallperformance(i, j) {
      let product = this.haoka_products[i];
      let item = product.haoka_list[j];
      item.all_performance.pop();
    },
    addguwenperformance(i, j) {
      let product = this.haoka_products[i];
      let item = product.haoka_list[j];
      item.guwen_performance.push({
        haoka: {
          cat: item.default_cat,
          amount: 0,
        },
        xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
      });
    },
    deleteguwenperformance(i, j) {
      let product = this.haoka_products[i];
      let item = product.haoka_list[j];
      item.guwen_performance.pop();
    },
    addmeiperformance(i, j) {
      let product = this.haoka_products[i];
      let item = product.haoka_list[j];
      item.mei_performance.push({
        haoka: {
          cat: item.default_cat,
          amount: 0,
        },
        xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
        shougong: this.shougong_cats.map((v) => {
          return { cat: v, amount: 0 };
        }),
      });
    },
    deletemeiperformance(i, j) {
      let product = this.haoka_products[i];
      let item = product.haoka_list[j];
      item.mei_performance.pop();
    },
    addjineallyejiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.all_yeji_performance.push({
        yeji: {
          cat: null,
          amount: 0,
        },
      });
    },
    deletejineallyejiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.all_yeji_performance.pop();
    },
    addjineguwenyejiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.guwen_yeji_performance.push({
        yeji: {
          cat: null,
          amount: 0,
        },
      });
    },
    deletejineguwenyejiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.guwen_yeji_performance.pop();
    },
    addjinemeiyejiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.mei_yeji_performance.push({
        yeji: {
          cat: null,
          amount: 0,
        },
      });
    },
    deletejinemeiyejiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.mei_yeji_performance.pop();
    },
    addjineallperformance(j) {
      let item = this.jine_haoka_list[j];
      item.all_performance.push({
        haoka: {
          cat: null,
          amount: 0,
        },
        xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
      });
    },
    deletejineallperformance(j) {
      let item = this.jine_haoka_list[j];
      item.all_performance.pop();
    },
    addjineguwenperformance(j) {
      let item = this.jine_haoka_list[j];
      item.guwen_performance.push({
        haoka: {
          cat: null,
          amount: 0,
        },
        xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
      });
    },
    deletejineguwenperformance(j) {
      let item = this.jine_haoka_list[j];
      item.guwen_performance.pop();
    },
    addjinemeiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.mei_performance.push({
        haoka: {
          cat: null,
          amount: 0,
        },
        xiangshu: { cat: this.xiangshu_cats[0], amount: 1 },
        shougong: this.shougong_cats.map((v) => {
          return { cat: v, amount: 0 };
        }),
      });
    },
    deletejinemeiperformance(j) {
      let item = this.jine_haoka_list[j];
      item.mei_performance.pop();
    },
    addmethod(t, i) {
      let product;
      if (t === "xiaofei") {
        product = this.xiaofei_products[i];
      } else if (t === "qiankuan") {
        product = this.arrearage.products[i];
      } else if (t === "tuikaxuka") {
        product = this.tui_customer_products[i];
      }
      let amount = null;
      let cat = null;
      let all_performance = [
        {
          yeji: {
            cat: cat,
            amount: amount,
          },
          type: "all_performance",
        },
      ];
      let guwen_users = product.guwen_users;
      let guwen_performance = guwen_users.map((user) => {
        return {
          user,
          yeji: {
            cat: cat,
            amount: null,
          },
          type: "guwen_performance",
        };
      });
      let mei_users = product.mei_users;
      let mei_performance = mei_users.map((user) => {
        return {
          user,
          yeji: {
            cat: cat,
            amount: null,
          },
          type: "mei_performance",
        };
      });
      let item = {
        method: null,
        amount: amount,
        jine_product: null,
        all_performance: all_performance,
        guwen_performance: guwen_performance,
        mei_performance: mei_performance,
        default_cat: null,
        has_performance:
          !(t === "tuikaxuka" && product.show_tuikaxuka_func < 0) &&
          this.show_add_performance,
      };
      product.methods.push(item);
    },
    deletemethod(t, i, j) {
      let product;
      if (t === "xiaofei") {
        product = this.xiaofei_products[i];
      } else if (t === "qiankuan") {
        product = this.arrearage.products[i];
      } else if (t === "tuikaxuka") {
        product = this.tui_customer_products[i];
      }
      product.methods.splice(j, 1);
    },
    addperformance(t, perf, i, j) {
      let xiaofei_product_item;
      if (t === "xiaofei" || t === "xiaohao") {
        xiaofei_product_item = this.xiaofei_products[i];
      } else if (t === "qiankuan") {
        xiaofei_product_item = this.arrearage.products[i];
      } else if (t === "tuikaxuka") {
        xiaofei_product_item = this.tui_customer_products[i];
      }
      if (t !== "xiaohao") {
        let amount = null;
        let cat = xiaofei_product_item["methods"][j].default_cat;
        let item = {
          type: perf,
          yeji: {
            cat: cat,
            amount: amount,
          },
        };
        if (perf === "guwen_performance") {
          item["user"] = null;
        } else if (perf === "mei_performance") {
          item["user"] = null;
        }
        let performance = xiaofei_product_item.methods[j][perf];
        performance.push(item);
      } else {
        let cat = null;
        let product = xiaofei_product_item["product"];
        let default_haocat_id = product.default_haocat_id;
        let cats = this.haoka_cats.filter((v) => v.value === default_haocat_id);
        cat = cats.length > 0 ? cats[0] : null;
        let default_xiang_ratio = product.main_type === 0 ? 0 : 1;
        let xiang_ratio =
          product.xiang_ratio || product.xiang_ratio === 0
            ? product.xiang_ratio
            : default_xiang_ratio;
        let xiang_amount = xiang_ratio < 0 ? 1 : xiang_ratio * xiaofei_product_item.cnt;
        let xiaohao_amount = JSON.parse(this.xiaofei_products_real_amounts)[i];
        let shougong =
          (product.shougong ? product.shougong : 0) * (xiang_ratio ? xiang_ratio : 1);
        let item = {
          haoka: {
            cat: cat,
            amount: xiaohao_amount,
          },
          xiangshu: {
            cat: this.xiangshu_cats[0],
            amount: xiang_amount,
          },
          shougong: [],
        };
        if (perf === "guwen_performance") {
          item["user"] = this.default_guwen;
        } else if (perf === "mei_performance") {
          item["user"] = this.default_mei;
          item["shougong"] = this.shougong_cats.map((v) => {
            return { cat: v, amount: product.shougong };
          });
        }
        let performance = xiaofei_product_item[perf];
        performance.push(item);
        let len = performance.length;
        for (let k = 0; k < len; k++) {
          performance[k]["haoka"]["amount"] = xiaohao_amount / len;
          performance[k]["xiangshu"]["amount"] = xiang_amount / len;
          if (perf === "mei_performance") {
            for (let m = 0; m < performance[k]["shougong"].length; m++) {
              performance[k]["shougong"][m]["amount"] = shougong / len;
            }
          }
        }
      }
    },
    deleteperformance(t, perf, i, j, k) {
      let xiaofei_product_item;
      if (t === "xiaofei" || t === "xiaohao") {
        xiaofei_product_item = this.xiaofei_products[i];
      } else if (t === "qiankuan") {
        xiaofei_product_item = this.arrearage.products[i];
      } else if (t === "tuikaxuka") {
        xiaofei_product_item = this.tui_customer_products[i];
      }
      let performance;
      if (t === "xiaohao") {
        performance = xiaofei_product_item[perf];
        performance.splice(k, 1);
        let product = xiaofei_product_item["product"];
        let default_xiang_ratio = product.main_type === 0 ? 0 : 1;
        let xiang_ratio =
          product.xiang_ratio || product.xiang_ratio === 0
            ? product.xiang_ratio
            : default_xiang_ratio;
        let xiang_amount = xiang_ratio < 0 ? 1 : xiang_ratio * xiaofei_product_item.cnt;
        let xiaohao_amount = JSON.parse(this.xiaofei_products_real_amounts)[i];
        let shougong =
          (product.shougong ? product.shougong : 0) * (xiang_ratio ? xiang_ratio : 1);
        let len = performance.length;
        for (let k = 0; k < len; k++) {
          performance[k]["haoka"]["amount"] = xiaohao_amount / len;
          performance[k]["xiangshu"]["amount"] = xiang_amount / len;
          if (perf === "mei_performance") {
            for (let m = 0; m < performance[k]["shougong"].length; m++) {
              performance[k]["shougong"][m]["amount"] = shougong / len;
            }
          }
        }
      } else {
        performance = xiaofei_product_item.methods[j][perf];
        performance.splice(k, 1);
      }
    },
    submitdata() {
      let effect_date = new Date(this.effect_date).format("yyyy-MM-dd");
      let corp_id = this.my_corp.corp_id;
      let cur_type = this.customer.cur_type;
      let customer_id = this.customer.customer_id;
      let customer_ids = [customer_id];
      let customer_name = this.customer.name;
      let document_no = this.create_document_switch ? this.document_no : null;
      let idx = this.idx;
      let this_haoka_products = [],
        this_jine_haoka_list = [],
        this_xiaofei_products = [],
        this_tui_customer_products = [],
        this_arrearage_products = [];
      for (let i = 0; i < this.items_to_submit.length; i++) {
        let item = this.items_to_submit[i];
        let add_type = item["add_type"];
        if (add_type === "haoka_products") {
          this_haoka_products.push(item);
        } else if (add_type === "jine_haoka_list") {
          this_jine_haoka_list.push(item);
        } else if (add_type === "xiaofei_products") {
          this_xiaofei_products.push(item);
        } else if (add_type === "tui_customer_products") {
          this_tui_customer_products.push(item);
        } else if (add_type === "arrearage") {
          this_arrearage_products.push(item);
        }
      }
      let haoka_products = this_haoka_products.map((v) => {
        let item = {};
        item.id = v.id;
        item.material_name = v.material_name;
        item.material_id = v.material_id;
        item.unitname = v.unitname;
        item.main_type = v.main_type;
        item.sub_type = v.sub_type;
        item.is_free = v.is_free;
        item.corp_id = v.corp_id;
        item.haoka_list = v.haoka_list.map((a) => {
          let haoka_list_item = {};
          haoka_list_item.special_product = a.special_product;
          haoka_list_item.customer_id = a.customer.value;
          haoka_list_item.customer_name =
            a.customer.value === -1 && a.customer_name
              ? a.customer_name
              : a.customer.label;
          let customer_id = a.customer.value;
          haoka_list_item.cur_type = a.customer.cur_type;
          if (!customer_ids.includes(customer_id)) {
            customer_ids.push(customer_id);
          }
          haoka_list_item.customer_ids = customer_ids;
          haoka_list_item.all_performance = a.all_performance.map((b) => {
            let bb = {};
            if (b.xiangshu.cat && b.xiangshu.amount) {
              bb.xiangshu = {
                cat_id: b.xiangshu.cat.id,
                amount: b.xiangshu.amount === "" ? null : b.xiangshu.amount,
              };
            }
            if (b.haoka.cat && b.haoka.amount) {
              bb.haoka = {
                cat_id: b.haoka.cat.id,
                amount: b.haoka.amount === "" ? null : b.haoka.amount,
              };
            }
            return bb;
          });
          haoka_list_item.guwen_performance = a.guwen_performance
            .filter((v) => v.user)
            .map((b) => {
              let bb = { user_id: b.user.value, role_type: 2 };
              if (b.xiangshu.cat && b.xiangshu.amount) {
                bb["xiangshu"] = {
                  cat_id: b.xiangshu.cat.id,
                  amount: b.xiangshu.amount === "" ? null : b.xiangshu.amount,
                };
              }
              if (b.haoka.cat && b.haoka.amount) {
                bb.haoka = {
                  cat_id: b.haoka.cat.id,
                  amount: b.haoka.amount === "" ? null : b.haoka.amount,
                };
              }
              return bb;
            });
          haoka_list_item.mei_performance = a.mei_performance
            .filter((v) => v.user)
            .map((b) => {
              let bb = { user_id: b.user.value, role_type: 1 };
              if (b.xiangshu.cat && b.xiangshu.amount) {
                bb["xiangshu"] = {
                  cat_id: b.xiangshu.cat.id,
                  amount: b.xiangshu.amount === "" ? null : b.xiangshu.amount,
                };
              }
              if (b.haoka.cat && b.haoka.amount) {
                bb.haoka = {
                  cat_id: b.haoka.cat.id,
                  amount: b.haoka.amount === "" ? null : b.haoka.amount,
                };
              }
              bb.shougong = b.shougong.map((c) => {
                let cc = {};
                // if (c.cat && c.amount) {
                cc.cat_id = c.cat.id;
                cc.amount = c.amount === "" ? null : c.amount;
                //}
                return cc;
              });
              return bb;
            });
          haoka_list_item.add_quantity = a.cnt;
          let sub_product_price = this.sub_product_price;
          let material_id = a.product ? a.product.value : v.material_id;
          if (
            v.main_type === 2 &&
            v.sub_type === 2 &&
            v.id in sub_product_price &&
            material_id in sub_product_price[v.id]
          ) {
            let unit_price = sub_product_price[v.id][material_id];
            haoka_list_item.add_price = unit_price[0] * a.cnt;
            haoka_list_item.add_price_4hao = unit_price[0] * a.cnt;
            haoka_list_item.add_price_free = unit_price[1] * a.cnt;
          } else {
            haoka_list_item.add_price = (v.left_price / v.left_quantity) * a.cnt;
            haoka_list_item.add_price_4hao =
              (v.left_price_4hao / v.left_quantity) * a.cnt;
            haoka_list_item.add_price_free =
              (v.left_price_free / v.left_quantity) * a.cnt;
          }
          haoka_list_item.material_id = a.product ? a.product.value : v.material_id;
          if (v.main_type === 2 && a.product.main_type === 10) {
            haoka_list_item.material_name = a.custom_product_name;
          } else {
            haoka_list_item.material_name =
              a.product &&
              this.main_type_dict &&
              this.main_type_dict[a.product.material_id] &&
              this.main_type_dict[a.product.material_id]["main_type"] === 2 &&
              a.real_product
                ? a.real_product.label
                : a.product
                ? a.product.label
                : v.material_name;
          }
          haoka_list_item.real_material_id = a.real_product
            ? a.real_product.material_id
            : null;
          haoka_list_item.unitname = a.product ? a.product.unitname : v.unitname;
          haoka_list_item.remark = a.remark;
          haoka_list_item.relate_material_outin = a.relate_material_outin;
          haoka_list_item.store_id = a.store_id;
          haoka_list_item.material_outin_ids = a.material_outins.map((v) => v.id);
          return haoka_list_item;
        });
        return item;
      });
      let jine_haoka_list = this_jine_haoka_list.map((a) => {
        let haoka_list_item = {};
        haoka_list_item.customer_id = a.customer.value;
        haoka_list_item.customer_name = a.customer.label;
        let customer_id = a.customer.value;
        if (!customer_ids.includes(customer_id)) {
          customer_ids.push(customer_id);
        }
        haoka_list_item.cur_type = a.customer.cur_type;
        haoka_list_item.customer_ids = customer_ids;
        haoka_list_item.all_yeji_performance = a.all_yeji_performance.map((b) => {
          let bb = {};
          if (b.yeji.cat && b.yeji.amount) {
            bb.yeji = {
              cat_id: b.yeji.cat.id,
              amount: b.yeji.amount === "" ? null : b.yeji.amount,
            };
          }
          return bb;
        });
        haoka_list_item.guwen_yeji_performance = a.guwen_yeji_performance
          .filter((v) => v.user)
          .map((b) => {
            let bb = { user_id: b.user.value, role_type: 2 };
            if (b.yeji.cat && b.yeji.amount) {
              bb.yeji = {
                cat_id: b.yeji.cat.id,
                amount: b.yeji.amount === "" ? null : b.yeji.amount,
              };
            }
            return bb;
          });
        haoka_list_item.mei_yeji_performance = a.mei_yeji_performance
          .filter((v) => v.user)
          .map((b) => {
            let bb = { user_id: b.user.value, role_type: 1 };
            if (b.yeji.cat && b.yeji.amount) {
              bb.yeji = {
                cat_id: b.yeji.cat.id,
                amount: b.yeji.amount === "" ? null : b.yeji.amount,
              };
            }
            return bb;
          });
        haoka_list_item.all_performance = a.all_performance.map((b) => {
          let bb = {};
          if (b.xiangshu.cat && b.xiangshu.amount) {
            bb["xiangshu"] = {
              cat_id: b.xiangshu.cat.id,
              amount: b.xiangshu.amount === "" ? null : b.xiangshu.amount,
            };
          }
          if (b.haoka.cat && b.haoka.amount) {
            bb.haoka = {
              cat_id: b.haoka.cat.id,
              amount: b.haoka.amount === "" ? null : b.haoka.amount,
            };
          }
          return bb;
        });
        haoka_list_item.guwen_performance = a.guwen_performance
          .filter((v) => v.user)
          .map((b) => {
            let bb = { user_id: b.user.value, role_type: 2 };
            if (b.xiangshu.cat && b.xiangshu.amount) {
              bb["xiangshu"] = {
                cat_id: b.xiangshu.cat.id,
                amount: b.xiangshu.amount === "" ? null : b.xiangshu.amount,
              };
            }
            if (b.haoka.cat && b.haoka.amount) {
              bb.haoka = {
                cat_id: b.haoka.cat.id,
                amount: b.haoka.amount === "" ? null : b.haoka.amount,
              };
            }
            return bb;
          });
        haoka_list_item.mei_performance = a.mei_performance
          .filter((v) => v.user)
          .map((b) => {
            let bb = { user_id: b.user.value, role_type: 1 };
            if (b.xiangshu.cat && b.xiangshu.amount) {
              bb["xiangshu"] = {
                cat_id: b.xiangshu.cat.id,
                amount: b.xiangshu.amount === "" ? null : b.xiangshu.amount,
              };
            }
            if (b.haoka.cat && b.haoka.amount) {
              bb.haoka = {
                cat_id: b.haoka.cat.id,
                amount: b.haoka.amount === "" ? null : b.haoka.amount,
              };
            }
            bb.shougong = b.shougong.map((c) => {
              let cc = {};
              // if (c.cat && c.amount) {
              cc.cat_id = c.cat.id;
              cc.amount = c.amount === "" ? null : c.amount;
              //}
              return cc;
            });
            return bb;
          });
        haoka_list_item.cnt = a.cnt;
        haoka_list_item.material_id = a.product ? a.product.value : null;
        haoka_list_item.material_name = a.product ? a.product.label : null;
        haoka_list_item.main_type = a.product ? a.product.main_type : null;
        haoka_list_item.sub_type = a.product ? a.product.sub_type : 0;
        haoka_list_item.unitname = a.product ? a.product.unitname : null;
        haoka_list_item.special_product = a.special_product;
        haoka_list_item.remark = a.remark;
        if (haoka_list_item.material_id === 0) {
          haoka_list_item.material_name = a.custom_product_name;
          haoka_list_item.unitname = a.custom_product_unitname;
          haoka_list_item.main_type = a.main_type;
        }
        haoka_list_item.methods = a.jine_methods.map((v) => {
          return {
            customer_product_id: v.value,
            jine_customer_product: v,
            amount: v.amount,
            real_amount: v.real_amount,
            is_free: v.is_free,
          };
        });
        haoka_list_item.relate_material_outin = a.relate_material_outin;
        haoka_list_item.store_id = a.store_id;
        haoka_list_item.material_outin_ids = a.material_outins.map((v) => v.id);
        return haoka_list_item;
      });
      let xiaofei_products = this_xiaofei_products;
      let tui_customer_products = this_tui_customer_products;
      let arrearage_products = this_arrearage_products;
      /*
      [
        {
          customer: { value: -1, label: "xx" },
          customer_id: -1,
          customer_name: "xx",
          cur_type: 2,
          cnt: 1,
          1: [{ user: {}, user_id: 2, user_name: "mm", cnt: 1 }],
          2: [{ user: {}, user_id: 3, user_name: "nn", cnt: 1 }],
        },
      ];
      */
      let customer_user_counts = this.customer_user_counts.map((v) => {
        let r = {};
        let mei = v[1].map((u) => {
          return {
            id: u.user.value,
            name: u.user.label,
            role_id: u.user.role_id,
            cnt: u.cnt,
            //role_type: u.role_type ? u.role_type : u.user.role_type,
            role_type: 1,
            overtime: u.overtime,
          };
        });
        let guwen = v[2].map((u) => {
          return {
            id: u.user.value,
            name: u.user.label,
            role_id: u.user.role_id,
            cnt: u.cnt,
            //role_type: u.role_type ? u.role_type : u.user.role_type,
            role_type: 2,
            overtime: u.overtime,
          };
        });
        r.users = mei.concat(guwen);
        r.customer = v.customer;
        r.customer_id = v.customer_id;
        r.customer_name = v.customer_name;
        r.cur_type = v.cur_type;
        (r.real_customer_id = v.customer_id === -1 ? this.customer.customer_id : null),
          (r.cnt = v.cnt);
        return r;
      });
      /*
      [
        {
          customer_id,
          customer_name,
          cur_type,
          cnt,
          users: [{ id, name, role_id, cnt, role_type, overtime }],
        },
      ];
      */
      let need_add_qinren = this.need_add_qinren;
      let data = {
        effect_date,
        corp_id,
        customer_id,
        customer_name,
        cur_type,
        tui_customer_products,
        arrearage_products,
        xiaofei_products,
        need_add_qinren,
        haoka_products,
        jine_haoka_list,
        customer_user_counts,
        document_no,
        idx,
      };
      this.$store.commit("show_loading", "正在提交数据 . . .");
      submitCustomerProductData(data)
        .then(() => {
          this.items_to_submit = [];
          this.haoka_products = [];
          this.jine_haoka_list = [];
          //getSingleCustomer(v.customer_id, this.corp.corp_id).then((res) => {
          //  this.customer2 = res.content;
          //});
          this.getcustomerproducts();
          if (this.$store.state.corptype === 1) {
            this.getcustomerstorematerialleftcnts();
          }
          this.getcustomercnts();
          this.$store.commit("add_notification", {
            msg: "提交成功",
            status: "success",
            seconds: 3,
          });
          //this.canceloperation();
        })
        .catch((e) => {
          this.$store.commit("add_notification", {
            msg: "提交失败: " + e,
            status: "error",
          });
        })
        .finally(() => {
          this.$store.commit("hide_loading");
        });
    },
  },
  mounted: function () {
    document.title =
      "开单" +
      "-" +
      this.$store.state.user.corp.name +
      (this.$store.state.env === "development" ? "(开发)" : "");
    if (
      (this.$store.state.user.privileges &&
        this.$store.state.user.privileges.customers > 2) ||
      this.$store.state.corptype
    ) {
      getCorpList().then((res) => {
        this.corplist = res.content.filter(
          (v) => v.corp_type === 0 && v.customer_enabled
        );
      });
    }
    if (this.$store.state.corptype) {
      this.main_type = 0;
    }
    this.corp = this.$store.state.user.corp;
    let dt = new Date();
    let hours = dt.getHours();
    if (hours < 10) {
      dt.setDate(dt.getDate() - 1);
    }
    this.effect_date = dt;
    getAllUserList().then((res) => {
      this.userlist = res.content;
    });
    getPayments().then((res) => {
      this.payments = res.content;
    });
    getCategories().then((res) => {
      let categories = res.content.filter((v) => !v.deleted && v.collect_type <= 9);
      let haoka_cats = [],
        shougong_cats = [],
        xiangshu_cats = [],
        xiaofei_cats = [];
      for (let i = 0; i < categories.length; i++) {
        let cat = categories[i];
        if (cat.cat_type === -1) {
          haoka_cats.push(cat);
        } else if (cat.cat_type === -2) {
          shougong_cats.push(cat);
        } else if (cat.cat_type === -3) {
          xiangshu_cats.push(cat);
        } else if (cat.cat_type >= 1) {
          xiaofei_cats.push(cat);
        }
      }
      this.haoka_cats = haoka_cats;
      this.shougong_cats = shougong_cats;
      this.xiangshu_cats = xiangshu_cats;
      this.xiaofei_cats = xiaofei_cats;
    });
    getMaterials().then((res) => {
      let main_type_dict = {};
      for (let i = 0; i < res.content.length; i++) {
        let { material_id, main_type, sub_material_ids, need_manage } = res.content[i];
        main_type_dict[material_id] = { main_type, sub_material_ids, need_manage };
      }
      this.main_type_dict = main_type_dict;
      let materials = res.content
        .filter((v) => !(v.main_type === 3 && v.sub_type > 0))
        .map((v) => {
          let spec_desc;
          if (v.spec && v.spec_unitname && v.unitname) {
            spec_desc = v.spec + v.spec_unitname + "/" + v.unitname;
          }
          if (v.set_spec && v.set_unitname && v.unitname) {
            spec_desc = v.set_spec + v.unitname + "/" + v.set_unitname;
          }
          if (spec_desc) {
            v.label = v.name + "(" + spec_desc + ")";
          } else {
            v.label = v.name;
          }
          return v;
        });
      this.materials = [
        { value: 0, label: "---自行填名称---", main_type: 10, material_id: 0 },
      ].concat(materials);
    });
    getStoresFs({}).then((res) => {
      let items = res.content;
      let storedict = {};
      for (let i = 0; i < items.length; i++) {
        let { store_id } = items[i];
        storedict[store_id] = items[i];
      }
      this.storedict = storedict;
    });
  },
};
</script>

<style lang="less" scoped>
.create-bill {
  padding: 2px 5px;
  .left-products {
    max-height: 200px;
    overflow-y: scroll;
    thead {
      position: sticky;
      top: 0;
    }
  }
  .flex-wrapper {
    display: flex;
    .select-customer {
      min-width: 200px;
      max-width: 300px;
    }
    .effect-date {
      padding-left: 8px;
      max-width: 210px;
    }
    .select-user {
      max-width: 240px;
      min-width: 160px;
      padding-left: 8px;
    }
  }
  .operation-buttons {
    display: flex;
    .button {
      margin: 0 3px;
      font-size: 0.85em;
    }
  }
  .customer-product {
    &:hover {
      td {
        background-color: #eee;
      }
    }
  }
  .filter-area-2 {
    display: flex;
    align-items: center;
    .filter-button {
      background: #777;
      color: #fff;
      &.active {
        background: #108ee9;
      }
      &.left {
        border-right: 1px solid #aaa;
      }
      &.middle {
        border-right: 1px solid #aaa;
      }
    }
  }
  .index-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .index {
      padding-right: 2px;
    }
    .gray-button {
      font-size: 0.6em;
      padding: 1px 4px;
    }
  }
  .add-xiaofei-item-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      padding-right: 4px;
    }
    .material {
      min-width: 220px;
    }
    .cnt {
      width: 60px;
    }
    .users {
      min-width: 80px;
    }
    .methods {
      min-width: 80px;
    }
    .amount {
      width: 100px;
    }
    .remark {
      width: 200px;
    }
  }
  .jine-customer-products {
    margin-left: 32px;
    display: flex;
    align-items: center;
    .jine-product {
      max-width: 300px;
      min-width: 150px;
    }
    .jine-product-desc {
      padding-top: 2px;
      padding-left: 10px;
      input {
        width: 65px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 4px 2px;
        line-height: 1;
        outline: none;
        text-align: center;
        margin: 0 2px;
      }
    }
  }
  .add-item-area {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > div {
      padding-right: 4px;
      margin-top: 25px;
    }
    &.more-margin {
      margin-top: 10px;
      margin-left: 30px;
    }
    .customer {
      position: relative;
      min-width: 80px;
      .type {
        position: absolute;
        font-size: 0.9em;
        top: -22px;
        overflow: visible;
        font-weight: bold;
        word-break: keep-all;
        white-space: nowrap;
      }
    }
    .real-customer-name {
      max-width: 90px;
    }
    .material {
      min-width: 200px;
    }
    .real-material {
      min-width: 140px;
    }
    .add-quantity {
      max-width: 60px;
    }
    .add-price {
      min-width: 90px;
      &.input {
        .amount {
          width: 90px;
        }
      }
    }
    .method {
      min-width: 72px;
    }
    .amount {
      width: 100px;
    }
    .default-cat {
      min-width: 85px;
    }
    .custom-product-1 {
      display: flex;
      align-items: center;
      border: 1px dashed #888;
      padding: 4px;
      margin: 2px 2px 2px 0;
      border-radius: 5px;
      .product-name {
        max-width: 120px;
      }
      .product-unitname {
        max-width: 40px;
      }
    }
    .performance-wrapper {
      position: relative;
      .performance-title {
        position: absolute;
        top: -22px;
        display: flex;
        align-items: center;
        font-size: 0.9em;
        .gray-button {
          font-size: 0.8em;
          padding: 2px 6px;
          border-radius: 5px;
          margin: 0 2px;
        }
      }
      .performance-items {
        display: flex;
      }
      .performance-row {
        display: flex;
        border: 1px dashed #888;
        padding: 4px;
        margin: 2px 0;
        border-radius: 5px;
        .category {
          min-width: 85px;
        }
        .amount {
          width: 90px;
        }
        .xiangshu {
          width: 50px;
        }
        .shougong {
          width: 60px;
        }
      }
    }
  }
  .jine_amount {
    display: flex;
    input {
      font-size: 1.01em;
      width: 50px;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 1px 2px;
      line-height: 1;
      background-color: #fcfcfc;
      outline: none;
      &.real-amount {
        width: 40px;
        padding: 2px;
      }
    }
  }
  .items-to-submit {
    .performance {
      .per-items {
        display: flex;
        .per-item {
          font-size: 0.85em;
          padding-right: 8px;
        }
      }
    }
  }
  .submit-button {
    border: 1px solid #108ee9;
    background: #108ee9;
    color: #fff;
    padding: 5px 39px;
    border-radius: 5px;
    font-weight: bold;
    &:active {
      background: #fff;
      color: #108ee9;
      font-weight: bold;
    }
  }
  .customer-user-counts {
    display: flex;
    align-items: center;
    .items {
      display: flex;
      align-items: center;
      & > div {
        padding-right: 20px;
      }
    }
  }
}
</style>
